// @import './assets/stylesheets/variables.scss';
// @import './assets/stylesheets/fonts.scss';
/* Note: fonts is imported here, because this version of create react app doesn't support relative paths's imports.
The paths to the fonts files are relative to this file in particular.*/

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// @media (max-width: $smallMaxWidth) {
//   ::-webkit-scrollbar {
//     display: none;
//   }
// }
