/*
@File: Livani Template Styles

* This file contains the styling for the actual template, this
is the file you need to edit to change the look of the
template.

This files table contents are outlined below>>>>>

*******************************************
*******************************************

** - Default CSS
** - Top Header CSS
** - Navbar CSS
** - Search Overlay CSS
** - Main Banner CSS
** - About CSS
** - Categories Banner CSS
** - Products CSS
** - Products Details CSS
** - Offer CSS
** - Facility CSS
** - Partner CSS
** - Testimonials CSS
** - Brand CSS
** - Blog CSS
** - Blog Details CSS
** - Instagram CSS
** - Modal CSS
** - Page Title CSS
** - Gallery CSS
** - Look Book CSS
** - Woocommerce Widget Sidebar CSS
** - Sizing Guide CSS
** - Cart CSS
** - Checkout CSS
** - FAQ CSS
** - 404 Error CSS
** - Customer Service CSS
** - Compare CSS
** - Pagination CSS
** - Widget Sidebar CSS
** - Coming Soon CSS
** - Track Order CSS
** - Login CSS
** - Signup CSS
** - Contact CSS
** - Footer CSS
** - Go Top CSS
*/

/*================================================
Default CSS
=================================================*/
@import url("https://fonts.googleapis.com/css?family=Poppins:100,100i,300,300i,400,400i,700,700i,900,900i&display=swap");
@import url("https://fonts.googleapis.com/css?family=Rozha+One:400,400i,500,500i,600,600i,700,700i&display=swap");
@import url("https://fonts.googleapis.com/css?family=Muli:400,400i,500,500i,600,600i,700,700i&display=swap");
$font-family1: "Muli", -apple-system, BlinkMacSystemFont, Arial sans-serif;
$font-family2: "Poppins", -apple-system, BlinkMacSystemFont, Arial sans-serif;
$main-color: #184d5e;
$main-color-light: #38616e;
$gradient-color: #4d7c8a;
$green-banner: #81b781;
$optional-color: #777777;
$dark-gray-color: #333;
$light-gray: #ccc6c6;
$black-color: #000000;
$white-color: #ffffff;
$white-transparent: #ffffffd8;
$font-size: 18px;
$transition: 0.2s;

$navbar-height: 10vh;

// html, body {
//     overflow-x: hidden;
//   }

body {
	padding: 0;
	margin: 0;
	font: {
		family: $font-family1, sans-serif;
		size: $font-size;
	}
}
img {
	max-width: 100%;
	height: auto;
}

.no-element-msg {
	width: 100%;
	text-align: center;
}

.invisible-btn {
	outline: none;
	cursor: pointer;
	border: none;
	background-color: transparent;
	margin: none;
	color: white;
}

.d-table {
	width: 100%;
	height: 100%;

	&-cell {
		vertical-align: middle;
	}
}
:focus {
	outline: 0 !important;
}
a {
	transition: $transition;
	color: $black-color;
	text-decoration: none;

	&:hover {
		color: $main-color;
		text-decoration: none;
	}
}
p {
	margin-bottom: 15px;
	color: $optional-color;
	line-height: 1.8;
	font-size: $font-size;

	&:last-child {
		margin-bottom: 0;
	}
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
	color: $black-color;
	font-family: $font-family2;
}
.ptb-100 {
	padding: {
		top: 100px;
		bottom: 100px;
	}
}
.pt-100 {
	padding-top: 100px;
}
.pt-150 {
	padding-top: 150px;
}
.pb-100 {
	padding-bottom: 100px;
}
.ptb-70 {
	padding: {
		top: 70px;
		bottom: 70px;
	}
}
.pt-70 {
	padding-top: 70px;
}

.pt-50 {
	padding-top: 50px;
}

.pt-20 {
	margin: 0px;
	padding-top: 20px;
}

.pb-70 {
	padding-bottom: 70px;
}
.bg-f5f5f5 {
	background-color: #f5f5f5;
}
.jarallax {
	background-attachment: fixed !important;
}
/*default-btn&optional-btn*/
.default-btn {
	display: inline-block;
	border: 1px solid $main-color;
	padding: 10px 30px;
	transition: $transition;
	text-transform: uppercase;
	background-color: $main-color;
	color: $white-color !important;
	font: {
		size: 14px;
		weight: 600;
	}
	&:hover {
		background-color: transparent !important;
		color: $main-color !important;
		border-color: $main-color !important;
	}
}

.default-btn-yellow {
	display: inline-block;
	border: 1px solid #ffbd5aa8;
	padding: 10px 30px;
	transition: $transition;
	text-transform: uppercase;
	background-color: #ffbd5aa8;
	color: $white-color !important;
	border-radius: 8px !important;
	font: {
		size: 14px;
		weight: 600;
	}
	&:hover {
		background-color: transparent !important;
		color: #ffbd5aa8 !important;
		border-color: #ffbd5aa8 !important;
	}
}

.break-me-link {
	color: white !important;
}
.break-me {
	background-color: #184d5e;
	border-radius: 10px;
	margin: 2px;
	padding: 5px 10px;
}

.pagination {
	margin-top: 10px;
}

.special-active-alt {
	background-color: #0c2730;
	border-radius: 10px;
	margin: 2px;
	padding: 5px 10px;
}

.default-btn-disabled {
	display: inline-block;
	padding: 10px 30px;
	transition: $transition;
	text-transform: uppercase;
	background-color: rgba($color: $main-color, $alpha: 0.5);
	color: $white-color !important;
	font: {
		size: 14px;
		weight: 600;
	}
}

.default-course-btn-disabled {
	display: inline-block;
	padding: 10px 30px;
	transition: $transition;
	text-transform: uppercase;
	margin-bottom: 20px;
	background-color: rgba($color: white, $alpha: 0.5);
	color: $white-color !important;
	font: {
		size: 14px;
		weight: 600;
	}
}

.default-course-btn-disabled-custom {
	display: inline-block;
	padding: 10px 30px;
	transition: $transition;
	text-transform: uppercase;
	margin-bottom: 20px;
	background-color: rgba($color: white, $alpha: 0.5);
	color: $white-color !important;
	font: {
		size:9px;
		weight: 600;
	}
}

.left-card {
	background-color: #f5f2e8;
	padding: 10px;
	border-radius: 10px;
}

.optional-btn {
	display: inline-block;
	border: 1px solid $black-color;
	padding: 10px 30px;
	transition: $transition;
	text-transform: uppercase;
	background-color: transparent;
	color: $black-color;
	font: {
		size: 14px;
		weight: 600;
	}
	&:hover {
		background-color: $main-color;
		color: $white-color;
		border-color: $main-color;
	}
}
/*section-title*/
.section-title {
	text-align: center;
	max-width: 720px;
	margin: {
		left: auto;
		right: auto;
		bottom: 50px;
	}
	span {
		display: block;
		margin-bottom: 8px;
		color: $main-color;
		font-weight: 600;
	}
	h2 {
		margin-bottom: 0;
		font: {
			size: 35px;
			weight: 700;
		}
	}
	&.text-left {
		max-width: 100%;
		position: relative;
		margin: {
			left: 0;
			bottom: 30px;
			right: 0;
		}
		.default-btn {
			position: absolute;
			right: 0;
			bottom: 0;
		}
	}
}

.section-title-alt {
	text-align: center;
	max-width: 720px;
	margin: {
		top: 30px;
		left: auto;
		right: auto;
		bottom: 0px;
	}
	span {
		display: block;
		margin-bottom: 8px;
		color: $main-color;
		font-weight: 600;
	}
	h2 {
		margin-bottom: 0;
		font: {
			size: 35px;
			weight: 700;
		}
	}
	&.text-left {
		max-width: 100%;
		position: relative;
		margin: {
			left: 0;
			bottom: 30px;
			right: 0;
		}
		.default-btn {
			position: absolute;
			right: 0;
			bottom: 0;
		}
	}
}
/*form-control*/
.form-control {
	background-color: #f5f5f5;
	height: 48px;
	border-radius: 0;
	box-shadow: unset !important;
	padding: 0 0 0 15px;
	border: 1px solid #f5f5f5;
	color: $black-color;
	font-size: $font-size;
	transition: $transition;

	&:focus {
		border-color: $main-color;
		background-color: $white-color;

		&::placeholder {
			color: transparent;
		}
	}
	&::placeholder {
		transition: $transition;
	}
}
textarea.form-control {
	padding-top: 15px;
	height: auto !important;
}

.coupon {
	margin-left: 15px;
}

/*================================================
Top Header CSS
=================================================*/
.top-header {
	background-color: $black-color;
	padding: {
		top: 12px;
		bottom: 12px;
	}
	.container-fluid {
		padding: {
			left: 30px;
			right: 30px;
		}
	}
}
.header-contact-info {
	padding-left: 0;
	margin-bottom: 0;
	list-style-type: none;

	li {
		display: inline-block;
		color: #d0d0d0;
		margin-right: 30px;
		position: relative;
		font: {
			weight: 600;
			size: 14px;
		}
		&::before {
			position: absolute;
			right: -15px;
			top: 6px;
			width: 1px;
			content: "";
			height: 12px;
			background-color: #636161;
		}
		a {
			display: inline-block;
			color: #d0d0d0;
			position: relative;

			&:hover {
				color: $main-color;
			}
			&::before {
				content: "";
				position: absolute;
				bottom: 0;
				left: 0;
				width: 0;
				height: 1px;
				background: $main-color;
				transition: 0.2s;
			}
			&:hover {
				&::before {
					width: 100%;
				}
			}
		}
		&:last-child {
			margin-right: 0;

			&::before {
				display: none;
			}
		}
		.language-switcher {
			position: relative;

			.dropdown-toggle {
				padding: 0;
				border: none;
				background-color: transparent;
				color: #d0d0d0;
				font: {
					weight: 600;
					size: 14.5px;
				}
				&::after {
					display: none;
				}
				img {
					width: 30px;
					border-radius: 3px;
					border: 2px solid $white-color;
					position: relative;
					top: -2px;
				}
				span {
					display: inline-block;
					margin-left: 10px;

					i {
						position: relative;
						font-size: 20px;
						top: 4px;
						margin-left: -3px;
					}
				}
			}

			&:hover {
				.dropdown-menu {
					opacity: 1;
					visibility: visible;
					transition: $transition;
					transform: scaleX(1);
				}
			}

			.dropdown-menu {
				padding: 15px;
				float: unset;
				border: none;
				box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
				border-radius: 0;
				display: block;
				margin-top: 12px;
				opacity: 0;
				visibility: visible;
				transition: $transition;
				transform: scaleX(0);

				.dropdown-item {
					padding: 0;
					color: $black-color;
					margin-bottom: 10px;
					font-weight: 600;
					font-size: 15px;

					&::before {
						display: none;
					}
					&:last-child {
						margin-bottom: 0;
					}
					img {
						width: 30px;
						border-radius: 5px;
						border: 3px solid $white-color;
					}
					span {
						display: inline-block;
						margin-left: 10px;
					}
					&:hover,
					&:focus {
						background-color: transparent !important;
					}
					&:active,
					&.active {
						color: $black-color;
						background-color: transparent;
					}
				}
				&.show {
					opacity: 1;
					visibility: visible;
					transform: scaleX(1);
				}
			}
		}
	}
}
.top-header-discount-info {
	text-align: center;

	p {
		margin-bottom: 0;
		line-height: initial;
		font-size: 14.5px;
		color: $white-color;

		a {
			display: inline-block;
			font-weight: 700;
			position: relative;
			color: $white-color;

			&::before {
				content: "";
				position: absolute;
				bottom: 0;
				left: 0;
				width: 0;
				height: 1px;
				background: $main-color;
				transition: 0.2s;
			}
			&:hover {
				color: $main-color;

				&::before {
					width: 100%;
				}
			}
		}
	}
}
.header-top-menu {
	text-align: right;
	padding-left: 0;
	margin-bottom: 0;
	list-style-type: none;

	li {
		display: inline-block;
		color: #d0d0d0;
		font-weight: 600;
		margin-right: 30px;
		position: relative;
		font-size: 14px;

		&::before {
			position: absolute;
			right: -15px;
			top: 6px;
			width: 1px;
			content: "";
			height: 12px;
			background-color: #636161;
		}
		i {
			position: absolute;
			left: 0;
			top: 2.5px;
			font-size: 16px;
		}
		a {
			display: inline-block;
			color: #d0d0d0;
			padding-left: 22px;
			position: relative;

			&:hover {
				color: $main-color;
			}
			&::before {
				content: "";
				position: absolute;
				bottom: 0;
				left: 0;
				width: 0;
				height: 1px;
				background: $main-color;
				transition: 0.2s;
			}
			&:hover {
				&::before {
					width: 100%;
				}
			}
		}
		&:last-child {
			margin-right: 0;

			&::before {
				display: none;
			}
		}
	}
}
.header-top-others-option {
	display: none;
	padding-left: 0;
	color: white;
	list-style-type: none;
	margin: {
		top: 15px;
		bottom: 0;
	}
	.option-item {
		display: inline-block;
		margin-left: 15px;
		position: relative;
		z-index: 3;
		color: #d0d0d0;

		&:first-child {
			margin-left: 0;
		}
		.search-btn-box {
			position: relative;
			top: 2px;

			.search-btn {
				font-size: 20px;
				cursor: pointer;
				color: #d0d0d0;
				transition: $transition;
			}
		}
		.cart-btn {
			font-size: 20px;
			position: relative;
			line-height: 1;

			a {
				display: inline-block;
				position: relative;
				color: #d0d0d0;

				span {
					position: absolute;
					right: -4px;
					top: -4px;
					width: 14px;
					height: 14px;
					text-align: center;
					line-height: 13.9px;
					border-radius: 50%;
					color: $white-color;
					background-color: $main-color;
					font: {
						size: 10px;
						weight: 600;
					}
				}
			}
		}
	}
}

.break-description {
	overflow-wrap: break-word;
}

/*================================================
Navbar CSS
=================================================*/
.colored-navbar-area {
	background-color: $main-color;
}
.navbar-area {
	.cart-icon {
		height: 30px;
	}

	&.is-sticky {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 999;
		box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
		background: $white-color !important;
		transition: $transition;
		color: $main-color !important;
		border-bottom: 2px solid white;
	}
	&.navbar-style-two {
		.main-nav {
			.navbar {
				.navbar-nav {
					margin: {
						left: 50px;
						right: auto;
					}
				}
			}
		}
	}
}

.success-container {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.success-hint {
	margin-bottom: 10px;
}

.main-nav-logo {
	height: 50px;
	padding: 2px 0px;
}

.main-nav {
	width: 100vw;
	position: absolute;
	height: $navbar-height;
	z-index: 99;
	top: 0;

	&.is-sticky {
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		z-index: 999;
		box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
		background: $main-color-light !important;
		transition: $transition;
		border-bottom: 2px solid white;
	}

	.container {
		width: 100%;
		padding-right: 15px;
		padding-left: 15px;
		margin-right: auto;
		margin-left: auto;
	}

	.navbar {
		position: inherit;
		height: 10vh;
		padding: {
			left: 0;
			right: 0;
		}
		.navbar-logo {
			float: left;
			height: 8vh !important;
			margin-top: 1vh;
			// height: 100%;
		}
		.navbar-brand {
			height: 9vh !important;
			font-size: 30px;
			line-height: 1;
			// margin-bottom: 10px;
			font-weight: bold;
			padding: {
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
			}
		}
		ul {
			padding-left: 0;
			list-style-type: none;
			margin-bottom: 0;
		}
		.navbar-nav {
			margin: {
				left: auto;
				right: auto;
			}
			.nav-item {
				position: relative;

				.nav-custom-link {
					height: 50px;
					// font-family: $primary-font;
					cursor: pointer;
				}

				a {
					text-transform: uppercase;
					size: 18px;
					font-weight: 900;
					color: white;
					font: {
						size: 16.5px;
						weight: 600;
						text-transform: uppercase;
					}
					padding: {
						left: 0;
						right: 0;
						top: 20px;
						bottom: 20px;
					}
					margin: {
						left: 13px;
						right: 13px;
					}
					i {
						font-size: 18px;
						position: relative;
						top: 2px;
						display: inline-block;
						margin: {
							left: -3px;
							right: -3px;
						}
					}
					&:hover,
					&:focus,
					&.active {
						color: $main-color;
					}
				}
				&:last-child {
					a {
						margin-right: 0;
					}
				}
				// &:first-child {
				//     a {
				//         margin-left: 0;
				//     }
				// }
				&:hover,
				&.active {
					a {
						color: #ffbe58;
					}
				}
				.dropdown-menu {
					box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
					background: $white-color;
					position: absolute;
					border: none;
					top: 70px;
					left: 0;
					width: 220px;
					z-index: 99;
					display: block;
					opacity: 0;
					visibility: hidden;
					border-radius: 0;
					// transition: background-color 0.1s ease-in-out;
					padding: 0;
					transform: scaleX(0);

					&.alternative {
						width: 180px;

@media only screen and (min-width: 1280px) {

	left: -100px;
  
}
					}

					li {
						a {
							padding: 8px 8px 6px;
							margin: 0;
							position: relative;
							display: block;
							color: #666666;
							// border-bottom: 1px dashed #e5e5e5;
							font: {
								size: 14px;
								weight: 500;
								family: $font-family2;
							}
							i {
								margin: 0;
								position: absolute;
								top: 50%;
								font-size: 20px;
								transform: translateY(-50%);
								right: 10px;
							}
							&:hover {
								// color: white!important;
								background-color: $main-color;
								// transition: background-color 0.1s !important;
							}

							span {
								background-color: red;
								color: #fff;
								font-size: 8px;
								padding: 2px 5px;
								border-radius: 4px;
								position: relative;
								top: -8px;
								left: 7px;
							}
						}
						&:hover {
							a {
								color: white;
							}
							background-color: $main-color;
							// transition: background-color 0.1s ease-out !important;
						}
						.dropdown-menu {
							left: 220px;
							top: 0;
							opacity: 0;
							visibility: hidden;
							transform: scaleY(0);

							li {
								a {
									color: #515050;

									&:hover,
									&:focus,
									&.active {
										color: white;
									}
								}
								.dropdown-menu {
									left: 230px;
									top: 0;
									opacity: 0;
									visibility: hidden;
									transform: scaleY(0);

									li {
										a {
											color: #515050;

											&:hover,
											&:focus,
											&.active {
												color: $main-color;
											}
										}
										.dropdown-menu {
											left: 240px;
											top: 0;
											opacity: 0;
											visibility: hidden;
											transform: scaleY(0);

											li {
												a {
													color: #515050;

													&:hover,
													&:focus,
													&.active {
														color: $main-color;
													}
												}
												.dropdown-menu {
													left: 240px;
													top: 0;
													opacity: 0;
													visibility: hidden;
													transform: scaleY(0);

													li {
														a {
															color: #515050;

															&:hover,
															&:focus,
															&.active {
																color: $main-color;
															}
														}
														.dropdown-menu {
															left: 240px;
															top: 0;
															opacity: 0;
															visibility: hidden;
															transform: scaleY(0);

															li {
																a {
																	color: #515050;

																	&:hover,
																	&:focus,
																	&.active {
																		color: $main-color;
																	}
																}
																.dropdown-menu {
																	left: 240px;
																	top: 0;
																	opacity: 0;
																	visibility: hidden;
																	transform: scaleY(0);

																	li {
																		a {
																			color: #515050;

																			&:hover,
																			&:focus,
																			&.active {
																				color: $main-color;
																			}
																		}
																	}
																}
																&.active {
																	a {
																		color: $main-color;
																	}
																}
																&:hover {
																	.dropdown-menu {
																		opacity: 1;
																		visibility: visible;
																		transform: scaleY(1);
																	}
																}
															}
														}
														&.active {
															a {
																color: $main-color;
															}
														}
														&:hover {
															.dropdown-menu {
																opacity: 1;
																visibility: visible;
																transform: scaleY(1);
															}
														}
													}
												}
												&.active {
													a {
														color: $main-color;
													}
												}
												&:hover {
													.dropdown-menu {
														opacity: 1;
														visibility: visible;
														transform: scaleY(1);
													}
												}
											}
										}
										&.active {
											a {
												color: $main-color;
											}
										}
										&:hover {
											.dropdown-menu {
												opacity: 1;
												visibility: visible;
												transform: scaleY(1);
											}
										}
									}
								}
								&.active {
									a {
										color: $main-color;
									}
								}
								&:hover {
									.dropdown-menu {
										opacity: 1;
										visibility: visible;
										transform: scaleY(1);
									}
								}
							}
						}
						&.active {
							a {
								color: $main-color;
							}
						}
						&:hover {
							.dropdown-menu {
								opacity: 1;
								visibility: visible;
								transform: scaleY(1);
							}
						}
					}
				}
				&:hover {
					.dropdown-menu {
						opacity: 1;
						visibility: visible;
						transform: scaleX(1);
					}
				}
				&.megamenu {
					position: inherit;
					height: 70px;

					.container {
						display: block;
						flex-wrap: unset;
						align-items: unset;
						justify-content: unset;
					}
					.dropdown-menu {
						width: 100%;
						margin-top: 8px;
						position: absolute;
						top: auto;
						left: 0;
						padding: 30px 0;
						transform: unset !important;

						.submenu-title {
							color: $black-color;
							position: relative;
							border-bottom: 1px solid #eeeeee;
							padding-bottom: 8px;
							text-transform: uppercase;
							margin: {
								bottom: 20px;
								top: 25px;
							}
							font: {
								size: 15.5px;
								weight: 600;
							}
							&::before {
								width: 30px;
								height: 1px;
								content: "";
								position: absolute;
								left: 0;
								bottom: -1px;
								background-color: $main-color;
							}
							&:first-child {
								margin-top: 0;
							}
						}
						a {
							border-bottom: none !important;
						}
						.megamenu-submenu {
							li {
								a {
									margin-top: 14px;
									color: #666666;
									padding: 0;
									font: {
										weight: 400;
									}
									&:hover,
									&.active {
										color: $main-color;
									}
								}
							}
							.aside-trending-products {
								overflow: hidden;
								position: relative;
								text-align: center;

								img {
									transition: $transition;
								}
								.category {
									position: absolute;
									left: 0;
									transition: $transition;
									right: 0;
									bottom: 0;
									background-color: $white-color;
									padding: 15px;
									margin: {
										left: 10px;
										right: 10px;
									}
									h4 {
										text-transform: uppercase;
										margin-bottom: 0;
										position: relative;
										z-index: 1;
										font: {
											size: $font-size;
											weight: 600;
										}
									}
								}
								.link-btn {
									position: absolute;
									top: 0;
									left: 0;
									display: block;
									width: 100%;
									height: 100%;
									z-index: 3;
								}
								&:not(:first-child) {
									margin-top: 15px;
								}
								&:hover {
									img {
										transform: scale(1.3);
									}
									.category {
										bottom: 10px;
									}
								}
							}
						}
						.brand-slides {
							margin-top: 30px;
							border: {
								top: 1px solid #eeeeee;
								bottom: 1px solid #eeeeee;
								left: 1px solid #eeeeee;
							}
						}
					}
				}
			}
		}
		.user-btn {
			font-size: 30px !important;
		}
		.others-option {
			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			.option-item {
				// display: inline-block;
				margin-left: 15px;
				position: relative;
				z-index: 3;
				color: white !important;

				&:first-child {
					margin-left: 0;
				}
				.search-btn-box {
					position: relative;
					top: 1px;

					.search-btn {
						font-size: 25px;
						cursor: pointer;
						color: $white-color;
						transition: $transition;
					}
				}
				.cart-btn {
					font-size: 25px;
					position: relative;
					line-height: 1;

					a {
						display: inline-block;
						position: relative;
						color: $white-color;

						span {
							position: absolute;
							right: -4px;
							top: -4px;
							width: 14px;
							height: 14px;
							text-align: center;
							line-height: 13.9px;
							border-radius: 50%;
							color: $white-color;
							background-color: $main-color;
							font: {
								size: 10px;
								weight: 600;
							}
						}
					}
				}
				.burger-menu {
					cursor: pointer;
					transition: $transition;
					position: relative;
					top: 4px;
					margin-left: 5px;

					span {
						height: 1px;
						width: 25px;
						background: $white-color;
						display: block;
						margin: 6px 0;
						transition: all 0.5s ease-in-out;
					}
					&.active {
						span {
							&.top-bar {
								transform: rotate(45deg);
								transform-origin: 10% 10%;
							}
							&.middle-bar {
								opacity: 0;
							}
							&.bottom-bar {
								transform: rotate(-45deg);
								transform-origin: 10% 90%;
								margin-top: 5px;
							}
						}
					}
				}
			}
		}
	}
}

.main-colored-nav {
	@extend .main-nav;
	background-color: $main-color-light;
}

// Navbar toggler
.navbar-toggler {
	border: none;
	background: #eee !important;
	padding: 10px;
	border-radius: 0;

	.icon-bar {
		width: 28px;
		transition: all 0.3s;
		background: $main-color;
		height: 2px;
		display: block;
	}
	.top-bar {
		transform: rotate(45deg);
		transform-origin: 10% 10%;
		left: 4px;
		position: relative;
	}
	.middle-bar {
		opacity: 0;
		margin: 6px 0;
	}
	.bottom-bar {
		transform: rotate(-45deg);
		transform-origin: 10% 90%;
		left: 4px;
		position: relative;
	}
	&.collapsed {
		.top-bar {
			transform: rotate(0);
			left: 0;
		}
		.middle-bar {
			opacity: 1;
		}
		.bottom-bar {
			transform: rotate(0);
			left: 0;
		}
	}
}

// Mobile and iPad Navbar
@media only screen and (max-width: 991px) {
	.navbar-area {
		padding: {
			top: 15px;
			bottom: 15px;
		}
		&.header-sticky {
			display: none;
		}
	}
}

/*================================================
Search Overlay CSS
=================================================*/
.search-overlay {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 99999;
	opacity: 0;
	visibility: hidden;
	transition: all 0.3s ease-in-out;
	overflow: hidden;

	.search-overlay-layer {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		z-index: 1;
		transform: translateX(100%);

		&:nth-child(1) {
			left: 0;
			background-color: rgba(0, 0, 0, 0.5);
			transition: all 0.3s ease-in-out 0s;
		}
		&:nth-child(2) {
			left: 0;
			background-color: rgba(0, 0, 0, 0.4);
			transition: all 0.3s ease-in-out 0.3s;
		}
		&:nth-child(3) {
			left: 0;
			background-color: rgba(0, 0, 0, 0.7);
			transition: all 0.9s ease-in-out 0.6s;
		}
	}
	.search-overlay-close {
		position: absolute;
		top: 40px;
		right: 40px;
		width: 50px;
		z-index: 2;
		text-align: center;
		cursor: pointer;
		padding: 10px;
		transition: all 0.9s ease-in-out 1.5s;
		opacity: 0;
		visibility: hidden;

		.search-overlay-close-line {
			width: 100%;
			height: 3px;
			float: left;
			margin-bottom: 5px;
			background-color: $white-color;
			transition: all 500ms ease;

			&:nth-child(1) {
				transform: rotate(45deg);
			}
			&:nth-child(2) {
				margin-top: -7px;
				transform: rotate(-45deg);
			}
		}
		&:hover {
			.search-overlay-close-line {
				background: $main-color;
				transform: rotate(180deg);
			}
		}
	}
	.search-overlay-form {
		transition: all 0.9s ease-in-out 1.4s;
		opacity: 0;
		visibility: hidden;
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translateY(-50%) translateX(-50%);
		z-index: 2;
		max-width: 500px;
		width: 500px;

		form {
			position: relative;

			.input-search {
				display: block;
				width: 100%;
				height: 55px;
				border: none;
				border-radius: 30px;
				padding-left: 20px;
				color: $black-color;
				font-size: $font-size;
			}
			button {
				position: absolute;
				right: 5px;
				top: 5px;
				width: 45px;
				color: $white-color;
				height: 45px;
				border-radius: 50%;
				background-color: $main-color;
				transition: $transition;
				border: none;
				font-size: 20px;
				line-height: 45px;

				&:hover {
					background-color: $black-color;
					color: $white-color;
				}
			}
		}
	}
	&.active {
		&.search-overlay {
			opacity: 1;
			visibility: visible;

			.search-overlay-layer {
				transform: translateX(0);
			}
			.search-overlay-close {
				opacity: 1;
				visibility: visible;
			}
			.search-overlay-form {
				opacity: 1;
				visibility: visible;
			}
		}
	}
}

.video-banner {
	width: 100vw;
	object-fit: fill;
	height: 100vh;
	position: fixed;
	z-index: -1;
	// position: relative;
}

.video-thumb {
	position: absolute;
	top: 0;
	// left: 0;
	width: auto !important;
	height: 100%;
	transition: opacity 1000ms ease 0ms;
}
.tiny {
	filter: blur(15px);
	transform: scale(0.8);
	transition: visibility 0ms ease 1000ms;
}

.video-overlay {
	background-color: black;
	opacity: 0.5;
	position: absolute;
	height: 100%;
	width: 100%;
}

/*================================================
Main Banner CSS
=================================================*/
.main-banner {
	width: 100vw;
	height: 100vh;
	position: relative;
	z-index: 1;
	background: {
		position: top center;
		size: cover;
		repeat: no-repeat;
	}
}

.bubble-header {
	background-color: rgba(355, 355, 355, 0.7);
	padding: 10px;
	display: inline-block;
	width: fit-content;
	border-radius: 8px !important;
	font-weight: 300;
	font-family: "Muli", -apple-system, BlinkMacSystemFont, Arial sans-serif;
}

.bubble-header-alt {
	@extend .bubble-header;
	width: fit-content;
	font-family: "Muli", -apple-system, BlinkMacSystemFont, Arial sans-serif;
	background-color: #184d5ea2;
	color: $white-color !important;
}

.bubble-subheader {
	@extend .bubble-header;
	margin-top: 20px;
}

.bubble-subheader-alt {
	@extend .bubble-header-alt;
	margin-top: 20px;
}

.main-banner-content-alt {
	background-color: rgba(355, 355, 355, 0.7);
	padding: 14px;
	width: 35%;
	border-radius: 8px !important;
	font-weight: 300;
	font-family: "Muli", -apple-system, BlinkMacSystemFont, Arial sans-serif;
	background-color: #ffbd5a6b;
	-webkit-box-shadow: 7px 9px 63px -5px #ffbd5a6b;
	-moz-box-shadow: 7px 9px 63px -5px #ffbd5a6b;
	box-shadow: 7px 9px 63px -5px #ffbd5a6b;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	h2, p {
		text-align: center !important;
	}

	.bubble-header {
		background-color: transparent;
	}
	
	.bubble-header-alt {
		background-color: transparent;
	}
	
	.bubble-subheader {
		background-color: transparent;
	}
	
	.bubble-subheader-alt {
		background-color: transparent;
	}
}

.banner-bg1 {
	// background: {
	//     video: url(../../../videos/video1.mp4);
	//     // image: url(../../../images/slider/slider_1.jpg);
	// };
}
.banner-bg2 {
	// background: {
	//     image: url(../../../images/slider/slider_2.jpg);
	// };
}
.banner-bg3 {
	// background: {
	//     image: url(../../../images/slider/handshake.jpg);
	// };
}
.banner-bg4 {
	background: {
		image: url(../../../images/offer-bg.jpg);
	}
}
.banner-bg5 {
	background: {
		image: url(../../../images/main-banner5.jpg);
	}
}
.banner-bg6 {
	background: {
		image: url(../../../images/main-banner6.jpg);
	}
}
.banner-bg7 {
	background: {
		image: url(../../../images/main-banner2.jpg);
	}
}
.banner-bg8 {
	background: {
		image: url(../../../images/main-banner2.jpg);
	}
}
.banner-bg9 {
	background: {
		image: url(../../../images/main-banner2.jpg);
	}
}
.banner-bg10 {
	background: {
		image: url(../../../images/home6/main-banner7.jpg);
	}
}
.banner-bg11 {
	background: {
		image: url(../../../images/home6/main-banner8.jpg);
	}
}
.banner-section {
	height: 650px;
	position: relative;
	z-index: 1;
	background: {
		position: top center;
		size: cover;
		repeat: no-repeat;
	}
	.main-banner-content {
		max-width: 100%;
		margin-left: 0;
	}
}
.main-banner-content {
	width: 60%;
	display: flex;
	flex-direction: column;
	margin-top: 100px;
	.sub-title {
		display: block;
		margin-bottom: 7px;
		font: {
			size: 17.5px;
			weight: 600;
		}
	}
	h1 {
		margin-bottom: 12px;
		font: {
			size: 65px;
			weight: 700;
		}
	}
	p {
		color: $black-color;
		font: {
			weight: 500;
			size: 17px;
		}
	}
	.btn-box {
		margin-top: 28px;

		.optional-btn {
			margin-left: 15px;
		}
	}
	&.text-white {
		.submenu-title {
			color: $white-color;
		}
		h1 {
			color: $white-color;
		}
		p {
			color: $white-color;
			opacity: 0.92;
		}
		.btn-box {
			.default-btn {
				background-color: $white-color;
				color: $black-color;
				border-color: $white-color;

				&:hover {
					color: $white-color;
					background-color: $black-color;
					border-color: $black-color;
				}
			}
			.optional-btn {
				border-color: $white-color;
				color: $white-color;

				&:hover {
					color: $white-color;
					background-color: $black-color;
					border-color: $black-color;
				}
			}
		}
	}
	&.text-center {
		.btn-box {
			.optional-btn {
				margin-left: 10px;
			}
			.default-btn {
				margin-right: 10px;
			}
		}
	}
}
.banner-content {
	position: relative;
	overflow: hidden;
	padding: 55px;
	max-width: 640px;
	z-index: 1;
	margin: {
		left: auto;
	}
	.line {
		top: 0;
		left: 0;
		content: "";
		z-index: -1;
		width: 100%;
		height: 100%;
		position: absolute;
		display: inline-block;
		border: 9px solid $black-color;
		clip-path: polygon(0 0, 60% 0, 36% 100%, 0 100%);
	}
	.sub-title {
		display: block;
		font: {
			weight: 300;
			size: 30px;
		}
	}
	h1 {
		margin: 12px 0 20px;
		font: {
			size: 65px;
			weight: 700;
		}
	}
	p {
		color: $black-color;
		max-width: 495px;
		line-height: 1.6;
		font: {
			size: 20px;
			weight: 300;
		}
	}
	.btn-box {
		margin-top: 25px;

		.optional-btn {
			margin-left: 15px;
		}
	}
	&.text-white {
		.submenu-title {
			color: $white-color;
		}
		.line {
			border-color: $white-color;
		}
		h1 {
			color: $white-color;
		}
		p {
			color: $white-color;
			opacity: 0.92;
		}
		.btn-box {
			.default-btn {
				background-color: $white-color;
				color: $black-color;
				border-color: $white-color;

				&:hover {
					color: $white-color;
					background-color: $black-color;
					border-color: $black-color;
				}
			}
			.optional-btn {
				border-color: $white-color;
				color: $white-color;

				&:hover {
					color: $white-color;
					background-color: $black-color;
					border-color: $black-color;
				}
			}
		}
	}
}
.hero-banner {
	padding-top: 30px;
	position: relative;
	z-index: 1;
	background-color: #f4f4f4;

	.main-banner-content {
		max-width: 100%;
		margin-left: 0;
	}
}
.banner-image {
	text-align: center;
	position: relative;
	z-index: 1;

	.circle {
		width: 470px;
		height: 470px;
		border-radius: 50%;
		background-color: rgba(255, 255, 255, 0.6);
		z-index: -1;
		position: absolute;
		left: 0;
		top: 5%;
		right: 0;
		margin: 0 auto;
	}
	img {
		width: unset !important;
		display: inline-block !important;
	}
}
.owl-item {
	&.active {
		.banner-content {
			.sub-title {
				animation: {
					duration: 1s;
					fill-mode: both;
					name: fadeInDown;
					delay: 0.3s;
				}
			}
			h1 {
				animation: {
					duration: 1s;
					fill-mode: both;
					name: fadeInDown;
					delay: 0.3s;
				}
			}
			p {
				animation: {
					duration: 1s;
					fill-mode: both;
					name: fadeInUp;
					delay: 0.3s;
				}
			}
			.btn-box {
				animation: {
					duration: 1s;
					fill-mode: both;
					name: fadeInUp;
					delay: 0.3s;
				}
			}
			.line {
				animation: {
					duration: 1s;
					fill-mode: both;
					name: fadeInLeft;
					delay: 0.3s;
				}
			}
		}
	}
}
.home-slides {
	// position:absolute!important;
	top: 0 !important;
	height: 100vh !important;
	// z-index:50;
	&.owl-theme {
		.owl-nav {
			margin-top: 0;

			[class*="owl-"] {
				color: $black-color;
				font-size: 45px;
				margin: 0;
				padding: 0;
				background: transparent;
				display: inline-block;
				border-radius: 0;
				transition: $transition;
				position: absolute;
				left: 0;
				top: 50%;
				opacity: 0;
				visibility: hidden;
				transform: translateY(-50%);

				&.owl-next {
					left: auto;
					right: 0;
				}
				&:hover {
					color: $black-color;
					transform: translateX(-10px) translateY(-50%);

					&.owl-next {
						transform: translateX(10px) translateY(-50%);
					}
				}
			}
		}
		.owl-dots {
			position: absolute;
			left: 0;
			right: 0;
			bottom: 20px;

			.owl-dot {
				span {
					width: 15px;
					height: 15px;
					margin: 0 4px;
					background: transparent;
					position: relative;
					transition: $transition;
					border-radius: 50%;
					border: 1px solid transparent;

					&::before {
						content: "";
						position: absolute;
						left: 0;
						top: 0;
						right: 0;
						bottom: 0;
						border-radius: 50%;
						background-color: $black-color;
						margin: 2px;
					}
				}
				&:hover,
				&.active {
					span {
						border-color: $black-color;
					}
				}
			}
		}
		&:hover {
			.owl-nav {
				[class*="owl-"] {
					left: 20px;
					opacity: 1;
					visibility: visible;

					&.owl-next {
						left: auto;
						right: 20px;
					}
				}
			}
		}
	}
}
.home-slides-two {
	&.owl-theme {
		.owl-nav {
			margin-top: 0;

			[class*="owl-"] {
				color: $white-color;
				font-size: 45px;
				margin: 0;
				padding: 0;
				background: transparent;
				display: inline-block;
				border-radius: 0;
				transition: $transition;
				position: absolute;
				left: 0;
				top: 50%;
				opacity: 0;
				visibility: hidden;
				transform: translateY(-50%);

				&.owl-next {
					left: auto;
					right: 0;
				}
				&:hover {
					color: $white-color;
					transform: translateX(-10px) translateY(-50%);

					&.owl-next {
						transform: translateX(10px) translateY(-50%);
					}
				}
			}
		}
		.owl-dots {
			position: absolute;
			left: 0;
			right: 0;
			bottom: 20px;

			.owl-dot {
				span {
					width: 15px;
					height: 15px;
					margin: 0 4px;
					background: transparent;
					position: relative;
					transition: $transition;
					border-radius: 50%;
					border: 1px solid transparent;

					&::before {
						content: "";
						position: absolute;
						left: 0;
						top: 0;
						right: 0;
						bottom: 0;
						border-radius: 50%;
						background-color: $black-color;
						margin: 2px;
					}
				}
				&:hover,
				&.active {
					span {
						border-color: $black-color;
					}
				}
			}
		}
		&:hover {
			.owl-nav {
				[class*="owl-"] {
					left: 20px;
					opacity: 1;
					visibility: visible;

					&.owl-next {
						left: auto;
						right: 20px;
					}
				}
			}
		}
	}
}
.home-slides-three {
	&.owl-theme {
		.owl-dots {
			position: absolute;
			right: 20px;
			top: 50%;
			transform: translateY(-50%);
			margin-top: 0 !important;

			.owl-dot {
				display: block;

				span {
					width: 15px;
					height: 15px;
					background: transparent;
					position: relative;
					transition: $transition;
					border-radius: 50%;
					border: 1px solid transparent;
					margin: {
						left: 0;
						right: 0;
						top: 3px;
						bottom: 3px;
					}
					&::before {
						content: "";
						position: absolute;
						left: 0;
						top: 0;
						right: 0;
						bottom: 0;
						border-radius: 50%;
						background-color: $black-color;
						margin: 2px;
					}
				}
				&:hover,
				&.active {
					span {
						border-color: $black-color;
					}
				}
			}
		}
	}
}

/*================================================
About CSS
=================================================*/
.about-area {
	position: relative;
	z-index: 1;
}
.about-image {
	position: relative;
	padding: {
		right: 50px;
		bottom: 220px;
	}
	img {
		border-radius: 5px;

		&:nth-child(2) {
			position: absolute;
			right: -10px;
			bottom: -50px;
			z-index: 1;
			box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.336) !important;
		}
	}
}

.about-banner-image {
	box-shadow: 0 1rem 1rem rgba(0, 0, 0, 0.336) !important;
}
.about-content {
	padding-left: 15px;

	.sub-title {
		display: block;
		font-size: 25px;
		margin-bottom: 8px;
		color: $main-color;
		font-weight: 600;
	}

	h2 {
		margin-bottom: 15px;
		line-height: 1.3;
		position: relative;
		border-bottom: 1px solid rgba(109, 109, 109, 0.322);
		font: {
			size: 35px;
			weight: 700;
		}
		&::before {
			width: 50px;
			height: 1px;
			bottom: -1px;
			content: "";
			position: absolute;
			left: 0;
			background-color: $main-color;
		}
	}

	h6 {
		margin-bottom: 14px;
		line-height: 1.6;
		font-size: 16px;
	}
	.features-text {
		margin-top: 25px;

		h5 {
			margin-bottom: 22px;
			position: relative;
			font: {
				size: 17px;
				weight: 600;
			}
			padding: {
				left: 47px;
				top: 7px;
			}
		}
		i {
			position: absolute;
			left: 0;
			top: 0;
			width: 35px;
			height: 35px;
			background-color: $main-color;
			text-align: center;
			line-height: 35px;
			color: $white-color;
			font-size: 20px;
			box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
			border-radius: 50%;
		}
	}
}
.about-inner-area {
	margin-top: 0px;
}
.about-text {
	h3 {
		position: relative;
		margin-bottom: 20px;
		padding-bottom: 8px;
		border-bottom: 1px solid #eeeeee;
		font: {
			size: 22px;
			weight: 700;
		}
		&::before {
			content: "";
			position: absolute;
			left: 0;
			bottom: -1px;
			width: 50px;
			background-color: $main-color;
			height: 1px;
		}
	}

	.centered {
		text-align: initial;
	}
	p {
		color: $black-color;
		font-size: 16px;
	}
	.features-list {
		padding-left: 0;
		list-style-type: none;
		margin: {
			top: 20px;
			bottom: 0;
		}
		li {
			text-align: justify;
			position: relative;
			margin-bottom: 13px;
			color: $black-color;
			padding: {
				left: 30px;
			}
			font-size: 1rem;
			i {
				font-size: 16px;
				color: $white-color;
				background-color: $main-color;
				width: 20px;
				height: 20px;
				line-height: 20px;
				border-radius: 50%;
				transition: $transition;
				text-align: center;
				position: absolute;
				left: 0;
				top: 15px;
				transform: translateY(-50%);
			}
			&:last-child {
				margin-bottom: 0;
			}
			&:hover {
				i {
					background-color: #eaeaf2;
					color: $black-color;
				}
			}
		}
	}
	.features-list-special {
		padding-left: 0;
		list-style-type: none;
		margin: {
			top: 55px;
			bottom: 0;
		}
		li {
			font-size: 1rem;
			text-align: justify;
			position: relative;
			margin-bottom: 13px;
			color: $black-color;
			padding: {
				left: 30px;
			}
			i {
				font-size: 16px;
				color: $white-color;
				background-color: $main-color;
				width: 20px;
				height: 20px;
				line-height: 20px;
				border-radius: 50%;
				transition: $transition;
				text-align: center;
				position: absolute;
				left: 0;
				top: 15px;
				transform: translateY(-50%);
			}
			&:last-child {
				margin-bottom: 0;
			}
			&:hover {
				i {
					background-color: #eaeaf2;
					color: $black-color;
				}
			}
		}
	}
}

.course-presentation {
	video{
		width: 100%;
		height: auto;
		border-radius: 5px;
		border: 2px solid #dcd8d823;
	}

}

.text-blue {
	color: $main-color;
}

/*================================================
Categories Banner CSS
=================================================*/
.single-categories-box {
	margin-bottom: 30px;
	overflow: hidden;
	border-radius: 5px;
	position: relative;

	.special {
		border: 2px solid #dcd8d823;
	}

	.course-presentation {
		width: 100%;
		height: auto;
		border-radius: 5px;
	}

	img {
		transition: $transition;
		border-radius: 5px;
		width: 100%;
	}
	.content {
		position: absolute;
		right: 0;
		bottom: 0;
		padding: 25px 25px 0;
		width: 100%;
		text-align: right;

		span {
			display: block;
			margin-bottom: 8px;
			transition: $transition;
			color: $main-color;
			font-weight: 600;
			transform: translateY(12px);
		}
		h3 {
			margin-bottom: 0;
			transition: $transition;
			color: $main-color;
			transform: translateY(12px);
			font: {
				size: 30px;
				weight: 700;
			}
		}
		.default-btn {
			background-color: transparent;
			color: $black-color;
			border-color: $black-color;
			opacity: 0;
			visibility: hidden;
			transform: translateY(10px);
		}
		.description {
			margin-bottom: 20px;
			height: 9vh;
			font-size: 15px !important;
			color: $main-color !important;
			max-width: 100%;
			opacity: 0;
			visibility: hidden;
			transform: translateY(10px);
		}

		.block-with-text {
			-webkit-box-orient: vertical;
			display: -webkit-box;
			-webkit-line-clamp: 2;
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: normal;
		}

		&.text-white {
			span {
				color: $white-color;
			}
			h3 {
				color: $white-color;
			}
			.description {
				color: $white-color !important;
			}
		}
	}
	.link-btn {
		display: block;
		width: 100%;
		height: 100%;
		z-index: 2;
		left: 0;
		top: 0;
		position: absolute;
	}
	&:hover {
		img {
			transform: scale(1.07);
		}
		.content {
			span {
				transform: translateY(-30px);
			}
			h3 {
				transform: translateY(-30px);
			}
			.default-btn {
				background-color: $main-color;
				color: $white-color;
				border-color: $main-color;
				opacity: 1;
				visibility: visible;
				transform: translateY(-15px);
			}

			.description {
				color: $white-color;
				opacity: 1;
				visibility: visible;
				transform: translateY(-15px);
			}
		}
	}

	.mobile-link-btn {
		display: block;
		width: 100%;
		height: 100%;
		z-index: 2;
		left: 0;
		top: 0;
		position: absolute;
	}
}
.categories-box {
	margin-bottom: 30px;
	overflow: hidden;
	position: relative;

	.bordered {
		border-radius: 10px;
	}

	img {
		transition: $transition;
		width: 100%;
	}
	.link-btn {
		display: block;
		width: 100%;
		height: 100%;
		z-index: 2;
		left: 0;
		top: 0;
		position: absolute;
	}
	.content {
		border-radius: 5px;
		position: absolute;
		top: 30px;
		left: 30px;
		transition: $transition;
		background-color: $white-transparent;
		box-shadow: 1px 1px 3px rgba(136, 136, 136, 0.19);
		padding: {
			top: 8px;
			bottom: 8px;
			left: 25px;
			right: 25px;
		}
		h3 {
			margin-bottom: 0;
			transition: $transition;
			font: {
				size: 30px;
			}
		}
	}
	&:hover {
		img {
			transform: scale(1.07);
		}
		.content {
			background-color: #ffbe58;

			h3 {
				color: $white-color;
			}
		}
	}
}

/*================================================
Products CSS
=================================================*/
.all-products-area {
	.container {
		.container {
			max-width: 100%;
			padding: {
				left: 30px;
				right: 30px;
			}
		}
	}
	.facility-area {
		margin-bottom: 30px;
		background-color: $white-color;
	}
	.products-area {
		margin-bottom: 30px;
		background-color: $white-color;
	}
	.facility-slides {
		&.owl-theme {
			.owl-nav {
				[class*="owl-"] {
					color: #87a1c1;
					background-color: $white-color;
					border: {
						color: $white-color;
					}
					&:hover {
						background-color: $main-color;
						color: $white-color;
						border-color: $main-color;
					}
				}
			}
		}
	}
	.brand-area {
		background-color: $white-color;
	}
	.brand-item {
		a {
			background-color: #f5f5f5;
			border-color: #e7e4e4;
		}
	}
	.categories-banner-area {
		background-color: $white-color;
		margin-bottom: 30px;
	}
	.ptb-100 {
		padding: {
			top: 30px;
			bottom: 30px;
		}
	}
	.pb-70 {
		margin-top: 90vh !important;
		padding-bottom: 0;
	}
	.pt-100 {
		padding-top: 30px;
	}
	.pb-100 {
		padding-bottom: 30px;
	}
}

.single-products-box {
	.products-button-horizontal {
		position: absolute;
		top: 20px;
		right: 20px;
		z-index: 1;

		ul {
			padding-left: 0;
			margin-bottom: 0;
			list-style-type: none;

			li {
				margin-bottom: 8px;
				opacity: 0;
				visibility: hidden;
				transform: translateX(30px);
				transition: all 0.3s ease-in-out 0s;

				a {
					display: inline-block;
					font-size: 25px;
					line-height: 1;
					color: $black-color;
					position: relative;

					&:hover {
						color: $main-color;
					}
					.tooltip-label {
						right: 110%;
						top: 45%;
						transform: translateX(0) translateY(-45%);
						position: absolute;
						padding: 0 10px;
						line-height: 28px;
						font-size: 12px;
						letter-spacing: 0.3px;
						visibility: hidden;
						white-space: nowrap;
						opacity: 0;
						color: transparent;
						background-color: transparent;
						transition: all 0.2s ease-in-out;
						background-color: $main-color;
						color: $white-color;

						&::before {
							content: "\e9ff";
							color: $main-color;
							top: 50%;
							position: absolute;
							transform: translateY(-50%);
							transition: all 0.2s ease-in-out;
							right: -13px;
							font: {
								family: "boxicons" !important;
								weight: normal;
								style: normal;
								size: 20px;
							}
						}
					}
					&:hover {
						.tooltip-label {
							visibility: visible;
							opacity: 1;
							right: 135%;
						}
					}
				}
				&:last-child {
					margin-bottom: 0;
				}
				&:nth-child(1) {
					transition-delay: 0.1s;
				}
				&:nth-child(2) {
					transition-delay: 0.2s;
				}
				&:nth-child(3) {
					transition-delay: 0.3s;
				}
			}
		}
	}
}

.non-resizable-text {
	background-color: transparent;
	border: none;
	resize: none;
}

.special-textarea {
	width: 100%;
	overflow-y: scroll;
	height: 240px;
	resize: none;
}

.single-products-box {
	position: relative;
	margin-bottom: 30px;

	.header {
		padding-right: 40px;
	}

	.no-centered {
		text-align: start !important;
		min-height: 110px;
	}

	.no-centered-short {
		text-align: start !important;
		min-height: 70px;
	}

	.horizontal-modal {
		height: 130px;
	}

	.special-btn {
		top: 0px !important;
		right: 0px !important;
	}

	.horizontal-menu {
		display: flex;
		flex-direction: column;
		align-items: center;
	}

	.products-image {
		text-align: center;
		position: relative;
		overflow: hidden;

		a {
			position: relative;
			display: block;

			img {
				transition: 0.8s;
			}
			.hover-image {
				position: absolute;
				left: 0;
				right: 0;
				opacity: 0;
				visibility: hidden;
				transform: scale(1.07);
			}
		}
		.products-button {
			position: absolute;
			top: 20px;
			right: 20px;
			z-index: 1;

			ul {
				padding-left: 0;
				margin-bottom: 0;
				list-style-type: none;

				li {
					margin-bottom: 8px;
					opacity: 0;
					visibility: hidden;
					transform: translateX(30px);
					transition: all 0.3s ease-in-out 0s;

					a {
						display: inline-block;
						font-size: 25px;
						line-height: 1;
						color: $black-color;
						position: relative;

						&:hover {
							color: $main-color;
						}
						.tooltip-label {
							right: 110%;
							top: 45%;
							transform: translateX(0) translateY(-45%);
							position: absolute;
							padding: 0 10px;
							line-height: 28px;
							font-size: 12px;
							letter-spacing: 0.3px;
							visibility: hidden;
							white-space: nowrap;
							opacity: 0;
							color: transparent;
							background-color: transparent;
							transition: all 0.2s ease-in-out;
							background-color: $main-color;
							color: $white-color;

							&::before {
								content: "\e9ff";
								color: $main-color;
								top: 50%;
								position: absolute;
								transform: translateY(-50%);
								transition: all 0.2s ease-in-out;
								right: -13px;
								font: {
									family: "boxicons" !important;
									weight: normal;
									style: normal;
									size: 20px;
								}
							}
						}
						&:hover {
							.tooltip-label {
								visibility: visible;
								opacity: 1;
								right: 135%;
							}
						}
					}
					&:last-child {
						margin-bottom: 0;
					}
					&:nth-child(1) {
						transition-delay: 0.1s;
					}
					&:nth-child(2) {
						transition-delay: 0.2s;
					}
					&:nth-child(3) {
						transition-delay: 0.3s;
					}
				}
			}
		}
		.sale-tag {
			position: absolute;
			left: 20px;
			bottom: 20px;
			background-color: $green-banner;
			color: $white-color;
			width: 55px;
			height: 55px;
			line-height: 55px;
			text-align: center;
			border-radius: 50%;
			z-index: 2;
			text-transform: uppercase;
			font: {
				weight: 700;
				size: 14px;
			}
		}
		.new-tag {
			position: absolute;
			right: 20px;
			bottom: 20px;
			background-color: $green-banner;
			color: $white-color;
			text-align: center;
			z-index: 2;
			padding: 1px 7px;
			text-transform: uppercase;
			font: {
				weight: 700;
				size: 14px;
			}
		}
	}
	.products-content {
		margin-top: 20px;
		position: relative;

		h3 {
			margin-bottom: 0;
			font: {
				size: 20px;
				weight: 600;
			}
			a {
				display: inline-block;
				color: $black-color;

				&:hover {
					color: $main-color;
				}
			}
		}
		.price {
			margin-top: 8px;
			transition: $transition;
			font: {
				size: $font-size;
				weight: 600;
			}
			.old-price {
				text-decoration: line-through;
				color: #999999;
				font-weight: 500;
				margin-right: 10px;
			}
		}
		.star-rating {
			position: absolute;
			right: 0;
			bottom: 0;
			color: #f49f0b;

			i {
				display: inline-block;
			}
		}
		.add-to-cart {
			position: absolute;
			left: 0;
			bottom: 0;
			text-transform: uppercase;
			transition: $transition;
			display: inline-block;
			color: $optional-color;
			opacity: 0;
			visibility: hidden;
			transform: translateX(20px);
			cursor: pointer;
			font: {
				size: 14px;
				weight: 600;
			}
			&:hover {
				color: $main-color;
			}
		}
		.products-discount {
			position: absolute;
			top: -6.1px;
			right: 10px;
			display: inline-block;

			span {
				position: relative;
				display: block;
				text-align: center;
				line-height: 1;
				padding: 12px 8px 10px;
				border-top-right-radius: 8px;
				width: 90px;
				background-color: red;
				color: $white-color;
				text-transform: uppercase;
				font: {
					weight: 600;
					size: 14px;
				}
				&:before {
					height: 6px;
					width: 6px;
					left: -6px;
					position: absolute;
					content: "";
					top: 0;
					background: red;
				}
				&:after {
					position: absolute;
					content: "";
					height: 6px;
					width: 8px;
					left: -8px;
					top: 0;
					border-radius: 8px 8px 0 0;
					background: red;
				}
			}
		}
	}
	.products-discount {
		position: absolute;
		top: -6.1px;
		left: 10px;
		display: inline-block;

		span {
			position: relative;
			display: block;
			text-align: center;
			line-height: 1;
			padding: 12px 8px 10px;
			border-top-left-radius: 8px;
			width: 90px;
			background-color: red;
			color: $white-color;
			text-transform: uppercase;
			font: {
				weight: 600;
				size: 14px;
			}
			&:before {
				height: 6px;
				width: 6px;
				right: -6px;
				position: absolute;
				content: "";
				top: 0;
				background: red;
			}
			&:after {
				position: absolute;
				content: "";
				height: 6px;
				width: 8px;
				right: -8px;
				top: 0;
				border-radius: 8px 8px 0 0;
				background: red;
			}
		}
	}
	&:hover {
		.products-content {
			.price {
				opacity: 0;
				visibility: hidden;
			}
			.add-to-cart {
				transform: translateX(0);
				opacity: 1;
				visibility: visible;
			}
		}
		.products-image {
			a {
				.main-image {
					opacity: 0;
					visibility: hidden;
					transform: scale(1.07);
				}
				.hover-image {
					opacity: 1;
					visibility: visible;
					transform: scale(1);
				}
			}
			.products-button {
				ul {
					li {
						transform: translateX(0);
						opacity: 1;
						visibility: visible;
					}
				}
			}
		}
	}
}

.products-special-btn {
	height: 100px;
}

.products-box {
	margin-bottom: 30px;
	position: relative;

	.products-image {
		text-align: center;
		position: relative;
		overflow: hidden;

		a {
			position: relative;
			display: block;

			img {
				transition: 0.8s;
			}
			.hover-image {
				position: absolute;
				left: 0;
				right: 0;
				opacity: 0;
				visibility: hidden;
				transform: scale(1.07);
			}
		}
		.products-button {
			position: absolute;
			top: 50%;
			left: 50%;
			z-index: 1;
			transform: translateX(-50%) translateY(-50%);

			ul {
				padding-left: 0;
				margin-bottom: 0;
				list-style-type: none;

				li {
					opacity: 0;
					visibility: hidden;
					display: inline-block;
					transform: translateX(30px);
					transition: all 0.3s ease-in-out 0s;
					margin: {
						left: 3px;
						right: 3px;
					}
					a {
						display: inline-block;
						font-size: 22px;
						line-height: 1;
						color: $black-color;
						position: relative;
						width: 40px;
						border-radius: 50%;
						line-height: 43px;
						box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
						background-color: $white-color;
						height: 40px;

						&:hover {
							background-color: $main-color;
							color: $white-color;
						}
						.tooltip-label {
							right: 50%;
							top: -15px;
							transform: translateX(50%);
							position: absolute;
							padding: 0 10px;
							line-height: 28px;
							font-size: 12px;
							letter-spacing: 0.3px;
							visibility: hidden;
							white-space: nowrap;
							opacity: 0;
							color: transparent;
							background-color: transparent;
							transition: all 0.2s ease-in-out;
							background-color: $black-color;
							color: $white-color;

							&::before {
								content: "\e9ff";
								color: $black-color;
								left: 50%;
								position: absolute;
								transform: translateX(-50%) rotate(90deg);
								transition: all 0.2s ease-in-out;
								bottom: -16px;
								font: {
									family: "boxicons" !important;
									weight: normal;
									style: normal;
									size: 20px;
								}
							}
						}
						&:hover {
							.tooltip-label {
								visibility: visible;
								opacity: 1;
								top: -45px;
							}
						}
					}
					&:nth-child(1) {
						transition-delay: 0.1s;
					}
					&:nth-child(2) {
						transition-delay: 0.2s;
					}
					&:nth-child(3) {
						transition-delay: 0.3s;
					}
				}
			}
		}
		.sale-tag {
			position: absolute;
			left: 20px;
			bottom: 20px;
			background-color: $green-banner;
			color: $white-color;
			width: 55px;
			height: 55px;
			line-height: 55px;
			text-align: center;
			border-radius: 50%;
			z-index: 2;
			text-transform: uppercase;
			font: {
				weight: 700;
				size: 14px;
			}
		}
		.new-tag {
			position: absolute;
			left: 20px;
			top: 20px;
			background-color: $green-banner;
			color: $white-color;
			text-align: center;
			z-index: 2;
			padding: 1px 7px;
			text-transform: uppercase;
			font: {
				weight: 700;
				size: 14px;
			}
		}
	}
	.products-content {
		margin-top: 20px;
		position: relative;

		.category {
			display: block;
			margin-bottom: 7px;
			text-transform: uppercase;
			color: $main-color;
			font-weight: 600;
			font-size: 14px;
		}
		h3 {
			margin-bottom: 0;
			font: {
				size: 20px;
				weight: 600;
			}
			a {
				display: inline-block;
				color: $black-color;

				&:hover {
					color: $main-color;
				}
			}
		}
		.price {
			transition: $transition;
			margin-top: 8px;
			font: {
				size: $font-size;
				weight: 600;
			}
			.old-price {
				text-decoration: line-through;
				color: #999999;
				font-weight: 500;
				margin-right: 10px;
			}
		}
		.star-rating {
			color: #f49f0b;
			position: absolute;
			right: 0;
			bottom: 0;

			i {
				display: inline-block;
			}
		}
		.add-to-cart {
			position: absolute;
			left: 0;
			bottom: 0;
			text-transform: uppercase;
			transition: $transition;
			display: inline-block;
			color: $optional-color;
			opacity: 0;
			visibility: hidden;
			transform: translateX(20px);
			cursor: pointer;
			font: {
				size: 14px;
				weight: 600;
			}
			&:hover {
				color: $main-color;
			}
		}
	}
	.products-discount {
		position: absolute;
		top: -6.1px;
		right: 10px;
		display: inline-block;

		span {
			position: relative;
			display: block;
			text-align: center;
			line-height: 1;
			padding: 12px 8px 10px;
			border-top-right-radius: 8px;
			width: 90px;
			background-color: red;
			color: $white-color;
			text-transform: uppercase;
			font: {
				weight: 600;
				size: 14px;
			}
			&:before {
				height: 6px;
				width: 6px;
				left: -6px;
				position: absolute;
				content: "";
				top: 0;
				background: red;
			}
			&:after {
				position: absolute;
				content: "";
				height: 6px;
				width: 8px;
				left: -8px;
				top: 0;
				border-radius: 8px 8px 0 0;
				background: red;
			}
		}
	}
	&:hover {
		.products-content {
			.price {
				opacity: 0;
				visibility: hidden;
			}
			.add-to-cart {
				transform: translateX(0);
				opacity: 1;
				visibility: visible;
			}
		}
		.products-image {
			a {
				.main-image {
					opacity: 0;
					visibility: hidden;
					transform: scale(1.07);
				}
				.hover-image {
					opacity: 1;
					visibility: visible;
					transform: scale(1);
				}
			}
			.products-button {
				ul {
					li {
						transform: translateX(0);
						opacity: 1;
						visibility: visible;
					}
				}
			}
		}
	}
}
.single-productsBox {
	margin-bottom: 30px;
	position: relative;
	text-align: center;

	.products-image {
		position: relative;
		overflow: hidden;

		a {
			position: relative;
			display: block;

			img {
				transition: 0.8s;
			}
			.hover-image {
				position: absolute;
				left: 0;
				right: 0;
				opacity: 0;
				visibility: hidden;
				transform: scale(1.07);
			}
		}
		.products-button {
			position: absolute;
			left: 50%;
			z-index: 1;
			transform: translateX(-50%);
			bottom: 20px;

			ul {
				padding-left: 0;
				margin-bottom: 0;
				list-style-type: none;

				li {
					opacity: 0;
					visibility: hidden;
					display: inline-block;
					transform: translateX(30px);
					transition: all 0.3s ease-in-out 0s;
					margin: {
						left: 3px;
						right: 3px;
					}
					a {
						display: inline-block;
						font-size: 22px;
						line-height: 1;
						color: $black-color;
						position: relative;
						width: 40px;
						border-radius: 50%;
						line-height: 43px;
						box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
						background-color: $white-color;
						height: 40px;

						&:hover {
							background-color: $main-color;
							color: $white-color;
						}
						.tooltip-label {
							right: 50%;
							top: -15px;
							transform: translateX(50%);
							position: absolute;
							padding: 0 10px;
							line-height: 28px;
							font-size: 12px;
							letter-spacing: 0.3px;
							visibility: hidden;
							white-space: nowrap;
							opacity: 0;
							color: transparent;
							background-color: transparent;
							transition: all 0.2s ease-in-out;
							background-color: $black-color;
							color: $white-color;

							&::before {
								content: "\e9ff";
								color: $black-color;
								left: 50%;
								position: absolute;
								transform: translateX(-50%) rotate(90deg);
								transition: all 0.2s ease-in-out;
								bottom: -16px;
								font: {
									family: "boxicons" !important;
									weight: normal;
									style: normal;
									size: 20px;
								}
							}
						}
						&:hover {
							.tooltip-label {
								visibility: visible;
								opacity: 1;
								top: -45px;
							}
						}
					}
					&:nth-child(1) {
						transition-delay: 0.1s;
					}
					&:nth-child(2) {
						transition-delay: 0.2s;
					}
					&:nth-child(3) {
						transition-delay: 0.3s;
					}
				}
			}
		}
		.sale-tag {
			position: absolute;
			left: 20px;
			top: 20px;
			background-color: $green-banner;
			color: $white-color;
			width: 55px;
			height: 55px;
			line-height: 55px;
			text-align: center;
			border-radius: 50%;
			z-index: 2;
			text-transform: uppercase;
			font: {
				weight: 700;
				size: 14px;
			}
		}
		.new-tag {
			position: absolute;
			left: 20px;
			top: 20px;
			background-color: $green-banner;
			color: $white-color;
			text-align: center;
			z-index: 2;
			padding: 1px 7px;
			text-transform: uppercase;
			font: {
				weight: 700;
				size: 14px;
			}
		}
	}
	.products-content {
		margin-top: 20px;
		position: relative;

		.category {
			display: block;
			margin-bottom: 7px;
			text-transform: uppercase;
			color: $main-color;
			font-weight: 600;
			font-size: 14px;
		}
		h3 {
			margin-bottom: 0;
			font: {
				size: 20px;
				weight: 600;
			}
			a {
				display: inline-block;
				color: $black-color;

				&:hover {
					color: $main-color;
				}
			}
		}
		.price {
			transition: $transition;
			margin-top: 8px;
			font: {
				size: $font-size;
				weight: 600;
			}
			.old-price {
				text-decoration: line-through;
				color: #999999;
				font-weight: 500;
			}
		}
		.star-rating {
			color: #f49f0b;
			margin-top: 8px;

			i {
				display: inline-block;
				margin-left: -2px;
			}
		}
		.add-to-cart {
			position: absolute;
			left: 50%;
			bottom: 0;
			text-transform: uppercase;
			transition: $transition;
			display: inline-block;
			color: $optional-color;
			opacity: 0;
			visibility: hidden;
			transform: translateX(-50%);
			margin-left: 15px;
			font: {
				size: 14px;
				weight: 600;
			}
			&:hover {
				color: $main-color;
			}
		}
	}
	.products-discount {
		position: absolute;
		top: -6.1px;
		right: 10px;
		display: inline-block;

		span {
			position: relative;
			display: block;
			text-align: center;
			line-height: 1;
			padding: 12px 8px 10px;
			border-top-right-radius: 8px;
			width: 90px;
			background-color: red;
			color: $white-color;
			text-transform: uppercase;
			font: {
				weight: 600;
				size: 14px;
			}
			&:before {
				height: 6px;
				width: 6px;
				left: -6px;
				position: absolute;
				content: "";
				top: 0;
				background: red;
			}
			&:after {
				position: absolute;
				content: "";
				height: 6px;
				width: 8px;
				left: -8px;
				top: 0;
				border-radius: 8px 8px 0 0;
				background: red;
			}
		}
	}
	&:hover {
		.products-content {
			.price {
				opacity: 0;
				visibility: hidden;
			}
			.add-to-cart {
				opacity: 1;
				margin-left: 0;
				visibility: visible;
			}
		}
		.products-image {
			a {
				.main-image {
					opacity: 0;
					visibility: hidden;
					transform: scale(1.07);
				}
				.hover-image {
					opacity: 1;
					visibility: visible;
					transform: scale(1);
				}
			}
			.products-button {
				ul {
					li {
						transform: translateX(0);
						opacity: 1;
						visibility: visible;
					}
				}
			}
		}
	}
}
.products-slides {
	.single-products-box {
		.products-image {
			a {
				img {
					width: auto !important;
					display: inline-block !important;
				}
			}
			.new-tag {
				top: 45px;
			}
		}
		.products-discount {
			top: 0;
		}
	}
}
.products-slides {
	&.owl-theme {
		.owl-nav {
			margin-top: 0;

			[class*="owl-"] {
				color: #87a1c1;
				font-size: 20px;
				background-color: $white-color;
				margin: 0;
				padding: 0;
				width: 50px;
				height: 50px;
				line-height: 48px;
				position: absolute;
				left: 0;
				transition: $transition;
				top: 50%;
				transform: translateY(-50%);
				opacity: 0;
				visibility: hidden;
				border: {
					radius: 50%;
					style: solid;
					color: $white-color;
					width: 1.5px;
				}
				&.owl-next {
					left: auto;
					right: 0;
				}
				&:hover {
					background-color: $main-color;
					color: $white-color;
					border-color: $main-color;
				}
			}
		}
		&:hover {
			.owl-nav {
				[class*="owl-"] {
					left: -30px;
					opacity: 1;
					visibility: visible;

					&.owl-next {
						left: auto;
						right: -30px;
					}
				}
			}
		}
	}
}
.products-collections-area {
	.container-fluid {
		width: 90%;
	}
}
.products-filter-options {
	margin-bottom: 45px;

	.products-ordering-list {
		.nice-select {
			background-color: transparent;
			border: none;
			height: 40px;
			line-height: 40px;
			color: $optional-color;
			font-size: $font-size;
			padding: {
				left: 0;
				right: 0;
			}
			&::after {
				right: 2px;
				width: 7px;
				height: 7px;
				border-color: #727695;
				border-width: 1px;
			}
			.current {
				color: $optional-color;
				font-family: $font-family2;
			}
			.list {
				background-color: $white-color;
				border-radius: 0;
				box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);
				width: 100%;
				margin: {
					top: 0;
					bottom: 0;
				}
				padding: {
					top: 5px;
					bottom: 5px;
				}
				.option {
					transition: $transition;
					line-height: 35px;
					min-height: 35px;
					padding: {
						left: 15px;
						right: 15px;
					}
					font: {
						size: $font-size;
					}
					&:hover,
					&.focus,
					&.selected.focus {
						background-color: transparent;
						color: $main-color;
					}
					&.selected {
						font-weight: 700;
					}
				}
			}
		}
	}
	p {
		line-height: initial;
		margin-bottom: 0;
		font-family: $font-family2;
	}
	.sub-title {
		color: $black-color;
		display: inline-block;
		padding-right: 5px;
		font: {
			size: $font-size;
			weight: 600;
		}
		a {
			text-transform: uppercase;
			color: $black-color;
			font-weight: 700;
			margin-right: 15px;
			padding-left: 22px;
			display: block;
			position: relative;

			i {
				font-size: 18px;
				position: absolute;
				left: 0;
				top: 1px;
			}
			&:hover {
				color: $main-color;
			}
		}
	}
	.view-list-row {
		position: relative;
		top: 2px;

		.view-column {
			a {
				display: inline-block;
			}
			.icon-view-one {
				span {
					cursor: pointer;
					width: 7px;
					height: 14px;
					display: block;
					float: left;
					border: 1px solid #d0d0d0;
					transition: $transition;
				}
				&.active,
				&:hover {
					span {
						border-color: $main-color;
					}
				}
			}
			.icon-view-two {
				margin-left: 2px;

				span {
					cursor: pointer;
					width: 7px;
					height: 14px;
					display: block;
					float: left;
					border: 1px solid #d0d0d0;
					transition: $transition;

					&:not(:first-child) {
						margin-left: 1px;
					}
				}
				&.active,
				&:hover {
					span {
						border-color: $main-color;
					}
				}
			}
			.icon-view-three {
				margin-left: 2px;

				span {
					width: 7px;
					height: 14px;
					display: block;
					float: left;
					border: 1px solid #d0d0d0;
					transition: $transition;

					&:not(:first-child) {
						margin-left: 1px;
					}
				}
				&.active,
				&:hover {
					span {
						border-color: $main-color;
					}
				}
			}
			.icon-view-four {
				margin-left: 2px;

				span {
					width: 7px;
					height: 14px;
					display: block;
					float: left;
					border: 1px solid #d0d0d0;
					transition: $transition;

					&:not(:first-child) {
						margin-left: 1px;
					}
				}
				&.active,
				&:hover {
					span {
						border-color: $main-color;
					}
				}
			}
			.icon-view-five {
				margin-left: 2px;

				span {
					width: 5px;
					height: 14px;
					display: block;
					float: left;
					border: 1px solid #d0d0d0;
					transition: $transition;

					&:not(:first-child) {
						margin-left: 1px;
					}
				}
				&.active,
				&:hover {
					span {
						border-color: $main-color;
					}
				}
			}
			.icon-view-six {
				margin-left: 2px;

				span {
					width: 3px;
					height: 14px;
					display: block;
					float: left;
					border: 1px solid #d0d0d0;
					transition: $transition;

					&:not(:first-child) {
						margin-left: 1px;
					}
				}
				&.active,
				&:hover {
					span {
						border-color: $main-color;
					}
				}
			}
			.view-grid-switch {
				margin-left: 2px;

				span {
					width: 24px;
					height: 6px;
					display: block;
					position: relative;
					background-color: #d0d0d0;
					transition: $transition;

					&::before {
						content: "";
						position: absolute;
						top: 0;
						left: 6px;
						background-color: $white-color;
						display: block;
						width: 2px;
						height: 6px;
					}
					&:not(:first-child) {
						margin-top: 2px;
					}
				}
				&.active,
				&:hover {
					span {
						background-color: $main-color;
					}
				}
			}
		}
	}
}
#products-collections-filter {
	&.products-col-one {
		.products-col-item {
			flex: 0 0 100%;
			max-width: 100%;

			.single-products-box {
				.products-image {
					a {
						img {
							width: 100%;
						}
					}
				}
			}
			.products-box {
				.products-image {
					a {
						img {
							width: 100%;
						}
					}
				}
			}
			.single-productsBox {
				.products-image {
					a {
						img {
							width: 100%;
						}
					}
				}
			}
		}
	}
	&.products-col-three {
		.products-col-item {
			flex: 0 0 33.333333%;
			max-width: 33.333333%;
		}
	}
	&.products-row-view {
		.products-col-item {
			flex: 0 0 100%;
			max-width: 100%;

			.single-products-box {
				display: flex;
				flex-wrap: wrap;
				align-items: center !important;
				background-color: #fdfdfd;

				.products-image {
					position: relative;
					flex: 0 0 41.666667%;
					max-width: 41.666667%;
				}
				.products-content {
					flex: 0 0 58.333333%;
					max-width: 58.333333%;
					text-align: left;
					padding: {
						left: 30px;
						right: 30px;
					}
					.add-to-cart {
						left: 30px;
					}
					.star-rating {
						right: 30px;
					}
				}
			}
			.products-box {
				display: flex;
				flex-wrap: wrap;
				align-items: center !important;
				background-color: #fdfdfd;

				.products-image {
					position: relative;
					flex: 0 0 41.666667%;
					max-width: 41.666667%;
				}
				.products-content {
					flex: 0 0 58.333333%;
					max-width: 58.333333%;
					text-align: left;
					padding: {
						left: 30px;
						right: 30px;
					}
					.add-to-cart {
						left: 30px;
					}
				}
			}
			.single-productsBox {
				display: flex;
				flex-wrap: wrap;
				align-items: center !important;
				background-color: #fdfdfd;

				.products-image {
					position: relative;
					flex: 0 0 41.666667%;
					max-width: 41.666667%;
				}
				.products-content {
					flex: 0 0 58.333333%;
					max-width: 58.333333%;
					text-align: left;
					padding: {
						left: 30px;
						right: 30px;
					}
					.add-to-cart {
						left: 30px;
						transform: translateX(0);
					}
				}
			}
			&.col-lg-4 {
				flex: 0 0 50%;
				max-width: 50%;
			}
		}
	}
	&.products-col-two {
		.col-lg-4 {
			flex: 0 0 50%;
			max-width: 50%;
		}
	}
	&.products-col-four {
		.col-lg-4 {
			flex: 0 0 25%;
			max-width: 25%;
		}
	}
}

.full-width-area {
	.container-fluid {
		max-width: 2500px;
	}
}

/*================================================
Products Details CSS
=================================================*/
.products-details-image {
	img {
		width: 100%;
	}
	.slick-slider {
		position: relative;
		padding: 0;
		margin: 0;
		list-style-type: none;
	}
	.slick-dots {
		padding: 0;
		text-align: center;
		list-style-type: none;
		display: flex;
		flex-wrap: wrap;
		margin: {
			left: -5px;
			right: -5px;
			top: 0;
			bottom: 0;
		}
		li {
			display: block;
			flex: 0 0 20%;
			max-width: 20%;
			position: relative;
			padding: {
				left: 5px;
				right: 5px;
				top: 10px;
			}
			button {
				padding: 0;
				outline: 0;
				display: block;
				border: none;
				margin: 0;
				cursor: pointer;
			}
			img {
				transition: $transition;
			}
			&:hover,
			&.slick-active {
				img {
					opacity: 0.4;
				}
			}
		}
	}
	.slick-thumbs {
		display: none;
	}
	.row {
		margin: {
			left: -8px;
			right: -8px;
			top: -15px;
		}
		.col-lg-6 {
			padding: {
				left: 8px;
				right: 8px;
			}
			.single-products-details-image {
				margin-top: 15px;
			}
		}
	}
	.single-products-details-image {
		margin-top: 30px;

		&:first-child {
			margin-top: 0;
		}
	}
}
.products-details-image-slider {
	&.owl-theme {
		margin-bottom: 30px;

		.owl-nav {
			margin-top: 0;

			[class*="owl-"] {
				color: #87a1c1;
				font-size: 15px;
				margin: 0;
				padding: 0;
				background: $white-color;
				width: 40px;
				height: 40px;
				line-height: 35px;
				position: absolute;
				left: 15px;
				transition: $transition;
				top: 50%;
				padding-right: 2px !important;
				transform: translateY(-50%);
				border: {
					radius: 50%;
					style: solid;
					color: $white-color;
					width: 1.5px;
				}
				&.owl-next {
					left: auto;
					right: 15px;
					padding: {
						right: 0 !important;
						left: 2px !important;
					}
				}
				&:hover {
					background-color: $main-color;
					color: $white-color;
					border-color: $main-color;
				}
			}
		}
	}
}

.special-uploaded-img {
	width: 100px;
	margin-top: 15px;
	display: flex;
}

.special-msg {
	padding: 20px 0px 0px 0px;
}
.products-details-desc {
	padding-bottom: 20px;
	h3 {
		margin-bottom: 18px;
		font: {
			size: 22px;
			weight: 700;
		}
	}
	.price {
		margin-bottom: 10px;
		color: $main-color;
		font: {
			size: $font-size;
			weight: 600;
		}
		.old-price {
			text-decoration: line-through;
			color: #828893;
			font-weight: normal;
			margin-right: 10px;
		}
	}
	.products-review {
		margin-bottom: 5px;

		.rating {
			display: inline-block;
			padding-right: 10px;
			font-size: 18px;

			i {
				color: #ffba0a;
				display: inline-block;
			}
		}
		.rating-count {
			display: inline-block;
			color: $black-color;
			border-bottom: 1px solid $black-color;
			line-height: initial;
			position: relative;
			top: -2px;
			font-weight: 600;

			&:hover {
				color: $main-color;
				border-color: $main-color;
			}
		}
	}
	.products-info {
		list-style-type: none;
		padding-left: 0;
		margin: {
			top: 5px;
			bottom: 0;
		}
		li {
			color: $black-color;
			margin-bottom: 8px;
			font: {
				size: 16px;
			}
			&:last-child {
				margin-bottom: 0;
			}
			span {
				color: $optional-color;
				margin-right: 5px;
			}
			a {
				display: inline-block;
				font-weight: 600;
				color: $black-color;

				&:hover {
					color: $main-color;
				}
			}
		}
	}
	.products-color-switch {
		margin-top: 10px;

		span {
			margin-bottom: 8px;
			display: block;
			font: {
				size: 17px;
				weight: 600;
			}
		}
		ul {
			padding-left: 0;
			list-style-type: none;
			margin: {
				bottom: 0;
				top: -3px;
				left: -3px;
			}
			li {
				display: inline-block;
				margin: {
					left: 3px;
					top: 3px;
				}
				a {
					display: block;
					position: relative;
					border: 1px solid transparent;
					width: 22px;
					height: 22px;
					border-radius: 50%;

					&::before {
						position: absolute;
						content: "";
						left: 0;
						right: 0;
						top: 0;
						bottom: 0;
						background: #eeeeee;
						border-radius: 50%;
						margin: 2px;
						transition: $transition;
					}
					&:hover,
					&:focus {
						border-color: $black-color;
					}
					&.color-white {
						&::before {
							background: $white-color;
							border: 1px solid #eeeeee;
						}
					}
					&.color-black {
						&::before {
							background: $black-color;
						}
					}
					&.color-red {
						&::before {
							background: red;
						}
					}
					&.color-blue {
						&::before {
							background: blue;
						}
					}
					&.color-green {
						&::before {
							background: green;
						}
					}
					&.color-yellow {
						&::before {
							background: yellow;
						}
					}
					&.color-yellowgreen {
						&::before {
							background: yellowgreen;
						}
					}
					&.color-pink {
						&::before {
							background: pink;
						}
					}
					&.color-violet {
						&::before {
							background: violet;
						}
					}
					&.color-teal {
						&::before {
							background: teal;
						}
					}
					&.color-plum {
						&::before {
							background: plum;
						}
					}
					&.color-lime {
						&::before {
							background: lime;
						}
					}
					&.color-blueviolet {
						&::before {
							background: blueviolet;
						}
					}
				}
				&.active {
					a {
						border-color: $black-color;
					}
				}
			}
		}
	}
	.products-size-wrapper {
		margin: {
			top: 8px;
			bottom: 25px;
		}
		span {
			margin-bottom: 8px;
			display: block;
			font: {
				size: 17px;
				weight: 600;
			}
		}
		ul {
			list-style-type: none;
			padding-left: 0;
			margin: {
				bottom: 0;
				top: -8px;
				left: -8px;
			}
			li {
				display: inline-block;
				margin: {
					left: 8px;
					top: 8px;
				}
				a {
					width: 38px;
					height: 35px;
					display: block;
					line-height: 32px;
					border: 1px solid #eeeeee;
					color: #858585;
					text-align: center;
					font: {
						size: $font-size;
						weight: 600;
					}
					&:hover {
						border-color: $main-color;
						color: $main-color;
					}
				}
				&.active {
					a {
						border-color: $main-color;
						color: $main-color;
					}
				}
			}
		}
	}
	.products-info-btn {
		margin-bottom: 25px;

		a {
			text-transform: uppercase;
			display: inline-block;
			position: relative;
			padding-left: 22px;
			line-height: initial;
			font: {
				size: 14px;
				weight: 600;
			}
			&::before {
				content: "";
				position: absolute;
				left: 0;
				bottom: -2px;
				width: 0;
				height: 1.5px;
				background: $main-color;
				transition: $transition;
			}
			i {
				font-size: 18px;
				position: absolute;
				left: 0;
				top: 0;
			}
			&:hover {
				color: $main-color;

				&::before {
					width: 100%;
				}
			}
			&:not(:first-child) {
				margin-left: 15px;
			}
		}
	}
	.products-add-to-cart {
		.input-counter {
			max-width: 120px;
			min-width: 120px;
			margin-right: 10px;
			text-align: center;
			display: inline-block;
			position: relative;

			span {
				position: absolute;
				top: 0;
				background-color: transparent;
				cursor: pointer;
				color: #d0d0d0;
				width: 40px;
				height: 100%;
				line-height: 50px;
				transition: $transition;
				font-size: 22px;

				&.minus-btn {
					left: 0;
				}
				&.plus-btn {
					right: 0;
				}
				&:hover {
					color: $main-color;
				}
			}
			input {
				height: 45px;
				color: $black-color;
				outline: 0;
				display: block;
				border: none;
				background-color: #f8f8f8;
				text-align: center;
				width: 100%;
				font: {
					size: 17px;
					weight: 600;
				}
				&::placeholder {
					color: $black-color;
				}
			}
		}
	}
	.wishlist-compare-btn {
		margin-top: 20px;

		.optional-btn {
			padding: {
				top: 8px;
				bottom: 10px;
			}
			i {
				font-size: 20px;
				position: relative;
				top: 3px;
				margin-right: 5px;
			}
			&:not(:first-child) {
				margin-left: 10px;
			}
		}
	}
	.buy-checkbox-btn {
		margin-top: 20px;

		input {
			display: none;
		}
		.cbx {
			margin: auto;
			-webkit-user-select: none;
			user-select: none;
			cursor: pointer;

			span {
				display: inline-block;
				vertical-align: middle;
				transform: translate3d(0, 0, 0);

				&:first-child {
					position: relative;
					width: 18px;
					height: 18px;
					border-radius: 3px;
					transform: scale(1);
					vertical-align: middle;
					border: 1px solid #ebebeb;
					transition: all 0.2s ease;
					transition: $transition;

					svg {
						position: absolute;
						top: 3px;
						left: 2px;
						fill: none;
						stroke: $white-color;
						stroke-width: 2;
						stroke-linecap: round;
						stroke-linejoin: round;
						stroke-dasharray: 16px;
						stroke-dashoffset: 16px;
						transition: all 0.3s ease;
						transition-delay: 0.1s;
						transform: translate3d(0, 0, 0);
						transition: $transition;
					}
					&:before {
						content: "";
						width: 100%;
						height: 100%;
						background: $main-color;
						display: block;
						transform: scale(0);
						opacity: 1;
						border-radius: 50%;
						transition: $transition;
					}
				}
				&:last-child {
					padding-left: 10px;
					color: $black-color;
					position: relative;
					top: -1px;
				}
			}
			&:hover {
				span {
					&:first-child {
						border-color: $main-color;
					}
				}
			}
		}
		.inp-cbx:checked + .cbx span:first-child {
			background: $main-color;
			border-color: $main-color;
			animation: wave 0.4s ease;
		}
		.inp-cbx:checked + .cbx span:first-child svg {
			stroke-dashoffset: 0;
		}
		.inp-cbx:checked + .cbx span:first-child:before {
			transform: scale(3.5);
			opacity: 0;
			transition: all 0.6s ease;
		}
		.item {
			&:not(:first-child) {
				margin-top: 20px;
			}
		}
		.default-btn {
			display: block;
			text-align: center;
		}
	}
	.products-details-accordion {
		margin-top: 25px;

		.accordion {
			list-style-type: none;
			padding-left: 0;
			margin-bottom: 0;
			border: none;

			.accordion__item {
				border-radius: 5px;
				display: block;
				background: $white-color;
				margin-bottom: 15px;
				border: none;

				&:last-child {
					margin-bottom: 0;
				}
			}
			.accordion__button {
				background-color: transparent;
				padding-left: 28px;
				color: $black-color;
				text-decoration: none;
				position: relative;
				display: block;
				padding: 0;

				font: {
					size: 16px;
					weight: 700;
				}
				i {
					position: absolute;
					left: 0;
					top: 1px;
					font-size: 25px;
					transition: $transition;
				}
				&.active {
					i {
						transform: rotate(180deg);
					}
				}
			}
			.accordion__panel {
				padding: 0;
			}
			.accordion-content {
				position: relative;
				margin-top: -5px;
				padding: {
					top: 15px;
				}
				ul,
				ol {
					padding-left: 0;
					list-style-type: none;
					margin-bottom: 0;

					li {
						margin-bottom: 10px;
						position: relative;
						padding-left: 13px;
						color: $optional-color;

						&::before {
							content: "";
							position: absolute;
							top: 8px;
							left: 0;
							width: 6px;
							height: 6px;
							background: $main-color;
							border-radius: 50%;
						}
						&:last-child {
							margin-bottom: 0;
						}
					}
				}
				.table {
					margin-bottom: 0;

					&.table-striped {
						tbody {
							tr {
								td {
									border-top: none;
									vertical-align: middle;
									white-space: nowrap;
									padding: 10px 15px;
									color: $black-color;

									&:first-child {
										font: {
											weight: 500;
										}
									}
								}
							}
						}
					}
					&.table-bordered {
						tbody {
							tr {
								td {
									vertical-align: middle;
									white-space: nowrap;
									padding: 10px 15px;
									color: $optional-color;
									line-height: 1.7;
									border-color: #eeeeee;

									&:first-child {
										color: $black-color;
										font: {
											weight: 500;
										}
									}
								}
							}
						}
					}
				}
				h3 {
					margin-bottom: 12px;
					font: {
						size: 18px;
						weight: 700;
					}
				}
				.products-review-form {
					margin-top: 10px;

					.review-title {
						position: relative;

						.rating {
							display: inline-block;

							.bx.bxs-star {
								color: #ffba0a;
							}
							i {
								color: #ebebeb;
								font-size: 16px;
								margin-right: -1px;
							}
						}
						p {
							margin-bottom: 0;
							display: inline-block;
							padding-left: 5px;
							line-height: initial;
							position: relative;
							top: -2px;
						}
						.default-btn {
							position: absolute;
							right: 0;
							bottom: 0;
						}
					}
					.review-comments {
						margin-top: 35px;

						.review-item {
							margin-top: 20px;
							position: relative;
							border-top: 1px dashed #eeeeee;
							padding-top: 20px;

							.rating {
								.bx.bxs-star {
									color: #ffba0a;
								}
								i {
									font-size: 17px;
									color: #ebebeb;
									margin-right: -1px;
								}
							}
							h3 {
								font-size: 18px;
								margin: {
									top: 8px;
									bottom: 8px;
								}
							}
							span {
								margin-bottom: 10px;
								font-size: $font-size;
								display: block;

								strong {
									font-weight: 500;
								}
							}
							p {
								margin-bottom: 0;
							}
						}
					}
					.review-form {
						margin-top: 30px;

						h3 {
							margin-bottom: 20px;
						}
						form {
							.form-group {
								margin-bottom: 15px;
							}
							.default-btn {
								margin-top: 10px;
							}
						}
					}
				}
			}
		}
	}
}
.products-details-tab {
	margin-top: 50px;

	.tabs {
		list-style-type: none;
		margin-bottom: -1px;
		padding-left: 0;

		li {
			display: inline-block;
			line-height: initial;
			// margin-right: 5px;
			width: 20%;

			a {
				display: block;
				position: relative;
				color: $black-color;
				border: 1px dashed #eeeeee;
				padding: 13px 12px 13px 32px;
				font: {
					weight: 600;
					size: 16px;
				}
				.dot {
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					left: 15px;
					width: 12px;
					height: 12px;
					border: 1px solid $black-color;
					transition: $transition;
					border-radius: 50%;

					&::before {
						position: absolute;
						top: 0;
						content: "";
						left: 0;
						right: 0;
						bottom: 0;
						background: $black-color;
						margin: 2px;
						border-radius: 50%;
						transition: $transition;
					}
				}
				&:hover {
					color: $white-color;
					background-color: $main-color;
					border-color: $main-color;

					.dot {
						border-color: $white-color;

						&::before {
							background: $white-color;
						}
					}
				}
			}
			&.current {
				a {
					color: $white-color;
					background-color: $main-color;
					border-color: $main-color;

					.dot {
						border-color: $white-color;

						&::before {
							background: $white-color;
						}
					}
				}
			}
			&:last-child {
				margin-right: 0;
			}
		}
	}
	.tab-content {
		border: 1px dashed #eeeeee;
		padding: 30px;

		.tabs-item {
			.products-details-tab-content {
				ul,
				ol {
					padding-left: 0;
					list-style-type: none;
					margin-bottom: 0;

					li {
						margin-bottom: 10px;
						position: relative;
						padding-left: 13px;
						color: $optional-color;

						&::before {
							content: "";
							position: absolute;
							top: 8px;
							left: 0;
							width: 6px;
							height: 6px;
							background: $main-color;
							border-radius: 50%;
						}
						&:last-child {
							margin-bottom: 0;
						}
					}
				}
				.table {
					margin-bottom: 0;

					&.table-striped {
						tbody {
							tr {
								td {
									border-top: none;
									vertical-align: middle;
									white-space: nowrap;
									padding: 10px 15px;
									color: $black-color;

									&:first-child {
										font: {
											weight: 600;
										}
									}
								}
							}
						}
					}
					&.table-bordered {
						tbody {
							tr {
								td {
									vertical-align: middle;
									white-space: nowrap;
									padding: 10px 15px;
									color: $optional-color;
									line-height: 1.7;
									border-color: #eeeeee;

									&:first-child {
										color: $black-color;
										font: {
											weight: 600;
										}
									}
								}
							}
						}
					}
				}
				h3 {
					margin-bottom: 15px;
					font: {
						size: 22px;
						weight: 700;
					}
				}
				.products-review-form {
					.review-title {
						position: relative;

						.rating {
							display: inline-block;

							.bx.bxs-star {
								color: #ffba0a;
							}
							i {
								color: #ebebeb;
								font-size: 18px;
								margin-right: -1px;
							}
						}
						p {
							margin-bottom: 0;
							display: inline-block;
							padding-left: 5px;
							line-height: initial;
							position: relative;
							top: -2px;
						}
						.default-btn {
							position: absolute;
							right: 0;
							bottom: 0;
						}
					}
					.review-comments {
						margin-top: 35px;

						.review-item {
							margin-top: 30px;
							position: relative;
							padding-right: 200px;
							border-top: 1px dashed #eeeeee;
							padding-top: 30px;

							.rating {
								.bx.bxs-star {
									color: #ffba0a;
								}
								i {
									font-size: 17px;
									color: #ebebeb;
									margin-right: -1px;
								}
							}
							h3 {
								font-size: 20px;
								margin: {
									top: 8px;
									bottom: 8px;
								}
							}
							span {
								margin-bottom: 10px;
								font-size: $font-size;
								display: block;

								strong {
									font-weight: 500;
								}
							}
							p {
								margin-bottom: 0;
							}
						}
					}
					.review-form {
						margin-top: 30px;

						h3 {
							margin-bottom: 20px;
						}
						form {
							.form-group {
								margin-bottom: 15px;
							}
							.default-btn {
								margin-top: 10px;
							}
						}
					}
				}
			}
		}
	}
}
.related-products {
	margin-top: 70px;
}

/*================================================
Offer CSS
=================================================*/
.offer-area {
	margin-top: 20vh;
	position: relative;
	z-index: 1;
	background: {
		position: center center;
		size: cover;
		repeat: no-repeat;
	}
	&.bg-image1 {
		background: {
			image: url(../../../images/banners/team.jpg);
		}
	}
	&.bg-image2 {
		background: {
			image: url(../../../images/banners/puzzle.jpg);
		}
	}
	&.bg-image3 {
		background: {
			image: url(../../../images/banners/writing.jpg);
		}
	}
	&.bg-image4 {
		background: {
			image: url(../../../images/banners/teal_alt.jpg);
		}
	}
	&.bg-image5 {
		background: {
			image: url(../../../images/banners/videoBanner.jpg);
		}
	}
}
.offer-content {
	// max-width: 550px;
	border: 1px solid $white-color;
	margin: 0 10%;
	text-align: center;
	border-radius: 5px;
	padding: 70px 40px;
	position: relative;
	z-index: 1;

	span {
		display: block;
		margin-bottom: 10px;
		color: $main-color;
		font-weight: 600;
	}
	h2 {
		margin-bottom: 0;
		font: {
			size: 45px;
			weight: 700;
		}
	}
	p {
		line-height: initial;
		margin: {
			top: 10px;
		}
	}
	.default-btn {
		margin-top: 12px;
	}
	&::before {
		content: "";
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		background: $white-transparent;
		z-index: -1;
		border-radius: 5px;
		margin: 10px;
	}
}
.offer-content-box {
	max-width: 720px;
	margin-right: auto;
	text-align: center;
	position: relative;
	z-index: 1;

	span {
		display: block;
		color: $white-color;
		font: {
			weight: 300;
			size: 30px;
		}
	}
	h2 {
		margin: 10px 0 10px;
		color: $white-color;
		font: {
			size: 65px;
			weight: 700;
		}
	}
	h3 {
		margin: 10px 0 10px;
		padding: 0px 10px;
		color: $white-color;
		font: {
			size: 40px !important;
			weight: 700;
		}
	}
	p {
		color: $white-color;
		font: {
			size: 20px;
			weight: 300;
		}
	}
	.default-btn {
		margin-top: 10px;
	}
}
.single-offer-products {
	position: relative;
	border-radius: 5px;
	padding-right: 100px;

	img {
		display: inline-block !important;
		width: auto !important;
	}
	.image {
		border-radius: 5px;

		a {
			display: block;
			border-radius: 5px;

			img {
				border-radius: 5px;
			}
		}
	}
	.content {
		position: absolute;
		right: 15px;
		box-shadow: 0px 8px 16px 0px rgba(146, 184, 255, 0.2);
		background-color: $white-color;
		border-radius: 5px;
		padding: 40px;
		max-width: 415px;
		top: 50%;
		transform: translateY(-50%);
		z-index: 5;

		h3 {
			margin: {
				bottom: 0;
			}
			font: {
				size: 30px;
				weight: 700;
			}
		}
		p {
			margin-top: 10px;
		}
	}
}
.offer-products-slides {
	&.owl-theme {
		.owl-dots {
			margin-top: 0 !important;
			position: absolute;
			left: 20px;
			bottom: 20px;

			.owl-dot {
				span {
					width: 15px;
					height: 15px;
					margin: 0 4px;
					background: transparent;
					position: relative;
					transition: $transition;
					border-radius: 50%;
					border: 1px solid transparent;

					&::before {
						content: "";
						position: absolute;
						left: 0;
						top: 0;
						right: 0;
						bottom: 0;
						border-radius: 50%;
						background-color: $white-color;
						margin: 2px;
					}
				}
				&:hover,
				&.active {
					span {
						border-color: $white-color;
					}
				}
			}
		}
	}
}

/*================================================
Facility CSS
=================================================*/
.facility-area {
	position: relative;
	z-index: 1;
}
.single-facility-box {
	margin-bottom: 30px;
	text-align: center;
	cursor: pointer;

	.icon {
		background-color: #f9f9f9;
		width: 150px;
		height: 150px;
		position: relative;
		border-radius: 50%;
		transition: $transition;
		font-size: 65px;
		margin: {
			left: auto;
			right: auto;
		}
		i {
			position: absolute;
			left: 0;
			right: 0;
			top: 40%;
			transform: translateY(-40%);
		}
	}
	h3 {
		display: block;
		background-color: $white-color;
		position: relative;
		padding-top: 18px;
		z-index: 1;
		margin: {
			top: -25px;
			bottom: 0;
		}
		font: {
			size: 20px;
			weight: 700;
		}
	}
	&:hover {
		.icon {
			background-color: $main-color;
			color: $white-color;
		}
	}
}
.facility-slides {
	&.owl-theme {
		.owl-nav {
			margin-top: 0;

			[class*="owl-"] {
				color: #87a1c1;
				font-size: 20px;
				margin: 0;
				padding: 0;
				background: transparent;
				width: 50px;
				height: 50px;
				line-height: 48px;
				position: absolute;
				left: 0;
				transition: $transition;
				top: 50%;
				transform: translateY(-50%);
				opacity: 0;
				visibility: hidden;
				border: {
					radius: 50%;
					style: solid;
					color: #e3e4ef;
					width: 1.5px;
				}
				&.owl-next {
					left: auto;
					right: 0;
				}
				&:hover {
					background-color: $main-color;
					color: $white-color;
					border-color: $main-color;
				}
			}
		}
		&:hover {
			.owl-nav {
				[class*="owl-"] {
					left: -30px;
					opacity: 1;
					visibility: visible;

					&.owl-next {
						left: auto;
						right: -30px;
					}
				}
			}
		}
	}
}

/*================================================
Partner CSS
=================================================*/
.partner-area {
	background-color: #f4f4f4;
}
.partner-item {
	text-align: center;

	a {
		display: block;

		img {
			width: auto !important;
			display: inline-block !important;
		}
	}
}

/*================================================
Testimonials CSS
=================================================*/
.testimonials-area {
	position: relative;
	z-index: 1;
	overflow: hidden;
}
.single-testimonials-item {
	background-color: $white-color;
	padding: 35px 30px 95px;
	box-shadow: 6px 5px 25px rgba(0, 0, 0, 0.06);
	text-align: center;
	transition: $transition;
	text-transform: italic;
	position: relative;
	margin-bottom: 100px;

	p {
		margin-bottom: 0;
		transition: $transition;
		color: $black-color;
		font: {
			style: italic;
			size: 17px;
			weight: 600;
		}
	}
	.info {
		position: absolute;
		left: 0;
		bottom: -100px;
		right: 0;

		img {
			width: 100px !important;
			height: 100px;
			display: inline-block !important;
			border: 3px solid $white-color;
		}
		h3 {
			margin: {
				bottom: 6px;
				top: 20px;
			}
			font: {
				size: 22px;
				weight: 700;
			}
		}
		span {
			color: $main-color;
			display: block;
			font-weight: 600;
			text-transform: uppercase;
		}
	}
}
.owl-item {
	.single-testimonials-item {
		box-shadow: unset;
		margin-top: 30px;
		transition: $transition;
		opacity: 0.5;
	}
	&.active {
		&.center {
			.single-testimonials-item {
				box-shadow: 6px 5px 25px rgba(0, 0, 0, 0.06);
				margin-top: 0;
				background-color: $main-color;
				transition: $transition;
				opacity: 1;

				p {
					color: $white-color;
				}
			}
		}
	}
}
.testimonials-slides {
	&.owl-theme {
		.owl-dots {
			margin-top: 15px;

			.owl-dot {
				span {
					width: 13px;
					height: 13px;
					margin: 0 5px;
					background: #dddddd;
					transition: $transition;
					border-radius: 50%;
					transform: scale(0.9);
				}
				&:hover,
				&.active {
					span {
						background-color: $main-color;
					}
				}
				&.active {
					span {
						transform: scale(1.2);
					}
				}
			}
		}
	}
}

/*================================================
Brand CSS
=================================================*/
.brand-area {
	background-color: #f4f4f4;
}
.brand-item {
	text-align: center;

	a {
		display: block;
		background-color: $white-color;
		border-right: 1px solid #eeeeee;
		padding: 20px;

		img {
			width: auto !important;
			display: inline-block !important;
			opacity: 0.5;
			transition: $transition;
		}
		&:hover {
			img {
				opacity: 1;
			}
		}
	}
}

/*================================================
Blog CSS
=================================================*/
.blog-area {
	.container-fluid {
		padding-left: 30px;
		padding-right: 30px;
	}
}
.single-blog-post {
	position: relative;
	margin-bottom: 30px;

	.post-image {
		overflow: hidden;
		position: relative;
		border-radius: 5px 5px 0 0;

		a {
			display: block;
			border-radius: 5px 5px 0 0;

			img {
				border-radius: 5px 5px 0 0;
				transition: $transition;
				width: 100%;
				height: 30vh;
			}
		}
		.date {
			position: absolute;
			right: -100%;
			top: 20px;
			background-color: $white-color;
			color: $black-color;
			padding: 5px 10px 6px;
			transition: $transition;
			visibility: hidden;
			font: {
				style: italic;
				size: 15px;
				weight: 600;
			}
		}
	}
	.post-content {
		margin-top: 20px;

		.category {
			display: block;
			margin-bottom: 10px;
			text-transform: uppercase;
			color: $main-color;
			font: {
				weight: 600;
				size: 13px;
			}
		}
		p {
			display: -webkit-box;
			-webkit-box-orient: vertical;
			-webkit-line-clamp: 3;
			overflow: hidden;
		}
		h3 {
			margin-bottom: 0;
			line-height: 1.3;
			font: {
				size: 23px;
				weight: 700;
			}

			a {
				display: -webkit-box;
				-webkit-box-orient: vertical !important;
				-webkit-line-clamp: 2 !important;
				overflow: hidden;
			}
		}
		.details-btn {
			margin-top: 13px;
			display: inline-block;
			position: relative;
			font: {
				size: $font-size;
				weight: 600;
			}
			&::before {
				width: 100%;
				height: 1px;
				background-color: $black-color;
				content: "";
				position: absolute;
				transition: $transition;
				left: 0;
				bottom: 0;
			}
			&:hover {
				color: $main-color;

				&::before {
					background-color: $main-color;
				}
			}
		}
	}
	&:hover {
		.post-image {
			img {
				transform: scale(1.07) rotate(2deg);
			}
			.date {
				visibility: visible;
				right: 0;
			}
		}
	}
}
.blog-slides {
	&.owl-theme {
		.owl-dots {
			margin: {
				top: 20px !important;
				bottom: 30px;
			}
			.owl-dot {
				span {
					width: 15px;
					height: 15px;
					margin: 0 4px;
					background: transparent;
					position: relative;
					transition: $transition;
					border-radius: 50%;
					border: 1px solid transparent;

					&::before {
						content: "";
						position: absolute;
						left: 0;
						top: 0;
						right: 0;
						bottom: 0;
						transition: $transition;
						border-radius: 50%;
						background-color: $black-color;
						margin: 2px;
					}
				}
				&:hover,
				&.active {
					span {
						border-color: $main-color;

						&::before {
							background-color: $main-color;
						}
					}
				}
			}
		}
	}
}
.blog-items {
	margin: {
		left: -15px;
		right: -15px;
	}
}

/*================================================
Blog Details CSS
=================================================*/
.blog-header {
	display: flex;
	flex-direction: row;
	.entry-meta {
		display: flex;
		flex-direction: column;
		ul {
			padding-left: 0;
			margin-bottom: 0;
			list-style-type: none;
			align-self: end;
			justify-self: end;
			justify-content: end;
			display: flex;
			flex-direction: column;
			justify-content: end;

			li {
				margin-left: 30px;
				margin-bottom: 30px;
				margin-top: 30px;
				position: relative;
				display: flex;
				flex-direction: column;
				font: {
					weight: 500;
					size: 16px;
				}
				padding: {
					right: 20px;
					left: 45px;
				}
				i {
					font-size: 32px;
					color: #cfcfcf;
					position: absolute;
					left: 0;
					top: 50%;
					transform: translateY(-50%);
				}
				span {
					display: block;
					color: $main-color;
					text-transform: uppercase;
					margin-bottom: 2px;
					font: {
						size: 14px;
						weight: 500;
					}
				}
				a {
					display: inline-block;
					font: {
						size: 16px;
						weight: 600;
					}
				}
				&:last-child {
					padding-right: 0;
					margin-right: 0;
					border-right: none;
				}
			}
		}
	}
}
.blog-details-desc {
	margin-top: 10vh;
	.article-image {
		img {
			max-height: 70vh;
			max-width: 100%;
		}
	}
	.article-image-slides {
		&.owl-theme {
			.owl-nav {
				margin-top: 0;

				[class*="owl-"] {
					color: #87a1c1;
					font-size: 18px;
					margin: 0;
					padding: 0;
					background: $white-color;
					width: 45px;
					height: 45px;
					line-height: 40px;
					position: absolute;
					left: 20px;
					transition: $transition;
					top: 50%;
					padding-right: 2px !important;
					transform: translateY(-50%);
					border: {
						radius: 50%;
						style: solid;
						color: $white-color;
						width: 1.5px;
					}
					&.owl-next {
						left: auto;
						right: 20px;
						padding: {
							right: 0 !important;
							left: 2px !important;
						}
					}
					&:hover {
						background-color: $main-color;
						color: $white-color;
						border-color: $main-color;
					}
				}
			}
		}
	}
	.article-video {
		iframe {
			width: 100%;
			height: 400px;
			border: none !important;
		}
	}
	.article-content {
		margin-top: 30px;

		.entry-meta {
			ul {
				padding-left: 0;
				margin-bottom: 0;
				list-style-type: none;

				li {
					margin-right: 20px;
					position: relative;
					display: inline-block;
					border-right: 1px solid #eeeeee;
					font: {
						weight: 500;
						size: 16px;
					}
					padding: {
						right: 20px;
						left: 45px;
					}
					i {
						font-size: 32px;
						color: #cfcfcf;
						position: absolute;
						left: 0;
						top: 50%;
						transform: translateY(-50%);
					}
					span {
						display: block;
						color: $main-color;
						text-transform: uppercase;
						margin-bottom: 2px;
						font: {
							size: 14px;
							weight: 500;
						}
					}
					a {
						display: inline-block;
						font: {
							size: 16px;
							weight: 600;
						}
					}
					&:last-child {
						padding-right: 0;
						margin-right: 0;
						border-right: none;
					}
				}
			}
		}
		h3 {
			margin: {
				bottom: 17px;
				top: 25px;
			}
			font: {
				size: 25px;
				weight: 700;
			}
		}
		.wp-block-gallery {
			&.columns-3 {
				justify-content: center;
				padding-left: 0;
				list-style-type: none;
				display: flex;
				flex-wrap: wrap;
				margin: {
					right: -10px;
					left: -10px;
					bottom: 30px;
					top: 30px;
				}
				li {
					flex: 0 0 33.3333%;
					max-width: 33.3333%;
					padding: {
						right: 10px;
						left: 10px;
					}
					figure {
						margin-bottom: 0;
					}
				}
			}
		}
		.features-list {
			padding-left: 0;
			list-style-type: none;
			margin: {
				top: 25px;
				bottom: 30px;
			}
			li {
				margin-bottom: 15px;
				position: relative;
				padding-left: 26px;
				color: $optional-color;

				i {
					color: $main-color;
					display: inline-block;
					font-size: 20px;
					position: absolute;
					left: 0;
					top: 1px;
				}
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
	.article-footer {
		display: flex;
		flex-wrap: wrap;
		border-top: 1px solid #eeeeee;
		padding-top: 30px;
		margin: {
			top: 30px;
		}
		.article-tags {
			flex: 0 0 50%;
			max-width: 50%;
			color: $optional-color;

			span {
				display: inline-block;
				color: $black-color;
				margin-right: 5px;
				position: relative;
				top: 3px;
				font: {
					size: 20px;
				}
			}
			a {
				display: inline-block;
				position: relative;
				margin-right: 5px;
				padding-right: 4px;
				font: {
					weight: 600;
				}

				&::before {
					content: ",";
					position: absolute;
					right: 0;
				}
				&:last-child {
					margin-right: 0;
					padding-right: 0;
				}
				&:last-child::before {
					display: none;
				}
			}
		}
		.article-share {
			flex: 0 0 50%;
			max-width: 50%;

			.social {
				padding-left: 0;
				list-style-type: none;
				text-align: right;
				margin: {
					bottom: 0;
				}
				li {
					display: inline-block;

					span {
						display: inline-block;
						margin-right: 5px;
						font-weight: 600;
						position: relative;
						top: -2px;
					}
					a {
						display: block;
						width: 32px;
						height: 32px;
						line-height: 32px;
						border-radius: 50%;
						background-color: $main-color;
						color: $white-color;
						border: 1px solid $main-color;
						text-align: center;
						font-size: 17px;
						margin-left: 5px;

						&:hover,
						&:focus {
							color: $main-color;
							background-color: transparent;
						}
						&.facebook {
							background-color: #3b5998;
							border-color: #3b5998;
							color: $white-color;

							&:hover,
							&:focus {
								color: #3b5998;
								background-color: transparent;
							}
						}
						&.twitter {
							background-color: #1da1f2;
							border-color: #1da1f2;
							color: $white-color;

							&:hover,
							&:focus {
								color: #1da1f2;
								background-color: transparent;
							}
						}
						&.linkedin {
							background-color: #007bb5;
							border-color: #007bb5;
							color: $white-color;

							&:hover,
							&:focus {
								color: #007bb5;
								background-color: transparent;
							}
						}
						&.instagram {
							background-color: #c13584;
							border-color: #c13584;
							color: $white-color;

							&:hover,
							&:focus {
								color: #c13584;
								background-color: transparent;
							}
						}
					}
				}
			}
		}
	}
	.article-author {
		margin-top: 30px;
		border-radius: 5px;
		background: #f4f4f4;

		.author-profile-header {
			height: 115px;
			border-radius: 5px 5px 0 0;
			background: {
				color: $main-color;
				image: url(../../../images/patt2-alt.png);
				position: center center;
				size: cover;
				repeat: no-repeat;
			}
		}
		.author-profile {
			padding: 0 25px 25px;

			.author-profile-title {
				position: relative;
				z-index: 1;
				margin: {
					bottom: 15px;
					top: -45px;
				}
				img {
					display: inline-block;
					border: 3px solid $white-color;
					object-fit: cover;
					width: 130px;
				}
				.author-profile-title-details {
					margin-top: 15px;

					.author-profile-details {
						h4 {
							margin-bottom: 5px;
							font: {
								size: 20px;
								weight: 700;
							}
						}
						span {
							color: $optional-color;
						}
					}
				}
				.author-profile-raque-profile {
					a {
						color: $black-color;
						padding: 5px 12px 4px;
						border-radius: 2px;
						display: inline-block;
						background-color: $white-color;

						font: {
							weight: 600;
						}
						&:hover,
						&:focus {
							background-color: $main-color;
							color: $white-color;
						}
					}
				}
			}
		}
	}
}

.institutional-video {
	display: flex;
	justify-content: center;
	iframe {
		width: 100%;
		height: 70vh;
		border: none !important;
	}
}

blockquote,
.blockquote {
	overflow: hidden;
	background-color: #fafafa;
	padding: 50px !important;
	position: relative;
	text-align: center;
	z-index: 1;
	margin: {
		bottom: 20px;
		top: 20px;
	}
	p {
		color: $black-color;
		line-height: 1.6;
		margin-bottom: 0;
		font: {
			style: italic;
			weight: 500;
			size: 22px !important;
		}
	}
	cite {
		display: none;
	}
	&::before {
		color: #efefef;
		position: absolute;
		animation: fade-up 1.5s infinite linear;
		left: 50px;
		top: -50px;
		z-index: -1;
		content: "\ee33";
		font: {
			family: "boxicons" !important;
			weight: normal;
			style: normal;
			variant: normal;
			size: 135px;
		}
	}
	&::after {
		content: "";
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		width: 3px;
		background-color: $main-color;
		margin: {
			top: 20px;
			bottom: 20px;
		}
	}
}
.xton-post-navigation {
	display: flex;
	flex-wrap: wrap;
	margin: {
		top: 30px;
	}
	padding: {
		top: 30px;
		bottom: 30px;
	}
	border: {
		top: 1px solid #eeeeee;
		bottom: 1px solid #eeeeee;
	}
}
.prev-link-wrapper {
	flex: 0 0 50%;
	max-width: 50%;
	padding: {
		right: 15px;
	}
	a {
		display: flex;
		align-items: center;

		&:hover {
			.image-prev {
				&::after {
					opacity: 1;
					visibility: visible;
				}
				.post-nav-title {
					opacity: 1;
					visibility: visible;
				}
			}
			.prev-link-info-wrapper {
				color: $main-color;
			}
		}
	}
	.image-prev {
		display: inline-block;
		min-width: 100px;
		width: 100px;
		border-radius: 5px;
		overflow: hidden;
		vertical-align: top;
		margin-right: 20px;
		position: relative;
		transition: $transition;

		img {
			border-radius: 5px;
		}
		&::after {
			display: block;
			content: "";
			height: 100%;
			width: 100%;
			position: absolute;
			top: 0;
			left: 0;
			border-radius: 5px;
			opacity: 0;
			background-color: $main-color;
			visibility: hidden;
			transition: $transition;
		}
		.post-nav-title {
			position: absolute;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
			right: 0;
			margin: 0 auto;
			text-align: center;
			text-transform: uppercase;
			z-index: 2;
			color: $white-color;
			opacity: 0;
			visibility: hidden;
			transition: $transition;
			font: {
				size: 16px;
				weight: 500;
			}
		}
	}
	.prev-link-info-wrapper {
		color: $black-color;
		transition: $transition;
	}
	.prev-title {
		display: inline-block;
		font: {
			weight: 700;
			size: 17px;
		}
	}
	.meta-wrapper {
		display: block;
		color: $optional-color;
		text-transform: capitalize;
		margin-top: 5px;
		font: {
			weight: 500;
			size: $font-size;
		}
	}
}

.capitalized {
	text-transform: uppercase;
}

.next-link-wrapper {
	flex: 0 0 50%;
	max-width: 50%;
	text-align: right;
	padding: {
		left: 15px;
	}
	a {
		display: flex;
		align-items: center;

		&:hover {
			.image-next {
				&::after {
					opacity: 1;
					visibility: visible;
				}
				.post-nav-title {
					opacity: 1;
					visibility: visible;
				}
			}
			.next-link-info-wrapper {
				color: $main-color;
			}
		}
	}
	.image-next {
		display: inline-block;
		min-width: 100px;
		width: 100px;
		border-radius: 5px;
		overflow: hidden;
		vertical-align: top;
		margin-left: 20px;
		position: relative;
		transition: $transition;

		img {
			border-radius: 5px;
		}
		&::after {
			display: block;
			content: "";
			height: 100%;
			width: 100%;
			position: absolute;
			top: 0;
			left: 0;
			border-radius: 5px;
			opacity: 0;
			background-color: $main-color;
			visibility: hidden;
			transition: $transition;
		}
		.post-nav-title {
			position: absolute;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
			right: 0;
			margin: 0 auto;
			text-align: center;
			text-transform: uppercase;
			z-index: 2;
			color: $white-color;
			opacity: 0;
			visibility: hidden;
			transition: $transition;
			font: {
				size: 16px;
				weight: 500;
			}
		}
	}
	.next-link-info-wrapper {
		transition: $transition;
		color: $black-color;
	}
	.next-title {
		display: inline-block;
		font: {
			weight: 700;
			size: 17px;
		}
	}
	.meta-wrapper {
		display: block;
		color: $optional-color;
		text-transform: capitalize;
		margin-top: 5px;
		font: {
			weight: 500;
			size: $font-size;
		}
	}
}
.comments-area {
	margin-top: 30px;

	.comments-title {
		margin: {
			bottom: 30px;
		}
		line-height: initial;
		font: {
			size: 22px;
			weight: 700;
		}
	}
	ol,
	ul {
		padding: 0;
		margin: 0;
		list-style-type: none;
	}
	.comment-list {
		padding: 0;
		margin: 0;
		list-style-type: none;
	}
	.children {
		margin-left: 20px;
	}
	.comment-body {
		border-bottom: 1px dashed #eeeeee;
		padding-left: 65px;
		color: $black-color;
		font-size: 14px;
		margin-bottom: 20px;
		padding-bottom: 20px;

		.reply {
			margin-top: 15px;

			a {
				border: 1px dashed #ded9d9;
				color: $black-color;
				display: inline-block;
				padding: 5px 20px;
				border-radius: 30px;
				text-transform: uppercase;
				font: {
					size: 13px;
					weight: 600;
				}
				&:hover {
					color: $white-color;
					background-color: $main-color;
					border-color: $main-color;
				}
			}
		}
	}
	.comment-author {
		font-size: 18px;
		margin-bottom: 0.4em;
		position: relative;
		z-index: 2;

		.avatar {
			height: 50px;
			left: -65px;
			position: absolute;
			width: 50px;
		}
		.fn {
			font: {
				weight: 600;
			}
		}
		.says {
			display: none;
		}
	}
	.comment-metadata {
		margin-bottom: 0.8em;
		color: $optional-color;
		letter-spacing: 0.01em;
		text-transform: uppercase;
		font: {
			size: 13px;
			weight: 400;
		}
		span {
			display: inline-block;
			color: $optional-color;

			&:hover {
				color: $main-color;
			}
		}
	}
	.comment-respond {
		margin-top: 30px;

		.comment-reply-title {
			margin-bottom: 0;
			font: {
				size: 22px;
				weight: 700;
			}
			#cancel-comment-reply-link {
				display: inline-block;
			}
		}
		.comment-form {
			overflow: hidden;
		}
		.comment-notes {
			margin: {
				bottom: 20px;
				top: 10px;
			}
			.required {
				color: red;
			}
		}
		.comment-form-comment {
			float: left;
			width: 100%;
		}
		label {
			display: none;
		}
		input[type="date"],
		input[type="time"],
		input[type="datetime-local"],
		input[type="week"],
		input[type="month"],
		input[type="text"],
		input[type="email"],
		input[type="url"],
		input[type="password"],
		input[type="search"],
		input[type="tel"],
		input[type="number"],
		textarea {
			display: block;
			width: 100%;
			background-color: #f4f4f4;
			border: none;
			padding: 0 0 0 15px;
			height: 50px;
			outline: 0;
			border-radius: 3px;
			color: $black-color;
			font: {
				weight: 500;
				size: $font-size;
			}
			&::placeholder {
				transition: $transition;
				color: $optional-color;
			}
			&:focus {
				&::placeholder {
					color: transparent;
				}
			}
		}
		textarea {
			height: auto !important;
			padding-top: 15px;
		}
		.comment-form-author {
			float: left;
			width: 50%;
			padding-right: 10px;
			margin-bottom: 20px;
		}
		.comment-form-email {
			float: left;
			width: 50%;
			padding-left: 12px;
			margin-bottom: 20px;
		}
		.comment-form-url {
			float: left;
			width: 100%;
			margin-bottom: 20px;
		}
		.comment-form-cookies-consent {
			width: 100%;
			float: left;
			position: relative;
			padding-left: 20px;
			margin: {
				bottom: 20px;
				top: 15px;
			}
			input {
				position: absolute;
				left: 0;
				top: 5px;
			}
			label {
				display: inline-block;
				margin: 0;
				color: $optional-color;
				position: relative;
				top: -3.5px;
				font: {
					weight: normal;
					size: 16px;
				}
			}
		}
		.form-submit {
			float: left;
			width: 100%;

			input {
				background: $main-color;
				border: none;
				color: $white-color;
				padding: 10px 30px 9px;
				display: inline-block;
				cursor: pointer;
				text-transform: uppercase;
				transition: $transition;
				border-radius: 0;
				font: {
					weight: 700;
					size: $font-size;
				}
				&:hover,
				&:focus {
					color: $white-color;
					background-color: $black-color;
				}
			}
		}
	}
}

/*================================================
Instagram CSS
=================================================*/
.instagram-area {
	position: relative;
	z-index: 1;

	.container-fluid {
		padding: {
			left: 0;
			right: 0;
		}
	}
}
.instagram-title {
	margin-bottom: 50px;
	text-align: center;

	a {
		display: inline-block;
		position: relative;
		text-transform: uppercase;
		letter-spacing: 2px;
		padding-left: 32px;
		font: {
			size: $font-size;
			weight: 300;
		}
		i {
			position: absolute;
			left: 0;
			top: 50%;
			transform: translateY(-50%);
			font-size: 25px;
		}
	}
}
.single-instagram-post {
	position: relative;
	overflow: hidden;
	z-index: 1;

	i {
		position: absolute;
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
		width: 32px;
		height: 32px;
		text-align: center;
		line-height: 32px;
		color: $white-color;
		font-size: 30px;
		border-radius: 2px;
		opacity: 0;
		visibility: hidden;
		transition: $transition;
		margin-top: 10px;
		background: radial-gradient(
			circle at 30% 107%,
			#fdf497 0,
			#fdf497 5%,
			#fd5949 45%,
			#d6249f 60%,
			#285aeb 90%
		);
	}
	.link-btn {
		position: absolute;
		left: 0;
		top: 0;
		display: block;
		width: 100%;
		height: 100%;
		z-index: 2;
	}
	&::before {
		content: "";
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background-color: $black-color;
		transition: $transition;
		opacity: 0;
		visibility: hidden;
	}
	&:hover {
		i {
			margin-top: 0;
			opacity: 1;
			visibility: visible;
		}
		&::before {
			visibility: visible;
			opacity: 0.5;
		}
	}
}

/*================================================
Modal CSS
=================================================*/
.productsQuickView {
	.modal-dialog {
		max-width: 900px;
		margin-left: auto;
		margin-right: auto;
		margin-bottom: 0;
	}
	.modal-content {
		border: none;
		padding: 40px;
		border-radius: 0;
		background: linear-gradient(0deg, #184d5e, #4d7c8a);
		border-radius: 10px;

		button {
			&.close {
				border-radius: 0px 10px 0px 0px;
				position: absolute;
				right: 0;
				top: 0;
				outline: 0;
				opacity: 1;
				color: $black-color;
				transition: $transition;
				line-height: 35px;
				padding: 0;
				margin: 0;
				font-size: 25px;
				width: 35px;
				text-shadow: unset;
				height: 35px;

				&:hover {
					background-color: red;
					color: $white-color;
				}
			}
		}
		.products-image {
			position: relative;
			.sale-tag {
				position: absolute;
				left: 20px;
				top: 20px;
				background-color: $green-banner;
				color: #ffffff;
				width: 55px;
				height: 55px;
				line-height: 55px;
				text-align: center;
				border-radius: 50%;
				z-index: 2;
				text-transform: uppercase;
				font-weight: 700;
				font-size: 14px;
			}
		}
		.products-content {
			h3 {
				color: white;
				margin-bottom: 30px;
				font: {
					size: 22px;
					weight: 600;
				}
				a {
					color: $black-color;

					&:hover {
						color: $black-color;
					}
				}
			}
			h6 {
				color: white;
				margin: 0px;
				font: {
					size: 17px;
					weight: 400;
				}
			}

			p {
				color: $light-gray;
				font: {
					size: 15px;
					weight: 400;
				}
			}

			.price {
				transition: $transition;
				margin: {
					top: 12px;
					bottom: 15px;
				}
				font: {
					size: $font-size;
					weight: 600;
				}
				.old-price {
					text-decoration: line-through;
					color: #999999;
					font-weight: 500;
					margin-right: 10px;
				}
			}
			.products-review {
				.rating {
					display: inline-block;
					padding-right: 10px;

					i {
						color: #f49f0b;
						display: inline-block;
						margin-right: -2px;
					}
				}
				.rating-count {
					display: inline-block;
					color: $black-color;
					border-bottom: 1px solid $black-color;
					line-height: initial;
					position: relative;
					top: -1px;

					&:hover {
						color: $main-color;
						border-color: $main-color;
					}
				}
			}
			.products-info {
				list-style-type: none;
				padding-left: 0;
				margin: {
					top: 20px;
					bottom: 0;
				}
				li {
					font-size: 16px;
					color: $black-color;
					margin-bottom: 8px;

					&:last-child {
						margin-bottom: 0;
					}
					span {
						color: $optional-color;
						margin-right: 5px;
					}
					a {
						display: inline-block;
						font-weight: 600;
						color: $black-color;

						&:hover {
							color: $black-color;
						}
					}
				}
			}
			.products-color-switch {
				margin-top: 8px;

				h4 {
					font-size: 16px;
					color: $optional-color;
					margin-bottom: 6px;
				}
				ul {
					list-style-type: none;
					padding-left: 0;
					margin: {
						bottom: 0;
						top: -4px;
						left: -4px;
					}
					li {
						display: inline-block;
						margin: {
							left: 4px;
							top: 4px;
						}
						a {
							display: inline-block;
							position: relative;
							border: 1px solid transparent;
							width: 25px;
							height: 25px;
							border-radius: 50%;

							&::before {
								position: absolute;
								content: "";
								left: 0;
								right: 0;
								top: 0;
								bottom: 0;
								background: #eeeeee;
								border-radius: 50%;
								margin: 2px;
								transition: $transition;
							}
							&:hover,
							&:focus {
								border-color: $black-color;
							}
							&.color-white {
								&::before {
									background: $white-color;
									border: 1px solid #eeeeee;
								}
							}
							&.color-black {
								&::before {
									background: $black-color;
								}
							}
							&.color-red {
								&::before {
									background: red;
								}
							}
							&.color-blue {
								&::before {
									background: blue;
								}
							}
							&.color-green {
								&::before {
									background: green;
								}
							}
							&.color-yellow {
								&::before {
									background: yellow;
								}
							}
							&.color-yellowgreen {
								&::before {
									background: yellowgreen;
								}
							}
							&.color-pink {
								&::before {
									background: pink;
								}
							}
							&.color-violet {
								&::before {
									background: violet;
								}
							}
							&.color-teal {
								&::before {
									background: teal;
								}
							}
							&.color-plum {
								&::before {
									background: plum;
								}
							}
							&.color-lime {
								&::before {
									background: lime;
								}
							}
							&.color-blueviolet {
								&::before {
									background: blueviolet;
								}
							}
						}
						&.active {
							a {
								border-color: $black-color;
							}
						}
					}
				}
			}
			.products-size-wrapper {
				margin: {
					top: 8px;
					bottom: 25px;
				}
				h4 {
					font-size: 16px;
					color: $optional-color;
					margin-bottom: 8px;
				}
				ul {
					list-style-type: none;
					padding-left: 0;
					margin: {
						bottom: 0;
						top: -8px;
						left: -8px;
					}
					li {
						display: inline-block;
						margin: {
							left: 8px;
							top: 8px;
						}
						a {
							width: 35px;
							height: 30px;
							display: inline-block;
							line-height: 28px;
							border: 1px solid #eeeeee;
							color: #858585;
							text-align: center;
							font: {
								size: 14px;
								weight: 600;
							}
							&:hover,
							&:focus {
								border-color: $black-color;
								color: $black-color;
							}
						}
						&.active {
							a {
								border-color: $black-color;
								color: $black-color;
							}
						}
					}
				}
			}
			.products-add-to-cart {
				.input-counter {
					max-width: 110px;
					min-width: 110px;
					margin-right: 10px;
					text-align: center;
					display: inline-block;
					position: relative;

					span {
						position: absolute;
						top: 0;
						background-color: transparent;
						cursor: pointer;
						color: #d0d0d0;
						width: 40px;
						height: 100%;
						line-height: 50px;
						transition: $transition;
						font-size: 18px;

						&.minus-btn {
							left: 0;
						}
						&.plus-btn {
							right: 0;
						}
						&:hover {
							color: $main-color;
						}
					}
					input {
						height: 45px;
						color: $black-color;
						outline: 0;
						display: block;
						border: none;
						background-color: #f8f8f8;
						text-align: center;
						width: 100%;
						font: {
							size: 17px;
							weight: 600;
						}
						&::placeholder {
							color: $black-color;
						}
					}
				}
			}
			.view-full-info {
				margin-top: 25px;
				color: $black-color;
				line-height: initial;
				display: inline-block;
				position: relative;
				font: {
					weight: 600;
					size: 15px;
				}
				&::before {
					content: "";
					position: absolute;
					bottom: 0;
					left: 0;
					width: 100%;
					height: 1px;
					background: $black-color;
					transition: $transition;
				}
				&:hover {
					&::before {
						width: 0;
					}
				}
			}
		}
	}

	&.active {
		opacity: 1;
		overflow: visible;
		display: block;
		background: rgba(0, 0, 0, 0.6);
	}
}

.shoppingCartModal {
	&.right {
		position: fixed;
		right: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.8);
		z-index: 99999;
		overflow: hidden;
		opacity: 0;
		visibility: hidden;
		transition: $transition;

		.modal-innter-content {
			width: 320px;
			background-color: #ffffff;
			height: 100%;
			margin-left: auto;
			overflow-y: auto;
			margin-right: -500px;
			transition: $transition;

			.modal-body {
				padding: 25px 15px;

				h3 {
					font-size: 17px;
					text-transform: uppercase;
					margin-bottom: 25px;
					font-family: $font-family2;
					font-weight: 600;
					color: $main-color;
				}
				.products-cart-content {
					.products-cart {
						position: relative;
						border-bottom: 1px dashed #eeeeee;
						margin-bottom: 15px;
						padding: {
							left: 70px;
							bottom: 15px;
						}
						.products-image {
							position: absolute;
							left: 0;
							top: 0;
							width: 55px;

							a {
								display: block;
							}
						}
						.products-content {
							position: relative;

							h3 {
								font-size: 15px;
								text-transform: initial;
								margin-bottom: 5px;

								a {
									display: inline-block;
								}
							}
							span {
								font-size: 12px;
								display: block;
								margin-bottom: 3px;
								color: $optional-color;
							}
							.products-price {
								span {
									display: inline-block;
									margin-bottom: 0;
									font-size: 13px;
									color: $optional-color;
									margin-right: 3px;

									&.price {
										font-size: 14px;
										color: $black-color;
									}
								}
							}
							.remove-btn {
								position: absolute;
								right: 0;
								top: 50%;
								transform: translateY(-50%);
								display: inline-block;
								color: $optional-color;
								opacity: 0;
								visibility: hidden;
								transition: $transition;

								&:hover {
									color: red;
								}
							}
							&:hover {
								.remove-btn {
									opacity: 1;
									visibility: visible;
								}
							}
						}
					}
				}
				.products-cart-subtotal {
					overflow: hidden;
					margin-bottom: 15px;
					border-bottom: 1px dashed #eeeeee;
					padding-bottom: 15px;

					span {
						text-transform: uppercase;
						display: inline-block;
						font: {
							size: 15px;
							weight: 600;
						}
						&.subtotal {
							float: right;
						}
					}
				}
				.products-cart-btn {
					.default-btn {
						display: block;
						width: 100%;
						text-align: center;
					}
					.optional-btn {
						display: block;
						width: 100%;
						margin-top: 15px;
						text-align: center;
					}
				}
			}
		}

		&.active {
			opacity: 1;
			visibility: visible;

			.modal-innter-content {
				margin-right: 0;
			}
		}
	}
	.close {
		color: $black-color;
		font-size: 30px;
		outline: 0 !important;
		line-height: initial;
		transition: $transition;
		position: absolute;
		right: 15px;
		top: 17px;
		z-index: 1;

		&:not(:disabled):not(.disabled) {
			&:hover,
			&:focus {
				color: red;
				text-decoration: none;
				opacity: 1;
			}
		}
	}
}

.sidebarModal {
	&.right {
		position: fixed;
		right: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.8);
		z-index: 99999;
		overflow: hidden;
		opacity: 0;
		visibility: hidden;
		transition: $transition;

		.modal-innter-content {
			width: 400px;
			background-color: #ffffff;
			height: 100%;
			margin-left: auto;
			overflow-y: auto;
			margin-right: -500px;
			transition: $transition;

			.modal-body {
				padding: 40px;

				h3 {
					margin-bottom: 25px;
					padding-bottom: 5px;
					position: relative;
					border-bottom: 1px solid rgba(0, 0, 0, 0.1);
					font: {
						size: 20px;
						weight: 600;
					}
					&::before {
						width: 50px;
						height: 1px;
						bottom: -1px;
						content: "";
						position: absolute;
						left: 0;
						background-color: $main-color;
					}
				}
				.sidebar-about-content {
					.about-the-store {
						.sidebar-contact-info {
							padding-left: 0;
							margin-bottom: 0;
							list-style-type: none;

							li {
								margin-bottom: 12px;
								position: relative;
								padding-left: 25px;

								i {
									position: absolute;
									left: 0;
									top: 2px;
									font-size: 18px;
								}
								a {
									display: inline-block;
									font-weight: 600;
									position: relative;

									&:hover {
										&::before {
											width: 100%;
										}
									}
									&::before {
										content: "";
										position: absolute;
										left: 0;
										bottom: 0;
										width: 0;
										height: 1px;
										transition: $transition;
										background-color: $main-color;
									}
								}
								&:last-child {
									margin-bottom: 0;
								}
							}
						}
					}
					.social-link {
						padding-left: 0;
						list-style-type: none;
						margin: {
							top: 20px;
							bottom: 0;
						}
						li {
							display: inline-block;
							margin-right: 10px;

							&:last-child {
								margin-right: 0;
							}
							a {
								width: 30px;
								height: 30px;
								text-align: center;
								color: $main-color;
								background-color: #f4f4f4;
								font-size: 18px;
								position: relative;
								border-radius: 2px;

								i {
									position: absolute;
									left: 0;
									top: 50%;
									right: 0;
									transform: translateY(-49%);
								}
								&:hover {
									background-color: $main-color;
									color: $white-color;
								}
							}
						}
					}
				}
				.sidebar-new-in-store {
					margin-top: 30px;

					.products-list {
						padding-left: 0;
						list-style-type: none;
						display: flex;
						flex-wrap: wrap;
						margin: {
							top: -10px;
							bottom: 20px;
							left: -5px;
							right: -5px;
						}
						li {
							flex: 0 0 25%;
							max-width: 25%;
							padding: {
								left: 5px;
								right: 5px;
								top: 10px;
							}
							a {
								display: block;
								position: relative;

								&::before {
									content: "";
									position: absolute;
									left: 0;
									top: 0;
									width: 100%;
									height: 100%;
									background-color: $main-color;
									transition: $transition;
									opacity: 0;
									visibility: hidden;
								}
								&:hover {
									&::before {
										opacity: 0.88;
										visibility: visible;
									}
								}
							}
						}
					}
					.shop-now-btn {
						display: inline-block;
						text-transform: uppercase;
						border-bottom: 1px solid $main-color;
						font: {
							weight: 600;
							size: 14px;
						}
						&:hover {
							color: $main-color;
							border-color: $main-color;
						}
					}
				}
			}
		}

		&.active {
			opacity: 1;
			visibility: visible;

			.modal-innter-content {
				margin-right: 0;
			}
		}
	}

	.close {
		color: $black-color;
		font-size: 28px;
		outline: 0 !important;
		line-height: initial;
		transition: $transition;
		position: absolute;
		right: 40px;
		top: 37px;
		z-index: 1;

		&:not(:disabled):not(.disabled) {
			&:hover,
			&:focus {
				color: red;
				text-decoration: none;
				opacity: 1;
			}
		}
	}
}

.sizeGuideModal {
	.modal-dialog {
		max-width: 900px;
		margin: 0 auto;
	}
	.modal-content {
		border: none;
		padding: 40px;
		border-radius: 0;

		button {
			&.close {
				position: absolute;
				right: 0;
				top: 0;
				outline: 0;
				opacity: 1;
				color: $black-color;
				transition: $transition;
				line-height: 35px;
				padding: 0;
				margin: 0;
				font-size: 20px;
				width: 35px;
				text-shadow: unset;
				height: 35px;

				&:hover {
					background-color: red;
					color: $white-color;
				}
			}
		}
		.modal-sizeguide {
			text-align: center;

			h3 {
				margin-bottom: 7px;
				font: {
					size: 22px;
					weight: 700;
				}
			}
			p {
				margin-bottom: 25px;
			}
			.table {
				margin-bottom: 0;

				thead {
					background-color: $black-color;
					color: $white-color;

					th {
						border-bottom: none;
						white-space: nowrap;
						color: $white-color;
						vertical-align: middle;
						padding: 10px 15px;
					}
				}
				tr {
					td,
					th {
						border-top: none;
						white-space: nowrap;
						vertical-align: middle;
						font-size: $font-size;
						padding: 10px 15px;
					}
				}
			}
		}
	}
}

.productsShippingModal {
	.modal-dialog {
		max-width: 900px;
		margin: 0 auto;
	}
	.modal-content {
		border: none;
		padding: 40px;
		border-radius: 0;

		button {
			&.close {
				position: absolute;
				right: 0;
				top: 0;
				outline: 0;
				opacity: 1;
				color: $black-color;
				transition: $transition;
				line-height: 35px;
				padding: 0;
				margin: 0;
				font-size: 25px;
				width: 35px;
				text-shadow: unset;
				height: 35px;

				&:hover {
					background-color: red;
					color: $white-color;
				}
			}
		}
		.shipping-content {
			h3 {
				margin: {
					bottom: 15px;
					top: 30px;
				}
				font: {
					size: 22px;
					weight: 700;
				}
				&:first-child {
					margin-top: 0;
				}
			}
			ul {
				padding-left: 0;
				margin-bottom: 0;
				list-style-type: none;

				li {
					margin-bottom: 12px;
					color: $optional-color;
					position: relative;
					padding-left: 13px;

					&::before {
						content: "";
						position: absolute;
						top: 9px;
						left: 0;
						width: 6px;
						height: 6px;
						border-radius: 50%;
						background: $main-color;
					}
					&:last-child {
						margin-bottom: 0;
					}
				}
			}
		}
	}
}

.shoppingWishlistModal {
	&.right {
		position: fixed;
		right: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.8);
		z-index: 99999;
		overflow: hidden;
		opacity: 0;
		visibility: hidden;
		transition: $transition;

		.modal-innter-content {
			width: 320px;
			background-color: #ffffff;
			height: 100%;
			margin-left: auto;
			overflow-y: auto;
			margin-right: -400px;
			transition: $transition;

			.modal-body {
				padding: 25px 15px;

				h3 {
					font-size: 17px;
					text-transform: uppercase;
					margin-bottom: 25px;
				}
				.products-cart-content {
					.products-cart {
						position: relative;
						border-bottom: 1px dashed #eeeeee;
						margin-bottom: 15px;
						padding: {
							left: 70px;
							bottom: 15px;
						}
						.products-image {
							position: absolute;
							left: 0;
							top: 0;
							width: 55px;

							a {
								display: block;
							}
						}
						.products-content {
							position: relative;

							h3 {
								font-size: 15px;
								text-transform: initial;
								margin-bottom: 5px;

								a {
									display: inline-block;
								}
							}
							span {
								font-size: 12px;
								display: block;
								margin-bottom: 3px;
								color: $optional-color;
							}
							.products-price {
								span {
									display: inline-block;
									margin-bottom: 0;
									font-size: 13px;
									color: $optional-color;
									margin-right: 3px;
									&:last-child {
										margin-right: 0;
									}

									&.price {
										font-size: 14px;
										color: $black-color;
									}
								}
							}
							.remove-btn {
								position: absolute;
								right: 0;
								top: 50%;
								transform: translateY(-50%);
								display: inline-block;
								color: $optional-color;
								opacity: 0;
								visibility: hidden;
								transition: $transition;

								&:hover {
									color: red;
								}
							}

							&:hover {
								.remove-btn {
									opacity: 1;
									visibility: visible;
								}
							}
						}
					}
				}

				.products-cart-btn {
					.default-btn {
						display: block;
						width: 100%;
						text-align: center;
					}

					.optional-btn {
						display: block;
						width: 100%;
						margin-top: 15px;
						text-align: center;
					}
				}
			}
		}

		&.active {
			opacity: 1;
			visibility: visible;

			.modal-innter-content {
				margin-right: 0;
			}
		}
	}

	.close {
		color: $black-color;
		font-size: 30px;
		outline: 0 !important;
		line-height: initial;
		transition: $transition;
		position: absolute;
		right: 15px;
		top: 17px;
		z-index: 1;

		&:not(:disabled):not(.disabled) {
			&:hover,
			&:focus {
				color: red;
				text-decoration: none;
				opacity: 1;
			}
		}
	}
}

.productsFilterModal {
	&.modal {
		&.left {
			.modal-dialog {
				position: fixed;
				margin: auto;
				width: 400px;
				height: 100%;
				transform: translate3d(0%, 0, 0);
			}
			&.fade {
				.modal-dialog {
					left: -320px;
					transition: opacity 0.3s linear, left 0.3s ease-out;
				}
				&.show {
					.modal-dialog {
						left: 0;
					}
				}
			}
		}
	}
	.modal-content {
		height: 100%;
		overflow-y: auto;
		border-radius: 0;
		border: none;
		position: relative;
		padding: {
			top: 60px;
			bottom: 60px;
			left: 30px;
			right: 30px;
		}
		.modal-body {
			padding: 0;

			.woocommerce-widget-area {
				.filter-list-widget {
					.selected-filters-wrap-list {
						ul {
							li {
								a {
									i {
										top: 0;
										padding-left: 0;
									}
								}
							}
						}
					}
				}
			}
		}
	}
	button {
		&.close {
			position: absolute;
			left: 30px;
			top: 20px;
			outline: 0;
			padding-left: 25px;
			text-shadow: unset;
			opacity: 1;
			line-height: initial;
			transition: $transition;
			font: {
				size: $font-size;
			}
			i {
				font-size: 24px;
				position: absolute;
				left: 0;
				top: -3.8px;
			}
			&:hover {
				color: red;
			}
		}
	}
}

/*================================================
Page Title CSS
=================================================*/
.page-title-area {
	background: url(../../../images/patt3.png);
	background-color: #f8f8f8;
	padding: {
		top: $navbar-height + 3.8vh;
		bottom: 30px;
	}
}
.page-title-content {
	position: relative;

	h2 {
		margin-bottom: 0;
		font: {
			size: 25px;
			weight: 700;
		}
	}

	h1 {
		margin-bottom: 0;
		color: $main-color;
		font: {
			// size: 25px;
			weight: 700;
		}
	}
	ul {
		text-align: right;
		position: absolute;
		right: 0;
		padding-left: 0;
		margin-bottom: 0;
		list-style-type: none;
		top: 50%;
		transform: translateY(-50%);

		li {
			display: inline-block;
			margin-left: 20px;
			font-weight: 600;
			color: $main-color;
			position: relative;

			&::before {
				content: "";
				position: absolute;
				left: -10px;
				top: 6px;
				height: 12px;
				width: 1px;
				background-color: $optional-color;
			}
			&:first-child {
				margin-left: 0;

				&::before {
					display: none;
				}
			}
			a {
				display: block;
			}
		}
	}
}

/*================================================
Gallery CSS
=================================================*/
.gallery-area {
	.container-fluid {
		padding: {
			left: 0;
			right: 0;
		}
	}
	.row {
		margin: {
			left: 0;
			right: 0;
		}
		.col-lg-1,
		.col-lg-2,
		.col-lg-3,
		.col-lg-4,
		.col-lg-5,
		.col-lg-6,
		.col-lg-7,
		.col-lg-8,
		.col-lg-9,
		.col-lg-10,
		.col-lg-11,
		.col-lg-12 {
			padding: {
				left: 0;
				right: 0;
			}
		}
	}
}
.single-gallery-item {
	position: relative;
	overflow: hidden;
	text-align: center;

	img {
		transition: $transition;
		width: 100%;
	}
	.popup-btn {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 2;
	}
	.gallery-content {
		position: absolute;
		bottom: 0;
		left: 10px;
		border: 1px solid $white-color;
		right: 10px;
		padding: 25px 15px;
		z-index: 1;
		opacity: 0;
		visibility: hidden;
		transition: $transition;

		&::before {
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			right: 0;
			bottom: 0;
			margin: 5px;
			background-color: $white-color;
			z-index: -1;
		}
		span {
			display: block;
			color: $main-color;
			text-transform: uppercase;
			margin-bottom: 5px;
			font-size: 14px;
		}
		h3 {
			font: {
				size: 22px;
				weight: 700;
			}
			margin: {
				bottom: 0;
			}
		}
	}
	&:hover {
		img {
			transform: rotate(8deg) scale(1.3);
		}
		.gallery-content {
			bottom: 10px;
			opacity: 1;
			visibility: visible;
		}
	}
}
.gallery-items {
	.single-gallery-item {
		margin-bottom: 30px;
	}
}

.row-justify-space-evenly {
	justify-content: space-evenly;
}

.title-underline {
	text-decoration: underline;
}
/*================================================
Look Book CSS
=================================================*/
.lookbook-items {
	margin: {
		left: -15px;
		right: -15px;
	}
}
.single-lookbook-box {
	position: relative;
	margin-bottom: 30px;

	a {
		display: block;
	}
	.content {
		transition: $transition;
		background-color: $white-color;
		padding: 10px;
		border-radius: 5px;
		position: relative;
		box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
		top: 0;
		margin: {
			left: 10px;
			top: -10px;
			right: 10px;
		}
		p {
			display: block;
			margin-bottom: 8px;
			font: {
				size: $font-size;
				weight: 600;
			}
			a {
				display: inline-block;
				padding-right: 3px;
			}
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	&:hover {
		.content {
			top: -10px;
		}
	}
}

/*================================================
Woocommerce Widget Sidebar CSS
=================================================*/
.woocommerce-widget-area {
	.woocommerce-widget {
		margin-bottom: 35px;

		.woocommerce-widget-title {
			margin-bottom: 25px;
			position: relative;
			padding-bottom: 8px;
			border-bottom: 1px solid #eeeeee;
			font: {
				size: 20px;
				weight: 700;
			}
			&::before {
				content: "";
				position: absolute;
				left: 0;
				bottom: 0;
				width: 50px;
				height: 1px;
				bottom: -1px;
				background-color: $main-color;
			}
		}
		&:last-child {
			margin-bottom: 0;
		}
	}
	.filter-list-widget {
		.selected-filters-wrap-list {
			ul {
				padding-left: 0;
				margin-bottom: 0;
				list-style-type: none;

				li {
					margin-bottom: 9px;
					font: {
						size: 14.5px;
						weight: 600;
					}
					a {
						position: relative;
						padding-left: 28px;
						display: inline-block;

						i {
							display: inline-block;
							background-color: $main-color;
							width: 18px;
							height: 18px;
							color: $white-color;
							border-radius: 50%;
							text-align: center;
							line-height: 19px;
							position: absolute;
							left: 0;
							top: 1px;
							padding: {
								left: 1px;
							}
						}
					}
					&:last-child {
						margin-bottom: 0;
					}
				}
			}
			.delete-selected-filters {
				display: inline-block;
				margin-top: 15px;
				font: {
					size: $font-size;
					weight: 600;
				}
				&:hover {
					color: red;
				}
				span {
					text-decoration: underline;
				}
			}
		}
	}
	.collections-list-widget {
		.collections-list-row {
			padding-left: 0;
			margin-bottom: 0;
			list-style-type: none;

			li {
				margin-bottom: 9px;

				a {
					display: inline-block;
					position: relative;
					padding-left: 25px;
					font: {
						size: $font-size;
						weight: 600;
					}
					&::before {
						content: "";
						position: absolute;
						left: 0;
						top: 2.5px;
						width: 15px;
						transition: $transition;
						height: 15px;
						border-radius: 50%;
						border: 1px solid $black-color;
					}
					&:hover {
						color: $main-color;

						&::before {
							border-color: $main-color;
						}
					}
					&.active {
						&::before {
							background-color: $main-color;
							border-color: $main-color;
						}
					}
				}
				&.active {
					a {
						&::before {
							background-color: $main-color;
							border-color: $main-color;
						}
					}
				}
				&:last-child {
					display: inline-block;
				}
			}
		}
	}
	.price-list-widget {
		.collection-filter-by-price {
			margin-top: 20px;

			.irs-handle {
				cursor: pointer;
			}
			.irs--flat .irs-from,
			.irs--flat .irs-to,
			.irs--flat .irs-single {
				background-color: $main-color;
				padding: 1px 5px 1px 11.5px;

				&::before {
					border-top-color: $main-color;
				}
				&::after {
					content: "$";
					position: absolute;
					left: 5px;
					top: 0.5px;
				}
			}
			.irs--flat .irs-bar {
				background-color: $main-color;
			}
			.irs--flat .irs-handle > i:first-child {
				background-color: $main-color;
			}
			.irs--flat .irs-min,
			.irs--flat .irs-max {
				padding: 1px 3px 1px 9.5px;

				&::before {
					content: "$";
					position: absolute;
					left: 3px;
					top: 0.5px;
				}
			}
			.js-range-of-price {
				width: 100%;
				height: 45px;
				border: 1px solid #eeeeee;
				padding: 6px 15px;
				font-size: 15px;
			}
		}
	}
	.size-list-widget {
		.size-list-row {
			list-style-type: none;
			padding-left: 0;
			margin: {
				bottom: 0;
				top: -10px;
			}
			li {
				display: inline-block;
				margin: {
					right: 10px;
					top: 10px;
				}
				a {
					width: 35px;
					height: 35px;
					display: inline-block;
					line-height: 33px;
					border: 1px solid #eeeeee;
					text-align: center;
					font: {
						size: 13px;
						weight: 600;
					}
					&:hover,
					&:focus {
						border-color: $main-color;
						color: $white-color;
						background-color: $main-color;
					}
					&.active {
						a {
							border-color: $main-color;
							color: $white-color;
							background-color: $main-color;
						}
					}
				}
				&.active {
					a {
						border-color: $main-color;
						color: $white-color;
						background-color: $main-color;
					}
				}
			}
		}
	}
	.color-list-widget {
		.color-list-row {
			list-style-type: none;
			padding-left: 0;
			margin: {
				bottom: -5px;
				top: -4px;
			}
			li {
				display: inline-block;
				margin: {
					right: 4px;
					top: 4px;
				}
				a {
					display: block;
					position: relative;
					border: 1px solid transparent;
					width: 25px;
					height: 25px;
					border-radius: 50%;

					&::before {
						position: absolute;
						content: "";
						left: 0;
						right: 0;
						top: 0;
						bottom: 0;
						background: #eeeeee;
						border-radius: 50%;
						margin: 2px;
						transition: $transition;
					}
					&:hover {
						border-color: $black-color;
					}
					&.color-white {
						&::before {
							background: $white-color;
							border: 1px solid #eeeeee;
						}
					}
					&.color-black {
						&::before {
							background: $black-color;
						}
					}
					&.color-red {
						&::before {
							background: red;
						}
					}
					&.color-blue {
						&::before {
							background: blue;
						}
					}
					&.color-green {
						&::before {
							background: green;
						}
					}
					&.color-yellow {
						&::before {
							background: yellow;
						}
					}
					&.color-yellowgreen {
						&::before {
							background: yellowgreen;
						}
					}
					&.color-pink {
						&::before {
							background: pink;
						}
					}
					&.color-violet {
						&::before {
							background: violet;
						}
					}
					&.color-teal {
						&::before {
							background: teal;
						}
					}
					&.color-plum {
						&::before {
							background: plum;
						}
					}
					&.color-lime {
						&::before {
							background: lime;
						}
					}
					&.color-blueviolet {
						&::before {
							background: blueviolet;
						}
					}
				}
				&.active {
					a {
						border-color: $black-color;
					}
				}
			}
		}
	}
	.brands-list-widget {
		.brands-list-row {
			padding-left: 0;
			margin-bottom: 0;
			list-style-type: none;

			li {
				margin-bottom: 9px;

				a {
					display: inline-block;
					position: relative;
					padding-left: 25px;
					font: {
						size: $font-size;
						weight: 600;
					}
					&::before {
						content: "";
						position: absolute;
						left: 0;
						top: 2.5px;
						width: 15px;
						transition: $transition;
						height: 15px;
						border-radius: 3px;
						border: 1px solid $black-color;
					}
					&:hover {
						color: $main-color;

						&::before {
							border-color: $main-color;
						}
					}
					&.active {
						&::before {
							background-color: $main-color;
							border-color: $main-color;
						}
					}
				}
				&.active {
					a {
						&::before {
							background-color: $main-color;
							border-color: $main-color;
						}
					}
				}
				&:last-child {
					display: inline-block;
				}
			}
		}
	}
	.aside-trending-widget {
		.aside-trending-products {
			overflow: hidden;
			position: relative;
			text-align: center;

			.category {
				position: absolute;
				left: 0;
				transition: $transition;
				right: 0;
				background-color: $white-color;
				padding: 20px;
				bottom: 10px;
				margin: {
					left: 10px;
					right: 10px;
				}
				h3 {
					text-transform: uppercase;
					margin-bottom: 7px;
					position: relative;
					z-index: 1;
					font: {
						size: 20px;
					}
				}
				span {
					color: $optional-color;
					display: block;
				}
			}
			img {
				transition: $transition;
				width: 100%;
			}
			.link-btn {
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 3;
			}
			&:hover,
			&:focus {
				img {
					transform: scale(1.3) rotate(4deg);
				}
			}
		}
	}
}

/*================================================
Sizing Guide CSS
=================================================*/
.sizing-guide-table {
	h3 {
		margin-bottom: 18px;
		font: {
			size: 22px;
			weight: 700;
		}
	}
	table {
		margin-bottom: 0;

		thead {
			background-color: $main-color;
			color: $white-color;

			th {
				border-bottom: none;
				vertical-align: middle;
				color: $white-color;
				white-space: nowrap;
				font: {
					size: 17px;
					weight: 600;
				}
				padding: {
					left: 15px;
					right: 15px;
				}
			}
		}
		tr {
			td {
				border-top: none;
				white-space: nowrap;
				vertical-align: middle;
				font-size: $font-size;
				padding: {
					left: 15px;
					right: 15px;
				}
				&:first-child {
					font-weight: 600;
				}
			}
		}
	}
	.sizing-guide-info {
		padding-left: 0;
		list-style-type: none;
		margin: {
			top: 30px;
			bottom: 0;
		}
		li {
			margin-bottom: 12px;

			&:last-child {
				margin-bottom: 0;
			}
			span {
				font-weight: 700;
			}
		}
	}
	&:not(:first-child) {
		margin-top: 35px;
	}
}

/*================================================
Cart CSS
=================================================*/
.cart-table {
	table {
		margin-bottom: 0;

		thead {
			tr {
				th {
					border-bottom-width: 0px;
					vertical-align: middle;
					padding: 0 0 15px;
					text-transform: uppercase;
					border: none;
					white-space: nowrap;
					font: {
						size: $font-size;
						weight: 600;
					}
				}
			}
		}
		tbody {
			tr {
				td {
					vertical-align: middle;
					color: $optional-color;
					white-space: nowrap;
					padding: {
						left: 0;
						right: 0;
						top: 15px;
						bottom: 15px;
					}
					border: {
						color: #eaedff;
						left: none;
						right: none;
					}
					&.product-thumbnail {
						a {
							display: inline-block;

							img {
								width: 100px;
							}
						}
					}
					&.product-name {
						a {
							display: inline-block;
							font: {
								size: 16px;
								weight: 600;
							}
						}
						ul {
							padding-left: 0;
							list-style-type: none;
							margin: {
								top: 10px;
								bottom: 0;
							}
							li {
								margin-bottom: 6px;
								font-size: 13.5px;

								span {
									display: inline;
									color: $black-color;
								}
								&:last-child {
									margin-bottom: 0;
								}
							}
						}
					}
					&.product-subtotal {
						overflow: hidden;

						.subtotal-amount {
							color: $black-color;
							font-weight: 600;
						}
						.remove {
							color: red;
							float: right;
							display: block;
							position: relative;
							top: -1px;
							font-size: 18px;
						}
					}
					&.product-quantity {
						.input-counter {
							max-width: 130px;
							min-width: 130px;
							text-align: center;
							display: inline-block;
							position: relative;

							span {
								position: absolute;
								top: 0;
								background-color: transparent;
								cursor: pointer;
								color: #d0d0d0;
								width: 40px;
								height: 100%;
								line-height: 50px;
								transition: $transition;
								font-size: 22px;

								&.minus-btn {
									left: 0;
								}
								&.plus-btn {
									right: 0;
								}
								&:hover {
									color: $main-color;
								}
							}
							input {
								height: 45px;
								color: $black-color;
								outline: 0;
								display: block;
								border: none;
								background-color: #f8f8f8;
								text-align: center;
								width: 100%;
								font: {
									size: 17px;
									weight: 600;
								}
								&::placeholder {
									color: $black-color;
								}
							}
						}
					}
					.unit-amount {
						color: $black-color;
						font-weight: 600;
					}
				}
			}
		}
	}
}
.cart-buttons {
	margin-top: 30px;
}
.cart-totals {
	background: $white-color;
	padding: 40px;
	max-width: 500px;
	box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
	border-radius: 5px;
	margin: {
		left: auto;
		top: 50px;
		right: auto;
	}
	h3 {
		margin-bottom: 20px;
		font: {
			size: 22px;
			weight: 700;
		}
	}
	ul {
		padding-left: 0;
		margin: 0 0 25px;
		list-style-type: none;

		li {
			border: 1px solid #eaedff;
			padding: 10px 15px;
			color: $black-color;
			overflow: hidden;
			font: {
				weight: 600;
			}
			&:first-child {
				border: {
					bottom: none;
				}
			}
			&:last-child {
				font: {
					size: 20px;
				}
				border: {
					top: none;
				}
				span {
					color: $black-color;
				}
			}
			span {
				float: right;
				font: {
					weight: normal;
				}
			}
		}
	}
}

/*================================================
Checkout CSS
=================================================*/
.user-actions {
	box-shadow: 0 2px 48px 0 rgba(0, 0, 0, 0.08);
	background: $white-color;
	padding: 15px 20px;
	border-top: 3px solid $main-color;
	position: relative;
	margin-bottom: 40px;

	i {
		color: $main-color;
		margin-right: 5px;
		font-size: 20px;
		position: relative;
		top: 3px;
	}
	span {
		display: inline-block;
		color: $main-color;
		font: {
			weight: 500;
			size: 16px;
		}
		a {
			display: inline-block;
			color: $black-color;

			&:hover,
			&:focus {
				color: $main-color;
			}
		}
	}
}

.green {
	background-color: $green-banner !important;
	border: 1px solid $green-banner !important;
	&:hover {
		color: white !important;
		border: 1px solid $green-banner !important;
	}
}

.green:hover {
	color: white !important;
	border: 1px solid $green-banner !important;
}

.content-paragraph {
	white-space: pre-wrap;
	color: #212529;
}

.billing-details {
	margin-bottom: 50px;
	.billing-row {
		margin-bottom: 50px;
	}
	.title {
		margin-bottom: 30px;
		position: relative;
		padding-bottom: 10px;
		border-bottom: 1px solid #eeeeee;
		font: {
			size: 22px;
			weight: 700;
		}
		&::before {
			content: "";
			position: absolute;
			background: $main-color;
			bottom: -1px;
			left: 0;
			width: 50px;
			height: 1px;
		}
	}
	.subtitle {
		margin-bottom: 30px;
		position: relative;
		font: {
			size: 18px;
			weight: 600;
		}
	}
	.form-group {
		margin-bottom: 25px;

		label {
			display: block;
			margin-bottom: 8px;
			text-transform: uppercase;
			font: {
				weight: 600;
				size: 14px;
			}
			.required {
				color: red;
			}
		}
		.nice-select {
			float: unset;
			line-height: 48px;
			color: $black-color;
			border: none;
			background-color: #f5f5f5 !important;
			font: {
				weight: normal;
				size: $font-size;
			}
			padding: {
				top: 0;
				bottom: 0;
			}
			.list {
				background-color: $white-color;
				box-shadow: 0px 0px 29px 0px rgba(102, 102, 102, 0.1);
				border-radius: 0;
				margin-top: 0;
				width: 100%;
				padding: {
					top: 10px;
					bottom: 10px;
				}
				.option {
					transition: $transition;
					padding: {
						left: 20px;
						right: 20px;
					}
					&:hover {
						background-color: $main-color !important;
						color: $white-color;
					}
					&.selected {
						background-color: transparent;
						font-weight: 600;
					}
				}
			}
			&:after {
				right: 20px;
			}
		}
	}
	.form-check {
		margin-bottom: 20px;

		.form-check-label {
			color: $black-color;
		}
		label {
			position: relative;
			left: -3px;
			top: 1px;
			font: {
				weight: 500;
			}
		}
	}
	.col-lg-12 {
		&:last-child {
			.form-group {
				margin-bottom: 0;
			}
		}
	}
}
.order-details {
	.title {
		margin-bottom: 30px;
		position: relative;
		padding-bottom: 10px;
		border-bottom: 1px solid #eeeeee;
		font: {
			size: 22px;
			weight: 700;
		}
		&::before {
			content: "";
			position: absolute;
			background: $main-color;
			bottom: -1px;
			left: 0;
			width: 50px;
			height: 1px;
		}
	}
	.order-table {
		display: flex;
		justify-content: center;
		align-items: center;
		table {
			thead {
				tr {
					th {
						border-bottom-width: 0;
						vertical-align: middle;
						border-color: #eaedff;
						padding: 12px 20px 10px;
						font: {
							weight: 700;
							size: 17px;
						}
					}
				}
			}
			tbody {
				tr {
					td {
						vertical-align: middle;
						border-color: #eaedff;
						font-size: $font-size;
						padding: {
							left: 20px;
							right: 20px;
							top: 15px;
							bottom: 13px;
						}
						&.product-name {
							a {
								display: inline-block;
								font: {
									weight: 500;
								}
							}
						}
						&.order-subtotal,
						&.order-shipping,
						&.total-price {
							span {
								color: $black-color;
								font-weight: 700;
							}
						}
						&.shipping-price,
						&.order-subtotal-price,
						&.product-subtotal {
							font-weight: 700;
							color: $black-color;
						}
					}
				}
			}
		}
	}
	.payment-box {
		// height: 140px;
		display: flex;
		justify-content: center;
		background-color: $white-color;
		box-shadow: 0 2px 28px 0 rgba(0, 0, 0, 0.06);
		margin-top: 30px;
		padding: 30px;
		a {
			width: 100%;
		}

		.order-btn {
			width: 100%;
		}

		button {
			width: 100%;
		}

		.payment-method {
			p {
				[type="radio"] {
					&:checked,
					&:not(:checked) {
						display: none;
					}
				}
				[type="radio"] {
					&:checked,
					&:not(:checked) {
						+ label {
							padding-left: 27px;
							cursor: pointer;
							display: block;
							color: $black-color;
							position: relative;
							margin-bottom: 8px;
							font: {
								weight: 600;
							}
							&::before {
								content: "";
								position: absolute;
								left: 0;
								top: 5px;
								width: 18px;
								height: 18px;
								border: 1px solid #dddddd;
								border-radius: 50%;
								background: $white-color;
							}
							&::after {
								content: "";
								width: 12px;
								height: 12px;
								background: $main-color;
								position: absolute;
								top: 8px;
								left: 3px;
								border-radius: 50%;
								transition: $transition;
							}
						}
					}
				}
				[type="radio"] {
					&:not(:checked) {
						+ label {
							&::after {
								opacity: 0;
								visibility: hidden;
								transform: scale(0);
							}
						}
					}
				}
				[type="radio"] {
					&:checked {
						+ label {
							&::after {
								opacity: 1;
								visibility: visible;
								transform: scale(1);
							}
						}
					}
				}
			}
		}
		.default-btn {
			margin-top: 20px;
			box-shadow: 0px 5px 28.5px 1.5px rgba(149, 152, 200, 0.2);
			display: block;
			text-align: center;
			&.disabled-btn {
				opacity: 0.5;
			}
		}
	}
}

/*================================================
FAQ CSS
=================================================*/
.faq-accordion-tab {
	.tabs {
		padding-left: 0;
		list-style-type: none;
		margin-bottom: 60px;
		text-align: center;

		li {
			margin: {
				left: 10px;
				right: 10px;
			}

			cursor: pointer;
			color: $black-color;
			text-decoration: none;
			border-radius: 5px;
			padding: 20px;
			display: block;
			box-shadow: 2px 8px 20px 0 rgba(25, 42, 70, 0.13) !important;
			font: {
				size: 17px;
				weight: 700;
			}
			i {
				font-size: 30px;
			}
			span {
				display: block;
				margin-top: 8px;
			}
			&:hover {
				background-color: $main-color;
				color: $white-color;
			}
			&.current {
				background-color: $main-color;
				color: $white-color;
			}
		}
	}
}

.order-details .payment-box-disabled {
	display: flex;
	justify-content: center;
	align-items: center;
}

.order-details .payment-box-disabled button {
	@extend .default-btn;
	opacity: 0.5;
	pointer-events: none;
	//display: none;
}

.payment-box-disabled button {
	@extend .default-btn;
	opacity: 0.5;
	pointer-events: none;
	//display: none;
}

.order-details .payment-box button {
	@extend .default-btn;
	display: box;
}

.tab {
	.tabs-item {
		display: none;

		&:first-child {
			display: block;
		}
	}
}
.faq-accordion {
	.accordion {
		list-style-type: none;
		padding-left: 0;
		margin: {
			bottom: 0;
		}
		border-radius: 0;
		border: none;

		.accordion__item {
			border-radius: 5px;
			display: block;
			box-shadow: 2px 8px 20px 0 rgba(25, 42, 70, 0.13) !important;
			background: $white-color;
			margin-bottom: 15px;
			border: none;

			&:last-child {
				margin-bottom: 0;
			}
		}
		.accordion__button {
			background-color: $white-color;
			padding: 20px 25px;
			color: $black-color;
			text-decoration: none;
			position: relative;
			display: block;
			border-radius: 5px;

			font: {
				size: 17px;
				weight: 700;
			}
			i {
				position: absolute;
				left: 25px;
				top: 18px;
				font-size: 25px;
				transition: $transition;
			}
			&.active {
				i {
					transform: rotate(180deg);
				}
			}
		}
		.accordion__panel {
			padding: 25px 30px;
			border-top: 1px solid #eee;
		}
		.accordion-content {
			p {
				font-size: 16px;
				line-height: 1.8;
			}
			&.show {
				display: block;
			}
			ul {
				padding-left: 0;
				list-style-type: none;
				margin-bottom: 0;

				li {
					color: $optional-color;
					position: relative;
					margin-bottom: 12px;
					line-height: 25px;
					padding-left: 15px;
					font-size: 16px;

					&::before {
						content: "";
						position: absolute;
						top: 10px;
						left: 0;
						width: 6px;
						height: 6px;
						background: $main-color;
						border-radius: 50%;
					}
					&:last-child {
						margin-bottom: 0;
					}
				}
			}
		}
	}
}

/*================================================
404 Error CSS
=================================================*/
.error-content {
	text-align: center;
	margin: 0 auto;
	max-width: 520px;
	height: 250px;
	h3 {
		font: {
			size: 30px;
			weight: 700;
		}
		margin: {
			top: 30px;
			bottom: 15px;
		}
	}
	p {
		margin-bottom: 25px;
	}
}

/*================================================
Customer Service CSS
=================================================*/
.customer-service-content {
	h3 {
		position: relative;
		text-align: center;
		font: {
			size: 22px;
			weight: 700;
		}
		i {
			top: 2px;
			left: 0;
			color: $main-color;
		}
		&:not(:first-child) {
			margin-top: 30px;
		}
	}
	p {
		color: black;
	}
	ul {
		padding-left: 0;
		list-style-type: none;
		margin-bottom: 0;

		li {
			color: $optional-color;
			margin-bottom: 12px;
			position: relative;
			padding-left: 13px;

			&::before {
				content: "";
				position: absolute;
				left: 0;
				top: 9px;
				width: 5px;
				height: 5px;
				border-radius: 50%;
				background: $main-color;
			}
			&:last-child {
				margin-bottom: 0;
			}
			a {
				display: inline-block;
				color: $optional-color;

				&:hover {
					color: $black-color;
				}
			}
		}
	}
}

/*================================================
Compare CSS
=================================================*/
.products-compare-table {
	table {
		margin-bottom: 0;

		tr {
			td {
				color: $optional-color;
				vertical-align: middle;
				padding: {
					left: 20px;
					right: 20px;
					top: 20px;
					bottom: 20px;
				}
				&:first-child {
					text-transform: uppercase;
					color: $black-color;
					font-weight: 600;
				}
				.remove-btn {
					margin-bottom: 15px;

					a {
						color: red;
						display: inline-block;
					}
				}
			}
			&:first-child {
				background-color: transparent !important;

				td {
					border-bottom: 1px solid #eeeeee;
				}
			}
		}
	}
}

/*================================================
Pagination CSS
=================================================*/
.pagination-area {
	position: relative;
	z-index: 1;
	margin: {
		top: 30px;
	}
	&::before {
		content: "";
		position: absolute;
		left: 0;
		top: 50%;
		transform: translateY(-50%);
		width: 100%;
		height: 1px;
		background-color: #eeeeee;
		z-index: -1;
	}
	.page-numbers {
		color: $black-color;
		display: inline-block;
		line-height: 1;
		background-color: $white-color;
		font: {
			weight: 600;
			size: 20px;
		}
		margin: {
			left: -1px;
			right: -1px;
			top: -10px;
			bottom: -10px;
		}
		padding: {
			left: 8px;
			right: 8px;
		}
		&:hover,
		&:focus,
		&.current {
			color: $main-color;
		}
		&.prev {
			padding-right: 0;
		}
		&.next {
			padding-left: 0;
		}
		&.prev,
		&.next {
			font-size: 28px;
			position: relative;
			top: 5px;
			margin: {
				left: -3px;
				right: -3px;
			}
		}
		&.disabled {
			color: $optional-color;
			cursor: not-allowed;
		}
	}
}

.pick-up-radio {
	display: flex;
	label {
		padding-left: 5px;
		text-transform: none !important;
	}
	// .msg {
	//     white-space: pre-wrap;
	// }
	//     [type="radio"] {
	//         &:checked, &:not(:checked) {
	//             display: none;
	//         }
	//     }
	//     [type="radio"] {
	//         &:checked, &:not(:checked) {
	//             + label {
	//                 padding-left: 27px;
	//                 cursor: pointer;
	//                 display: block;
	//                 color: $black-color;
	//                 position: relative;
	//                 margin-bottom: 8px;
	//                 font: {
	//                     weight: 600;
	//                 }
	//                 &::before {
	//                     content: '';
	//                     position: absolute;
	//                     left: 0;
	//                     top: 5px;
	//                     width: 18px;
	//                     height: 18px;
	//                     border: 1px solid #dddddd;
	//                     border-radius: 50%;
	//                     background: $white-color;
	//                 }
	//                 &::after {
	//                     content: '';
	//                     width: 12px;
	//                     height: 12px;
	//                     background: $main-color;
	//                     position: absolute;
	//                     top: 8px;
	//                     left: 3px;
	//                     border-radius: 50%;
	//                     transition: $transition;
	//                 }
	//             }
	//         }
	//     }
	//     [type="radio"] {
	//         &:not(:checked) {
	//             + label {
	//                 &::after {
	//                     opacity: 0;
	//                     visibility: hidden;
	//                     transform: scale(0);
	//                 }
	//             }
	//         }
	//     }
	//     [type="radio"] {
	//         &:checked {
	//             + label {
	//                 &::after {
	//                     opacity: 1;
	//                     visibility: visible;
	//                     transform: scale(1);
	//                 }
	//             }
	//         }
	//     }
}

/*================================================
Widget Sidebar CSS
=================================================*/

.course-price-img {
	.new-tag {
		position: absolute;
		right: 0px;
		top: 20px;
		background-color: $green-banner;
		color: $white-color;
		text-align: center;
		z-index: 2;
		padding: 1px 7px;
		text-transform: uppercase;
		font: {
			weight: 700;
			size: 14px;
		}
	}
	.sale-tag {
		position: absolute;
		left: 20px;
		top: 20px;
		background-color: $green-banner;
		color: $white-color;
		width: 55px;
		height: 55px;
		line-height: 55px;
		text-align: center;
		border-radius: 50%;
		z-index: 2;
		text-transform: uppercase;
		font: {
			weight: 700;
			size: 14px;
		}
	}
}

.single-course-price-container {
	max-height: 410px;
}

.single-course-price {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background-color: $gradient-color;
	border-radius: 10px;
	height: 100%;
	color: white;
	width: 100%;
	padding: 10px;
	h5 {
		font-size: 30px;
		align-self: center;
		color: white;
	}
	h3 {
		color: white;
		font-size: 60px;
		font-weight: 700;
		align-self: center;
	}

	.post-content {
		height: 50%;
	}
}

.last-price {
	text-decoration: line-through !important;
	font-size: 20px !important;
}

.course-btn {
	background-color: white;
	color: $main-color;
	padding: 10px;
	font-size: 15px;
	font-weight: 600;
	padding-left: 20px;
	padding-right: 20px;
	margin-bottom: 20px;
}

.course-btn:hover {
	cursor: pointer;
	transition: $transition;
	background-color: $main-color;
	color: white;
	padding: 10px;
	font-size: 15px;
	font-weight: 600;
	padding-left: 20px;
	padding-right: 20px;
}

.single-course-price:hover {
}

.course-price-img {
	display: flex;
	height: 50%;
	width: 80%;
	max-height: 50%;
	margin-top: 20px;
	margin-bottom: 20px;
}

.course-title-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
}

.course-subtitle {
	padding-top: 20px;
	font-size: 3vh;
	font-weight: 300;
	font-family: $font-family2;
}

.course-title {
	text-transform: uppercase;
	font-family: $font-family1;
	font-size: 6vh;
	font-weight: 900;
}

.course-heading {
	font-family: $font-family1;
	font-size: 5.5vh;
	font-weight: 600;
}

.course-avatar {
	border-radius: 100%;
	width: 60px;
	height: 60px;
}

.sidebar-col {
	//padding-top: 15vh;
}

.geometric-banner {
	// margin-bottom: 30px;
	margin-top: 40px;
	display: flex;
	height: 160px;
	background: url("../../../images/patt2-alt.png");
}

.margin-60 {
	margin-top: 60px !important;
}

.geometric-image {
	// width: 130px;
	// background-color: #f8f8f8;
	// padding-top: 13.8vh;
	// padding-bottom: 30px;
}

.course-widget-subtitle {
	font-weight: 900;
	font-size: 17px;
}

.course-widget-info {
	font-weight: 400;
	font-size: 17px;
	color: grey;
}

.column {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-content: center;
	width: 25%;
	i {
		font-size: 40px !important;
		color: $main-color;
		width: 20%;
	}
}

.column-2 {
	display: flex;
	flex-direction: column;
	justify-content: center;
	width: 75%;
	padding-left: 10px;
}

.row-space-between {
	margin: 0px;
	display: flex;
	flex-direction: row;
	justify-content: center;
	padding-bottom: 20px;
}

/*================================================
Widget Sidebar CSS
=================================================*/
.custom-widget-area {
	padding-left: 15px;
	.widget {
		margin-bottom: 35px;

		&:last-child {
			margin-bottom: 0;
		}
		.widget-title {
			margin-bottom: 25px;
			position: relative;
			padding-bottom: 8px;
			border-bottom: 1px solid #eeeeee;
			font: {
				size: 21px;
				weight: 700;
			}
			&::before {
				content: "";
				position: absolute;
				left: 0;
				bottom: 0;
				width: 50px;
				height: 1px;
				bottom: -1px;
				background-color: $main-color;
			}
		}
	}
	.widget_search {
		form {
			position: relative;

			.screen-reader-text {
				display: none;
			}
			label {
				display: block;
				margin-bottom: 0;
			}
			.search-field {
				height: 50px;
				color: $black-color;
				background-color: #eef5f9;
				display: block;
				width: 100%;
				border-radius: 5px;
				padding: 0 0 0 15px;
				border: none;
				transition: $transition;
				font: {
					size: $font-size;
					weight: 500;
				}
				&::placeholder {
					color: $optional-color;
					transition: $transition;
				}
				&:focus {
					&::placeholder {
						color: transparent;
					}
				}
			}
			button {
				border: none;
				background-color: $main-color;
				color: $white-color;
				height: 40px;
				width: 40px;
				position: absolute;
				right: 5px;
				transition: $transition;
				border-radius: 5px;
				top: 5px;
				font-size: 20px;

				&:hover,
				&:focus {
					background-color: $black-color;
					color: $white-color;
				}
			}
		}
	}
	.widget_posts_thumb {
		position: relative;
		overflow: hidden;

		.item {
			overflow: hidden;
			margin-bottom: 15px;

			&:last-child {
				margin-bottom: 0;
			}
			.thumb {
				float: left;
				height: 80px;
				overflow: hidden;
				display: block;
				position: relative;
				width: 80px;
				margin-right: 15px;
				z-index: 1;

				.fullimage {
					width: 80px;
					height: 80px;
					display: inline-block;
					border-radius: 5px;
					background: {
						size: cover !important;
						repeat: no-repeat;
						position: center center !important;
					}
					&.bg1 {
						background-image: url(../../../images/blog/blog7.jpg);
					}
					&.bg2 {
						background-image: url(../../../images/blog/blog8.jpg);
					}
					&.bg3 {
						background-image: url(../../../images/blog/blog5.jpg);
					}
				}
				&::before,
				&::after {
					// transition: all .8s cubic-bezier(.2, 1, .22, 1);
					content: "";
					background-color: $white-color;
					position: absolute;
					z-index: 1;
					top: 50%;
					left: 50%;
					opacity: 0;
					transform: translate(-50%, -50%);
				}
				&::before {
					width: 40px;
					height: 1px;
					left: 100%;
				}
				&::after {
					height: 40px;
					width: 1px;
					top: 0;
				}
			}
			.info {
				overflow: hidden;
				margin-top: 5px;

				span {
					display: block;
					color: $optional-color;
					text-transform: uppercase;
					margin: {
						top: -2px;
						bottom: 5px;
					}
					font: {
						size: 12px;
					}
				}
				.title {
					margin-bottom: 0;
					line-height: 1.4;
					font: {
						size: 18px;
						weight: 600;
					}
					a {
						display: inline-block;
					}
				}
			}
			&:hover {
				.thumb {
					&::before,
					&::after {
						opacity: 1;
						top: 50%;
						left: 50%;
					}
				}
			}
		}
	}
	.widget_categories {
		ul {
			padding-left: 0;
			margin-bottom: 0;
			list-style-type: none;

			li {
				position: relative;
				margin-bottom: 12px;
				overflow: hidden;
				color: $black-color;
				padding-left: 18px;
				font: {
					weight: 600;
					size: $font-size;
				}
				&:last-child {
					margin-bottom: 0;
				}
				&::before {
					background: $main-color;
					height: 8px;
					width: 8px;
					content: "";
					border-radius: 50%;
					left: 0;
					top: 7px;
					position: absolute;
				}
				a {
					color: $black-color;
					display: block;

					&:hover {
						color: $main-color;
					}
				}
				.post-count {
					color: $optional-color;
					font-size: 14px;
					float: right;
				}
			}
		}
	}
	.widget_tag_cloud {
		.widget-title {
			margin-bottom: 17px;
		}
	}
	.tagcloud {
		a {
			display: inline-block;
			background-color: #f6f6f6;
			color: $black-color;
			padding: 7px 15px;
			border: none;
			border-radius: 3px;
			font: {
				weight: 600;
				size: 14px !important;
			}
			margin: {
				top: 8px;
				right: 4px;
			}
			&:hover,
			&:focus {
				color: $white-color;
				background-color: $main-color;
			}
		}
	}
	.widget_instagram {
		ul {
			padding-left: 0;
			list-style-type: none;
			flex-wrap: wrap;
			display: flex;
			margin: {
				bottom: -10px;
				left: -5px;
				right: -5px;
			}
			li {
				flex: 0 0 33.3333333333%;
				max-width: 33.3333333333%;
				padding: {
					bottom: 10px;
					right: 5px;
					left: 5px;
				}
				a {
					position: relative;

					&::before {
						content: "";
						position: absolute;
						left: 0;
						top: 0;
						width: 100%;
						height: 100%;
						background-color: $main-color;
						transition: $transition;
						opacity: 0;
						visibility: hidden;
					}
					&:hover {
						&::before {
							opacity: 0.7;
							visibility: visible;
						}
					}
				}
			}
		}
	}
	.widget_contact {
		position: relative;
		z-index: 1;
		border-radius: 5px;
		height: 400px;
		text-align: center;
		background: {
			image: url(../../../images/offer-bg.jpg);
			position: center center;
			size: cover;
			repeat: no-repeat;
		}
		&::before {
			width: 100%;
			height: 100%;
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			border-radius: 5px;
			background-color: $main-color;
			opacity: 0.7;
			z-index: -1;
		}
		.text {
			position: absolute;
			left: 0;
			bottom: 25px;
			right: 0;

			.icon {
				width: 65px;
				height: 65px;
				border: 2px dashed $main-color;
				position: relative;
				border-radius: 50%;
				font-size: 35px;
				transition: $transition;
				background-color: $white-color;
				color: $main-color;
				margin: {
					left: auto;
					right: auto;
					bottom: 17px;
				}
				i {
					position: absolute;
					left: 0;
					right: 0;
					top: 50%;
					transform: translateY(-50%);
				}
			}
			span {
				display: block;
				color: $white-color;
				margin-bottom: 8px;
				font: {
					size: 16px;
					weight: 600;
				}
			}
			h4 {
				color: $white-color;
				margin: 0;
				font: {
					weight: 800;
					size: 25px;
				}
			}
		}
	}
}

.widget-area {
	padding: 25px 0px 0px 25px;
	position: fixed;
	background-color: whitesmoke;
	border-radius: 10px;
	width: 22%;
	// height: 63%;
	margin-top: 6vh;
	display: flex;
	flex-direction: column;
	justify-content: space-evenly;
	.widget {
		margin-bottom: 35px;

		&:last-child {
			margin-bottom: 0;
		}
		.widget-title {
			margin-bottom: 25px;
			position: relative;
			padding-bottom: 8px;
			border-bottom: 1px solid #eeeeee;
			font: {
				size: 21px;
				weight: 700;
			}
			&::before {
				content: "";
				position: absolute;
				left: 0;
				bottom: 0;
				width: 50px;
				height: 1px;
				bottom: -1px;
				background-color: $main-color;
			}
		}

		.buy-btn {
			font-size: 20px;
		}
	}
	.widget_search {
		form {
			position: relative;

			.screen-reader-text {
				display: none;
			}
			label {
				display: block;
				margin-bottom: 0;
			}
			.search-field {
				height: 50px;
				color: $black-color;
				background-color: #eef5f9;
				display: block;
				width: 100%;
				border-radius: 5px;
				padding: 0 0 0 15px;
				border: none;
				transition: $transition;
				font: {
					size: $font-size;
					weight: 500;
				}
				&::placeholder {
					color: $optional-color;
					transition: $transition;
				}
				&:focus {
					&::placeholder {
						color: transparent;
					}
				}
			}
			button {
				border: none;
				background-color: $main-color;
				color: $white-color;
				height: 40px;
				width: 40px;
				position: absolute;
				right: 5px;
				transition: $transition;
				border-radius: 5px;
				top: 5px;
				font-size: 20px;

				&:hover,
				&:focus {
					background-color: $black-color;
					color: $white-color;
				}
			}
		}
	}
	.widget_posts_thumb {
		position: relative;
		overflow: hidden;

		.item {
			overflow: hidden;
			margin-bottom: 15px;

			&:last-child {
				margin-bottom: 0;
			}
			.thumb {
				float: left;
				height: 80px;
				overflow: hidden;
				display: block;
				position: relative;
				width: 80px;
				margin-right: 15px;
				z-index: 1;

				.fullimage {
					width: 80px;
					height: 80px;
					display: inline-block;
					border-radius: 5px;
					background: {
						size: cover !important;
						repeat: no-repeat;
						position: center center !important;
					}
					&.bg1 {
						background-image: url(../../../images/blog/blog7.jpg);
					}
					&.bg2 {
						background-image: url(../../../images/blog/blog8.jpg);
					}
					&.bg3 {
						background-image: url(../../../images/blog/blog5.jpg);
					}
				}
				&::before,
				&::after {
					transition: all 0.8s cubic-bezier(0.2, 1, 0.22, 1);
					content: "";
					background-color: $white-color;
					position: absolute;
					z-index: 1;
					top: 50%;
					left: 50%;
					opacity: 0;
					transform: translate(-50%, -50%);
				}
				&::before {
					width: 40px;
					height: 1px;
					left: 100%;
				}
				&::after {
					height: 40px;
					width: 1px;
					top: 0;
				}
			}
			.info {
				overflow: hidden;
				margin-top: 5px;

				span {
					display: block;
					color: $optional-color;
					text-transform: uppercase;
					margin: {
						top: -2px;
						bottom: 5px;
					}
					font: {
						size: 12px;
					}
				}
				.title {
					margin-bottom: 0;
					line-height: 1.4;
					font: {
						size: 18px;
						weight: 600;
					}
					a {
						display: inline-block;
					}
				}
			}
			&:hover {
				.thumb {
					&::before,
					&::after {
						opacity: 1;
						top: 50%;
						left: 50%;
					}
				}
			}
		}
	}
	.widget_categories {
		ul {
			padding-left: 0;
			margin-bottom: 0;
			list-style-type: none;

			li {
				position: relative;
				margin-bottom: 12px;
				overflow: hidden;
				color: $black-color;
				padding-left: 18px;
				font: {
					weight: 600;
					size: $font-size;
				}
				&:last-child {
					margin-bottom: 0;
				}
				&::before {
					background: $main-color;
					height: 8px;
					width: 8px;
					content: "";
					border-radius: 50%;
					left: 0;
					top: 7px;
					position: absolute;
				}
				a {
					color: $black-color;
					display: block;

					&:hover {
						color: $main-color;
					}
				}
				.post-count {
					color: $optional-color;
					font-size: 14px;
					float: right;
				}
			}
		}
	}
	.widget_tag_cloud {
		.widget-title {
			margin-bottom: 17px;
		}
	}
	.tagcloud {
		a {
			display: inline-block;
			background-color: #f6f6f6;
			color: $black-color;
			padding: 7px 15px;
			border: none;
			border-radius: 3px;
			font: {
				weight: 600;
				size: 14px !important;
			}
			margin: {
				top: 8px;
				right: 4px;
			}
			&:hover,
			&:focus {
				color: $white-color;
				background-color: $main-color;
			}
		}
	}
	.widget_instagram {
		ul {
			padding-left: 0;
			list-style-type: none;
			flex-wrap: wrap;
			display: flex;
			margin: {
				bottom: -10px;
				left: -5px;
				right: -5px;
			}
			li {
				flex: 0 0 33.3333333333%;
				max-width: 33.3333333333%;
				padding: {
					bottom: 10px;
					right: 5px;
					left: 5px;
				}
				a {
					position: relative;

					&::before {
						content: "";
						position: absolute;
						left: 0;
						top: 0;
						width: 100%;
						height: 100%;
						background-color: $main-color;
						transition: $transition;
						opacity: 0;
						visibility: hidden;
					}
					&:hover {
						&::before {
							opacity: 0.7;
							visibility: visible;
						}
					}
				}
			}
		}
	}
	.widget_contact {
		position: relative;
		z-index: 1;
		border-radius: 5px;
		height: 400px;
		text-align: center;
		background: {
			image: url(../../../images/offer-bg.jpg);
			position: center center;
			size: cover;
			repeat: no-repeat;
		}
		&::before {
			width: 100%;
			height: 100%;
			content: "";
			position: absolute;
			left: 0;
			top: 0;
			border-radius: 5px;
			background-color: $main-color;
			opacity: 0.7;
			z-index: -1;
		}
		.text {
			position: absolute;
			left: 0;
			bottom: 25px;
			right: 0;

			.icon {
				width: 65px;
				height: 65px;
				border: 2px dashed $main-color;
				position: relative;
				border-radius: 50%;
				font-size: 35px;
				transition: $transition;
				background-color: $white-color;
				color: $main-color;
				margin: {
					left: auto;
					right: auto;
					bottom: 17px;
				}
				i {
					position: absolute;
					left: 0;
					right: 0;
					top: 50%;
					transform: translateY(-50%);
				}
			}
			span {
				display: block;
				color: $white-color;
				margin-bottom: 8px;
				font: {
					size: 16px;
					weight: 600;
				}
			}
			h4 {
				color: $white-color;
				margin: 0;
				font: {
					weight: 800;
					size: 25px;
				}
			}
		}
	}
}

/*================================================
Coming Soon CSS
=================================================*/
.coming-soon-area {
	height: 100vh;
	position: relative;
	z-index: 1;
	background: #f6f7fb;
	background: {
		image: url(../../../images/coming-soon-bg.jpg);
		position: top center;
		size: cover;
		repeat: no-repeat;
	}
}

.widget-blog-title {
	margin-bottom: 25px;
	position: relative;
	padding-bottom: 8px;
	border-bottom: 1px solid #eeeeee;
	font: {
		size: 21px;
		weight: 700;
	}
	&::before {
		content: "";
		position: absolute;
		left: 0;
		bottom: 0;
		width: 50px;
		height: 1px;
		bottom: -1px;
		background-color: $main-color;
	}
}

.coming-soon-content {
	max-width: 700px;
	background: $white-color;
	border-radius: 5px;
	overflow: hidden;
	text-align: center;
	padding: 40px 60px;
	box-shadow: 1px 5px 24px 0 rgba(68, 102, 242, 0.05);
	margin: {
		left: 100px;
	}
	.logo {
		display: inline-block;
	}
	h2 {
		font: {
			size: 40px;
			weight: 700;
		}
		margin: {
			top: 30px;
			bottom: 0;
		}
	}
	#timer {
		margin-top: 40px;

		div {
			background-color: #333333;
			color: $white-color;
			width: 100px;
			height: 105px;
			border-radius: 5px;
			font: {
				size: 35px;
				weight: 900;
			}
			margin: {
				left: 10px;
				right: 10px;
			}
			span {
				display: block;
				margin-top: -1px;
				font: {
					size: 16px;
					weight: 600;
				}
			}
		}
	}
	form {
		position: relative;
		max-width: 500px;
		margin: {
			left: auto;
			right: auto;
			top: 50px;
		}
		.form-group {
			margin-bottom: 25px;
			width: 100%;
			position: relative;

			.label-title {
				margin-bottom: 0;
				position: absolute;
				display: block;
				left: 0;
				top: 0;
				pointer-events: none;
				width: 100%;
				height: 100%;
				color: $black-color;

				i {
					position: absolute;
					left: 0;
					transition: $transition;
					top: 9px;
					font: {
						size: 22px;
					}
				}
				&::before {
					content: "";
					display: block;
					position: absolute;
					bottom: 0;
					left: 0;
					width: 0;
					height: 2px;
					transition: $transition;
					background: $main-color;
				}
			}
			.input-newsletter {
				border-radius: 0;
				border: none;
				border-bottom: 2px solid #eeeeee;
				padding: 0 0 0 32px;
				color: $black-color;
				height: 45px;
				display: block;
				width: 100%;
				transition: $transition;
				font: {
					size: 16px;
					weight: 400;
				}
				&::placeholder {
					color: #a1a1a1;
					transition: $transition;
				}
				&:focus {
					&::placeholder {
						color: transparent;
					}
				}
			}
		}
		.validation-danger {
			margin-top: 15px;
			color: red;
		}
		.validation-success {
			margin-top: 15px;
		}
		p {
			margin: {
				bottom: 0;
				top: 20px;
			}
		}
	}
}

/*================================================
Track Order CSS
=================================================*/
.track-order-content {
	max-width: 700px;
	background-color: $white-color;
	box-shadow: 3px 5px 25px 0 rgba(0, 0, 0, 0.1);
	text-align: center;
	padding: 40px;
	margin: {
		left: auto;
		right: auto;
	}
	h2 {
		margin-bottom: 30px;
		font: {
			size: 35px;
			weight: 700;
		}
	}
	form {
		text-align: left;
		padding: {
			left: 30px;
			right: 30px;
		}
		.form-group {
			label {
				display: block;
				color: $black-color;
				margin-bottom: 9px;
				text-transform: uppercase;
				font: {
					size: 14px;
					weight: 600;
				}
			}
		}
		.default-btn {
			margin-top: 5px;
		}
	}
}

/*================================================
Login CSS
=================================================*/
.login-error {
	color: red !important;
}
.login-area {
	height: 100vh;
	display: flex;
	flex-direction: column;
	justify-content: center;
}
.login-content {
	h2 {
		margin-bottom: 25px;
		font: {
			size: 25px;
			weight: 700;
		}
	}
	.login-form {
		padding-right: 15px;

		.form-group {
			margin-bottom: 20px;

			.form-control {
				height: 50px;
			}
		}
		.default-btn {
			display: block;
			width: 100%;
			padding: 14px 25px;
		}
		.forgot-password {
			display: inline-block;
			margin-top: 15px;
			position: relative;
			font: {
				weight: 600;
			}
			&::before {
				width: 100%;
				height: 1.5px;
				background: $optional-color;
				bottom: 0;
				left: 0;
				position: absolute;
				transition: $transition;
				content: "";
			}
			&:hover {
				&::before {
					width: 0;
				}
			}
		}
	}
}
.new-customer-content {
	padding-left: 15px;

	h2 {
		margin-bottom: 20px;
		font: {
			size: 25px;
			weight: 700;
		}
	}
	span {
		display: block;
		margin-bottom: 10px;
		font: {
			size: 17px;
			weight: 600;
		}
	}
	.optional-btn {
		margin-top: 5px;
	}
}

/*================================================
Signup CSS
=================================================*/
.signup-content {
	max-width: 520px;
	margin: {
		left: auto;
		right: auto;
	}
	h2 {
		margin-bottom: 25px;
		text-align: center;
		font: {
			size: 25px;
			weight: 700;
		}
	}
	.signup-form {
		.form-group {
			margin-bottom: 20px;

			label {
				display: block;
				text-transform: uppercase;
				margin-bottom: 8px;
				font: {
					size: 14px;
					weight: 600;
				}
			}
			.form-control {
				height: 50px;
			}
		}
		.default-btn {
			display: block;
			width: 100%;
			padding: 14px 25px;
		}
		.return-store {
			display: inline-block;
			margin-top: 15px;
			color: $optional-color;
			position: relative;
			font-size: 15px;

			&::before {
				width: 100%;
				height: 1px;
				background: $optional-color;
				bottom: 0;
				left: 0;
				position: absolute;
				transition: $transition;
				content: "";
			}
			&:hover {
				color: $black-color;

				&::before {
					width: 0;
				}
			}
		}
	}
}

.payment-content {
	max-width: 920px;
	margin: {
		left: auto;
		right: auto;
	}
	h2 {
		margin-bottom: 25px;
		text-align: center;
		font: {
			size: 25px;
			weight: 700;
		}
	}
	.payment-form {
		.card-logo {
			height: 30px;
			margin-right: 10px;
		}
		.card-list {
			max-height: 30vh;
			overflow-x: scroll;
			margin-top: 10%;
			list-style: none;
		}
		.form-group {
			margin-bottom: 20px;

			label {
				display: block;
				text-transform: uppercase;
				margin-bottom: 8px;
				font: {
					size: 14px;
					weight: 600;
				}
			}
			.form-control {
				height: 50px;
			}
		}
		.default-btn {
			display: block;
			width: 100%;
			padding: 14px 25px;
		}
		.return-store {
			display: inline-block;
			margin-top: 15px;
			color: $optional-color;
			position: relative;
			font-size: 15px;

			&::before {
				width: 100%;
				height: 1px;
				background: $optional-color;
				bottom: 0;
				left: 0;
				position: absolute;
				transition: $transition;
				content: "";
			}
			&:hover {
				color: $black-color;

				&::before {
					width: 0;
				}
			}
		}
	}
}

.login-content {
	max-width: 520px;
	margin: {
		left: auto;
		right: auto;
	}
	h2 {
		margin-bottom: 25px;
		text-align: center;
		font: {
			size: 25px;
			weight: 700;
		}
	}
	.login-form {
		.form-group {
			margin-bottom: 20px;

			label {
				display: block;
				text-transform: uppercase;
				margin-bottom: 8px;
				font: {
					size: 14px;
					weight: 600;
				}
			}
			.form-control {
				height: 50px;
			}
		}
		.default-btn {
			display: block;
			width: 100%;
			padding: 14px 25px;
		}
		.return-store {
			display: inline-block;
			margin-top: 15px;
			color: $optional-color;
			position: relative;
			font-size: 15px;

			&::before {
				width: 100%;
				height: 1px;
				background: $optional-color;
				bottom: 0;
				left: 0;
				position: absolute;
				transition: $transition;
				content: "";
			}
			&:hover {
				color: $black-color;

				&::before {
					width: 0;
				}
			}
		}
	}
}

/*================================================
Contact CSS
=================================================*/
.contact-info {
	h3 {
		margin-bottom: 15px;
		font: {
			size: 22px;
			weight: 700;
		}
	}
	p {
		a {
			display: inline;
			font-weight: 500;
		}
	}
	.contact-list {
		padding-left: 0;
		margin-bottom: 25px;
		list-style-type: none;

		li {
			margin-bottom: 12px;
			position: relative;
			padding-left: 28px;

			i {
				position: absolute;
				left: 0;
				top: 2px;
				color: $main-color;
				font-size: 20px;
			}
			a {
				display: inline-block;
				font-weight: 600;
				position: relative;

				&:hover {
					&::before {
						width: 100%;
					}
				}
				&::before {
					content: "";
					position: absolute;
					left: 0;
					bottom: 0;
					width: 0;
					height: 1px;
					transition: $transition;
					background-color: $main-color;
				}
			}
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	.opening-hours {
		padding-left: 0;
		margin-bottom: 25px;
		list-style-type: none;

		li {
			margin-bottom: 10px;

			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	.social {
		padding-left: 0;
		list-style-type: none;
		margin: {
			bottom: 0;
		}
		li {
			display: inline-block;
			margin-right: 8px;

			&:last-child {
				margin-right: 0;
			}
			a {
				width: 30px;
				height: 30px;
				text-align: center;
				display: block;
				color: $main-color;
				background-color: #f4f4f4;
				font-size: 18px;
				position: relative;
				border-radius: 2px;

				i {
					position: absolute;
					left: 0;
					top: 50%;
					right: 0;
					transform: translateY(-49%);
				}
				&:hover {
					background-color: $main-color;
					color: $white-color;
				}
			}
		}
	}
}
.contact-form {
	h3 {
		margin-bottom: 25px;
		padding-bottom: 10px;
		position: relative;
		color: white !important;
		border-bottom: 1px solid rgba(10, 10, 10, 0.1);
		font: {
			size: 30px;
			weight: 700;
		}
		&::before {
			width: 50px;
			height: 1px;
			bottom: -1px;
			content: "";
			position: absolute;
			left: 0;
			background-color: $main-color;
		}
	}
	form {
		button {
			font-size: 20px;
		}
		.form-group {
			label {
				display: block;
				color: white;
				margin-bottom: 10px;
				font: {
					size: 18px;
					weight: 600;
				}
				span {
					color: red;
				}
			}
		}
		.with-errors {
			ul {
				padding-left: 0;
				list-style-type: none;
				margin: {
					top: 10px;
					bottom: 0;
				}
				li {
					color: red;
					font-size: 15px;
				}
			}
		}
		#msgSubmit {
			margin-bottom: 0;

			&.text-danger,
			&.text-success {
				margin-top: 15px;
			}
		}
	}
}
#map {
	width: 100%;
	height: 500px;
}

/*================================================
Footer CSS
=================================================*/
.footer-area {
	padding-top: 100px;
	background-color: $dark-gray-color;
	position: relative;
	z-index: 1;
}
.single-footer-widget {
	margin-bottom: 30px;

	h3 {
		color: $white-color;
		margin-bottom: 25px;
		padding-bottom: 10px;
		position: relative;
		border-bottom: 1px solid rgba(255, 255, 255, 0.1);
		font: {
			size: 30px;
			weight: 700;
		}
		&::before {
			width: 50px;
			height: 1px;
			bottom: -1px;
			content: "";
			position: absolute;
			left: 0;
			background-color: $main-color;
		}
	}
	p {
		color: $white-color;
	}
	.about-the-store {
		p {
			font: {
				weight: 600;
			}
		}
		.footer-contact-info {
			padding-left: 0;
			margin-bottom: 0;
			list-style-type: none;

			li {
				margin-bottom: 12px;
				color: $white-color;
				position: relative;
				padding-left: 25px;

				i {
					position: absolute;
					left: 0;
					top: 2px;
					font-size: 18px;
				}
				a {
					display: inline-block;
					color: $white-color;
					font-weight: 600;
					position: relative;

					&:hover {
						color: $main-color;

						&::before {
							width: 100%;
						}
					}
					&::before {
						content: "";
						position: absolute;
						left: 0;
						bottom: 0;
						width: 0;
						height: 1px;
						transition: $transition;
						background-color: $main-color;
					}
				}
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
	.social-link {
		padding-left: 0;
		list-style-type: none;
		margin: {
			top: 20px;
			bottom: 0;
		}
		li {
			display: inline-block;
			margin-right: 10px;

			&:last-child {
				margin-right: 0;
			}
			a {
				width: 30px;
				height: 30px;
				text-align: center;
				color: $main-color;
				background-color: $white-color;
				font-size: 18px;
				position: relative;
				border-radius: 2px;

				i {
					position: absolute;
					left: 0;
					top: 50%;
					right: 0;
					transform: translateY(-49%);
				}
				&:hover {
					background-color: $main-color;
					color: $white-color;
				}
			}
		}
	}
	.quick-links {
		padding-left: 0;
		margin-bottom: 0;
		list-style-type: none;

		li {
			margin-bottom: 12px;
			color: $white-color;

			a {
				display: inline-block;
				color: $white-color;
				font-weight: 600;
				position: relative;

				&:hover {
					color: $main-color;

					&::before {
						width: 100%;
					}
				}
				&::before {
					content: "";
					position: absolute;
					left: 0;
					bottom: 0;
					width: 0;
					height: 1px;
					transition: $transition;
					background-color: $main-color;
				}
			}
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	.customer-support {
		padding-left: 0;
		margin-bottom: 0;
		list-style-type: none;

		li {
			margin-bottom: 12px;
			color: $white-color;

			a {
				display: inline-block;
				color: $white-color;
				font-weight: 600;
				position: relative;

				&:hover {
					color: $main-color;

					&::before {
						width: 100%;
					}
				}
				&::before {
					content: "";
					position: absolute;
					left: 0;
					bottom: 0;
					width: 0;
					height: 1px;
					transition: $transition;
					background-color: $main-color;
				}
			}
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
	.footer-newsletter-box {
		font-size: 20px;
		p {
			font-size: 18px;
		}
		.newsletter-form {
			position: relative;
			margin-top: -5px;

			label {
				display: block;
				color: $white-color;
				font-weight: 600;
				margin-bottom: 10px;
			}
			.input-newsletter {
				width: 100%;
				background-color: transparent;
				border: 1px solid white;
				border-radius: 0;
				height: 45px;
				padding: 10px 15px;
				color: $white-color;
				font-size: $font-size;
				border-radius: 0.25rem;

				&::placeholder {
					transition: $transition;
					color: #cccccc;
				}
				&:focus {
					&::placeholder {
						color: transparent;
					}
				}
			}
			button {
				display: block;
				margin-top: 0.8rem;
				width: 100%;
				border-radius: 0.25rem;
				background-color: $main-color;
				color: $white-color;
				border: none;
				padding: 12px 25px;
				transition: $transition;
				font: {
					weight: 600;
					size: 25px;
				}
				&:hover {
					color: $black-color;
					background-color: $white-color;
				}
			}
			#validator-newsletter {
				position: absolute;
				left: 0;
				bottom: -32px;
				color: $white-color !important;
			}
		}
	}
}
.footer-bottom-area {
	margin-top: 70px;
	border-top: 1px solid rgba(255, 255, 255, 0.1);
	padding: {
		top: 25px;
		bottom: 25px;
	}
	p {
		margin-bottom: 0;
		color: #cecdcd;
		font: {
			weight: 500;
		}
		i {
			position: relative;
			top: 1px;
		}
		a {
			display: inline-block;
			color: $white-color;
			font-weight: 600;
			position: relative;

			&::before {
				content: "";
				position: absolute;
				left: 0;
				bottom: 0;
				width: 0;
				transition: $transition;
				height: 1px;
				background-color: $main-color;
			}
			&:hover {
				color: $main-color;

				&::before {
					width: 100%;
				}
			}
		}
	}
	.payment-types {
		padding: 0;
		margin: 0;
		list-style-type: none;
		text-align: right;

		li {
			display: inline-block;

			a {
				display: block;
				transition: $transition;

				&:hover {
					transform: translateY(-5px);
				}
			}
		}
	}
}
.lines {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	height: 100%;
	margin: auto;
	width: 90vw;
	z-index: -1;

	.line {
		position: absolute;
		width: 1px;
		height: 100%;
		top: 0;
		left: 50%;
		background: rgba(255, 255, 255, 0.1);
		overflow: hidden;

		&::after {
			content: "";
			display: block;
			position: absolute;
			height: 15vh;
			width: 100%;
			top: -50%;
			left: 0;
			background: linear-gradient(
				to bottom,
				rgba(255, 255, 255, 0) 0%,
				$white-color 75%,
				$white-color 100%
			);
			animation: run 7s 0s infinite;
			animation-fill-mode: forwards;
			animation-timing-function: cubic-bezier(0.4, 0.26, 0, 0.97);
		}
		&:nth-child(1) {
			margin-left: -25%;

			&::after {
				animation-delay: 2s;
			}
		}
		&:nth-child(3) {
			margin-left: 25%;

			&::after {
				animation-delay: 2.5s;
			}
		}
	}
}
@keyframes run {
	0% {
		top: -50%;
	}
	100% {
		top: 110%;
	}
}

/*================================================
Go Top CSS
=================================================*/
.go-top {
	position: fixed;
	cursor: pointer;
	bottom: 0;
	right: 15px;
	color: $white-color;
	background-color: $main-color;
	z-index: 4;
	width: 42px;
	text-align: center;
	height: 42px;
	line-height: 45px;
	font-size: 28px;
	transition: $transition;
	border-radius: 5px 5px 0 0;
	visibility: hidden;

	&.active {
		visibility: visible;
	}

	&:hover {
		background-color: $black-color;
		color: $white-color;
	}
}

/*================================================
Products Details Area CSS
=================================================*/
.products-page-gallery {
	text-align: center;
	.slick-slider {
		.slick-list {
			.slick-track {
				.slick-slide {
					padding: 0 3px;
					cursor: pointer;

					img {
						width: 100%;
					}
				}
			}
		}
	}
}
.covid-19-offer {
	.default-btn {
		&:hover {
			background-color: $white-color;
			color: $main-color;
		}
	}
}

/* Grocery Demo CSS */
.grocery-main-banner {
	padding: {
		top: 200px;
		bottom: 200px;
	}
	background: {
		image: url("../../../images/grocery-banner-bg.jpg");
		position: center center;
		size: cover;
		repeat: no-repeat;
	}
	&.electronics-banner {
		background: {
			image: url("../../../images/electronics-banner-bg.jpg");
		}
	}
}
.grocery-banner-content {
	max-width: 720px;
	text-align: center;
	margin: {
		left: auto;
		right: auto;
	}
	h1 {
		font: {
			size: 58px;
			weight: 800;
			style: italic;
		}
		margin: {
			top: 0;
			bottom: 18px;
		}
	}
	p {
		color: $black-color;
		font: {
			size: 20px;
			weight: 600;
		}
	}
	h2 {
		color: #e7160e;
		font: {
			size: 50px;
			weight: 700;
		}
		margin: {
			top: 15px;
			bottom: 0;
		}
	}
	.default-btn {
		margin-top: 20px;
	}
}
.left-categories {
	transition: $transition;
	.content {
		right: auto;
		text-align: left;
		transition: $transition;
	}
	&:hover {
		transition: $transition;
		.content {
			bottom: 15px;
		}
	}
}
.single-categories-box {
	&.hover-active {
		.content {
			span {
				transform: translateY(-30px);
			}
			h3 {
				transform: translateY(-30px);
			}
			.default-btn {
				background-color: $main-color;
				color: $white-color;
				border-color: $main-color;
				opacity: 1;
				visibility: visible;
				transform: translateY(-15px);
			}
		}
	}
	&.overly {
		position: relative;
		transition: $transition;

		&::before {
			position: absolute;
			content: "";
			background-color: rgba(0, 0, 0, 0.5);
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			transition: $transition;
		}
	}
}
.testimonials-section {
	background-color: #f5f5f5;
}
.categories-banner-area {
	background-color: #e0e0e091;
	background: {
		color: #d5f3fe;
		image: url("../../../images/courses/chairs_3.jpg");
		position: center center;
		size: cover;
		repeat: no-repeat;
	}

	min-height: 100%;
	background: linear-gradient(90deg, #184d5ee7, #4d7c8adc),
		url("../../../images/courses/chairs_3.jpg");
	background-size: cover;

	.default-btn:hover {
		border: 1px solid #ffbd5a !important;
		color: white !important;
	}
}

.categories-banner-area-2 {
	background: linear-gradient(90deg, $main-color, $gradient-color);
}

.grocery-demo {
	.products-area {
		padding-top: 100px;

		&.pt-100 {
			padding-top: 0;
		}
	}
	.categories-banner-area.pt-100 {
		padding-top: 0;
	}
	.blog-area {
		&.pt-100 {
			padding-top: 0;
		}
	}
}

/* Furniture Banner CSS */
.furniture-banner {
	background: {
		color: #d5f3fe;
		image: url("../../../images/furniture-banner-bg.jpg");
		position: center center;
		size: cover;
		repeat: no-repeat;
	}
	padding: {
		top: 200px;
		bottom: 200px;
	}
}
.furniture-banner-content {
	margin-left: 50px;

	.sub-title {
		display: block;
		margin-bottom: 7px;
		font: {
			size: 17.5px;
			weight: 600;
		}
	}
	h1 {
		margin: {
			top: 0;
			bottom: 12px;
		}
		font: {
			size: 65px;
			weight: 700;
		}
	}
	p {
		color: #334d68;
		font: {
			weight: 500;
			size: 17px;
		}
	}
	.btn-box {
		margin-top: 28px;

		.optional-btn {
			margin-left: 15px;
		}
	}
}

/* jewelry Demo CSS */
.jewelry-main-banner {
	padding: {
		top: 200px;
		bottom: 200px;
		left: 50px;
		right: 50px;
	}
	background: {
		image: url("../../../images/jewelry-banner-bg.jpg");
		position: top center;
		size: cover;
		repeat: no-repeat;
	}
}
.jewelry-banner-content {
	max-width: 755px;
	position: relative;
	overflow: hidden;
	padding: 55px;
	z-index: 1;

	.line {
		top: 0;
		left: 0;
		content: "";
		z-index: -1;
		width: 100%;
		height: 100%;
		position: absolute;
		display: inline-block;
		border: 9px solid #000000;
		clip-path: polygon(0 0, 60% 0, 36% 100%, 0 100%);
	}
	.sub-title {
		display: block;
		color: #fff;
		font: {
			size: 25px;
		}
	}
	h1 {
		margin: 18px 0 15px;
		color: #fff;
		font: {
			size: 65px;
			weight: 700;
		}
	}
	p {
		color: #fff;
		max-width: 495px;
		margin-bottom: 0;
		line-height: 1.6;
		font: {
			size: 20px;
			weight: 300;
		}
	}
	.default-btn {
		margin-top: 25px;
	}
}

/*================================================
Contact CSS
=================================================*/
.contact-page {
	height: 150vh;
	position: relative;
}

.contact-area {
	height: 90vh;
	background: linear-gradient(0deg, #184d5e, #4d7c8a);
	margin: auto;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.contact-area-main {
	height: 100vh;
	background: white;
	margin: auto;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.contact-form-main {
	h3 {
		margin-bottom: 25px;
		padding-bottom: 10px;
		position: relative;
		color: black !important;
		border-bottom: 1px solid rgba(10, 10, 10, 0.1);
		font: {
			size: 30px;
			weight: 700;
		}
		&::before {
			width: 50px;
			height: 1px;
			bottom: -1px;
			content: "";
			position: absolute;
			left: 0;
			background-color: $main-color;
		}
	}
	form {
		button {
			font-size: 20px;
		}
		.form-group {
			label {
				display: block;
				color: black;
				margin-bottom: 10px;
				font: {
					size: 18px;
					weight: 600;
				}
				span {
					color: red;
				}
			}
		}
		.with-errors {
			ul {
				padding-left: 0;
				list-style-type: none;
				margin: {
					top: 10px;
					bottom: 0;
				}
				li {
					color: red;
					font-size: 15px;
				}
			}
		}
		#msgSubmit {
			margin-bottom: 0;

			&.text-danger,
			&.text-success {
				margin-top: 15px;
			}
		}
	}
}

/*================================================
Subscribe CSS
=================================================*/

.subscribe-area {
	position: relative;
	z-index: 1;
}

.subscribe-button {
	background-color: $main-color !important;
}

.subscribe-button:hover {
	background-color: $main-color-light !important;
}

/*================================================
Home - Blog CSS
=================================================*/

.most-recent-post {
	// -webkit-box-shadow: 0px 5px 26px 13px #B0ACAC;
	// box-shadow: 0px 5px 26px 13px #B0ACAC;
	-webkit-box-shadow: 0px 0px 12px 4px #b0acac;
	box-shadow: 0px 0px 12px 4px #b0acac;
	border-radius: 10px;
	transform: scale(1.1);
	margin-left: 10px;
	margin-right: 10px;
}

.most-recent-post-label {
	margin-left: 0px !important;
	color: white;
	font-weight: 700;
	background-color: $main-color-light;
	width: 90%;
	align-self: center;
	text-align: center;
	border-radius: 6px;
}

.most-recent-post-content {
	padding: 20px;
}
.blog-col {
	align-items: center !important;
}

.custom-select {
	option {
		background-color: #e8e2d6 !important;
	}
}

.row-justify-center {
	display: flex;
	flex-direction: row;
	justify-content: center !important;
}

.row-justify-end {
	justify-content: flex-end;
}

.course-sidebar {
	position: fixed !important;
	background-color: whitesmoke;
	border-radius: 10px;
	border: 0.5px solid grey;
}

.price-contact {
	color: black;
	font-size: 17px;
	padding-top: 30px;
}

.module-modal-row {
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	margin-left: 0px;
	margin-bottom: 25px;
	align-content: center;
	align-items: center;
	h5 {
		margin: 0px;
		margin-right: 10px;
		color: white;

		font: {
			size: 17px;
			weight: 700;
		}
	}
}

.module-modal-column {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	margin-left: 0px;
	margin-bottom: 25px;
	align-content: flex-start;
	align-items: flex-start;
	h5 {
		margin: 0px;
		margin-right: 10px;
		color: white;

		font: {
			size: 17px;
			weight: 700;
		}
	}
}

.checkout-area {
	min-height: 100vh;

	.container {
		margin-top: 10vh;
	}
}

.see-all-courses {
	font-weight: 500;
	font-size: 20px;
	cursor: pointer;
}

.courses-area {
	.container {
		// height:100vh;
		justify-content: center;
	}
}

//////////////////////////////////////////
/* Certificates css                    */
/////////////////////////////////////////
.certificates-area {
	height: 100vh;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	text-align: center;
	img {
		max-height: 55vh;
	}
}

.certificates-content {
	display: flex;
	flex-direction: row;
	align-items: center;
	text-align: center;
	width: 100%;
	padding-top: 15vh;
	h3 {
		font: {
			size: 30px;
			weight: 700;
		}
		margin: {
			// top: 30px;
			bottom: 15px;
		}
	}
	p {
		//margin-bottom: 25px;
	}
	.email {
		font-size: 15px;
		font-weight: 500;
		margin-bottom: 30px;
	}
}

.checkout-btn {
	width: 100% !important;
	display: inline-block;
	border: 1px solid $main-color;
	padding: 10px 30px;
	transition: $transition;
	text-transform: uppercase;
	background-color: $main-color;
	color: $white-color !important;
	font: {
		size: 14px;
		weight: 600;
	}
	&:hover {
		background-color: transparent;
		color: $main-color !important;
		border-color: $main-color !important;
	}
}

.lowercase {
	text-transform: none !important;
}

.loader-background {
	height: 73vh;
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-content: center;
	align-items: center;
}

.nav-link-login {
	color: white !important;
}

.cart-icon {
	height: 30px !important;
}

.consultoria-image {
	height: 80vh;
	img {
		object-fit: fill;
	}
}

.downloadContainer {
	width: 100%;
	display: flex;
	justify-content: center;
	margin-top: 50px;
}

.downloadLink {
	margin: 0px;
	padding: 10px 5px;
	color: #fff;
}

.image-div {
	height: 158px;
	display: flex;
	flex-direction: column;
	justify-content: center;
}

.all-courses-description {
	width: 82vw;
	display: flex;
	margin: auto;
	margin-top: 50px;
	margin-bottom: 50px;
}
